import { MenuItem, Select } from '@mui/material'
import React from 'react'
import TimezoneSelect, {
  useTimezoneSelect,
  allTimezones
} from 'react-timezone-select'
const labelStyle = 'original'
const timezones = {
  ...allTimezones,
  'Europe/Berlin': 'Frankfurt'
}

export default function TimezoneSelectCell ({ onChange, value }) {
  const { options, parseTimezone } = useTimezoneSelect({
    labelStyle,
    timezones
  })
  return (
    <Select
      value={value!=null? parseTimezone(value):parseTimezone('Asia/Kolkata')}
      defaultValue={parseTimezone('Asia/Kolkata')}
      variant='outlined'
      onChange={event => onChange(parseTimezone(event.target.value))}
      renderValue={() => (value == null ? ' ' : value)}
      MenuProps={{
        anchorOrigin: {
          vertical: 'top',
          horizontal: 'center'
        }
      }}
    >
      {options.map(timezone => (
        <MenuItem key={timezone.value} value={timezone.value}>
          {timezone.label}
        </MenuItem>
      ))}
    </Select>
  )
}
