import React from 'react'
import { Typography } from '@mui/material'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import { AddTaskRounded, Block, CelebrationOutlined, ContrastRounded } from '@mui/icons-material'
import { WarningAmberOutlined } from '@mui/icons-material'
import TreeLeaveIcon from '../../stylings/TreeLeaveIcon'
export default function CalenderLegend () {
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        justifyItems: 'center',
        width: 'max-content',
        height: 'max-content',
        backgroundColor: 'whitesmoke'
      }}
    >
      <Typography
        variant='body1'
        style={{ display: 'inline-flex', alignItems: 'center' }}
      >
        <AddTaskRounded color='secondary' /> : Add current day's attendance
      </Typography>
      <Typography
        variant='body1'
        style={{ display: 'inline-flex', alignItems: 'center' }}
      >
        <CheckCircleIcon color='info' /> : Attendance marked
      </Typography>
      <Typography
        variant='body1'
        style={{ display: 'inline-flex', alignItems: 'center' }}
      >
        <TreeLeaveIcon /> : Leave taken
      </Typography>
      <Typography
        variant='body1'
        style={{ display: 'inline-flex', alignItems: 'center' }}
      >
        <WarningAmberOutlined color='error' /> : Missed attendance
      </Typography>
      <Typography
        variant='body1'
        style={{ display: 'inline-flex', alignItems: 'center' }}
      >
        <CheckCircleIcon color='warning' /> : Request Processing
      </Typography>
      <Typography
        variant='body1'
        style={{ display: 'inline-flex', alignItems: 'center' }}
      >
        <Block color='secondary' /> : Weekend
      </Typography>
      <Typography
        variant='body1'
        style={{ display: 'inline-flex', alignItems: 'center' }}
      >
        <CelebrationOutlined color='warning'/> : Holiday
      </Typography>
      <Typography
        variant='body1'
        style={{ display: 'inline-flex', alignItems: 'center' }}
      >
        <ContrastRounded color='secondary' />: Half Day
      </Typography>
    </div>
  )
}
