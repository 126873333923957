import { Padding } from '@mui/icons-material'
import { backdropClasses } from '@mui/material'
import { border, margin } from '@mui/system'

export const dataGridStyles = {
  boxShadow: 2,
  margin: 1,
  backgroundColor: 'white',
  '& .MuiDataGrid-columnHeader': {
    color: 'white',
    backgroundColor: 'secondary.main'
  },
  '& .MuiDataGrid-cell': {
    backgroundColor: 'rgba(252, 255, 255, 0.4)'
  },
  '& .MuiDataGrid-cell--editable': {
    backgroundImage:'url(/icons8-edit-50.png)',
    backgroundSize:'10px',
    backgroundOrigin:'content-box',
    backgroundRepeat:'no-repeat',
    backgroundPosition:'right'

  },
  '& .MuiDataGrid-row.Mui-selected': {
    backgroundColor: 'primary.light'
  },
  '& .MuiDataGrid-row.Mui-selected:hover': {
    backgroundColor: 'primary.light'
  },

  '.MuiDataGrid-cell.MuiDataGrid-cell--editing': {
    border: 'turquoise 3px solid'
  }
}
