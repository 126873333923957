import React, { useState, useEffect } from 'react'
import { Select, MenuItem, Checkbox } from '@mui/material'
import { fetchItems } from '../../../service/InterfaceFunctions/fetchItems'
export default function LaptoptSelect ({ value, type, max, onChange }) {
  const [laptopList, setLaptopList] = useState([])
  const [laptopSelected, setLaptopSelected] = useState(value)

  async function fetchLaptopsList () {
    try {
      await fetchItems('laptopasset').then(result => {
        setLaptopList(result.sort())
      })
    } catch (error) {
      console.error(error)
      setLaptopList([])
    }
  }

  useEffect(() => {
    fetchLaptopsList()
  }, [])

  useEffect(() => {
    if (value && value.length > 0) {
      setLaptopSelected(value)
    }
  }, [value])

  const handleLaptopChange = event => {
    const selectedLaptopIDs = event.target.value
    let selectedLaptops
    if ((max === 1 || type === 'SingleUser') && selectedLaptopIDs.length > 1) {
      selectedLaptops = laptopList
        .filter(laptop => selectedLaptopIDs.includes(laptop.laptopID))
        .splice(selectedLaptopIDs.length-2,selectedLaptopIDs.length-1)
    } else {
      if (selectedLaptopIDs.length <= max)
        selectedLaptops = laptopList.filter(laptop =>
          selectedLaptopIDs.includes(laptop.laptopID)
        )
      else selectedLaptops = laptopSelected
    }

    setLaptopSelected(selectedLaptops)
    onChange(selectedLaptops)
  }

  return (
    <Select
      multiple
      value={laptopSelected.map(laptop => laptop.laptopID)}
      onChange={handleLaptopChange}
      renderValue={selectedLaptops =>
        selectedLaptops
          .map(
            laptopID =>
              laptopList.find(laptop => laptop.laptopID === laptopID)
                ?.laptopAssetID || ''
          )
          .join(', ')
      }
      id='laptop-select'
      variant='outlined'
      fullWidth
      label='laptop'
      MenuProps={{
        anchorOrigin: {
          vertical: 'top',
          horizontal: 'center'
        }
      }}
    >
      {laptopList.map(laptop => (
        <MenuItem key={laptop.laptopID} value={laptop.laptopID}>
          <Checkbox
            checked={laptopSelected.some(p => p.laptopID === laptop.laptopID)}
          />
          {laptop.laptopAssetID}
        </MenuItem>
      ))}
    </Select>
  )
}
