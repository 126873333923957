import React from 'react'
import { useGridApiRef } from '@mui/x-data-grid'
import GenericSelectCell from '../AdminSelectCells/GenericSelectCell'
import ManagerSelect from '../AdminSelectCells/ManagerSelect'
import RoleSelect from '../AdminSelectCells/RoleSelect'
import ProjectSelect from '../AdminSelectCells/ProjectSelect'
import TableComponent from '../../TableComponents'
import HolidayListSelect from '../AdminSelectCells/HolidayListSelect'
import dayjs from 'dayjs'
import AddCustomLeaveBalanceForUser from '../AdminCreateForms/AddCustomLeaveBalanceForUser'
export default function UserList ({ refreshTable }) {
  const apiRef = useGridApiRef()
  const itemName = 'user'
  const itemID = 'userID'
  const columns = [
    
    {
      field: 'archived',
      headerName: 'Status',
      width: 70,
      editable: true,
      valueFormatter: ( value ) =>
        value == 0 || value == null ? 'Active' : 'Archived'
    },
    {
      field: 'allowTimesheet',
      headerName: 'Timesheet Allowed',
      width: 100,
      editable: true,
      type:'boolean'
      
    },
    {
      field: 'employeeID',
      headerName: 'Employee ID',
      width: 150,
      editable: true
    },
    {
      field: 'name',
      headerName: 'First Name',
      width: 150,
      editable: true
    },
    {
      field: 'lname',
      headerName: 'Last Name',
      width: 150,
      editable: true
    },
    {
      field: 'type',
      headerName: 'Type',
      width: 100,
      editable: true,
      renderEditCell: params => (
        <GenericSelectCell
          label={'Type'}
          value={params.value}
          key={'typeID'}
          keyvalue={'typeName'}
          itemName={'employeetype'}
          onChange={async event => {
            await apiRef.current.setEditCellValue({
              id: params.id,
              field: params.field,
              value: event
            })
          }}
        />
      )
    },
    {
      field: 'roles',
      headerName: 'Roles',
      width: 300,
      editable: true,
      valueFormatter: ( value ) => value.map(role => role.roleName),
      renderEditCell: params => (
        <RoleSelect
          value={params.value}
          onChange={async event => {
            await apiRef.current.setEditCellValue({
              id: params.id,
              field: params.field,
              value: event
            })
          }}
        />
      )
    },
    {
      field: 'assignedProjects',
      headerName: 'Projects',
      width: 300,
      editable: true,
       valueFormatter: (value) => value.map(project => project.projectName),
      renderEditCell: params => (
        <ProjectSelect
          value={params.value}
          onChange={async event => {
            await apiRef.current.setEditCellValue({
              id: params.id,
              field: params.field,
              value: event
            })
          }}
        />
      )
    },
    {
      field: 'doJ',
      headerName: 'DOJ',
      type: 'date',
       valueGetter: (value) => (value !== null ? new Date(value) : null),
      width: 150,
      editable: true
    },
    {
      field: 'lastWorkingDate',
      headerName: 'Last Date',
      type: 'date',
       valueGetter: (value) => (value !== null ? new Date(value) : null),
      width: 150,
      editable: true
    },
    {
      field: 'email',
      headerName: 'Email',
      width: 300,
      editable: true
    },
    {
      field: 'secondaryEmail',
      headerName: 'Secondary Email',
      width: 300,
      editable: true
    },
    {
      field: 'department',
      headerName: 'Department',
      width: 100,
      editable: true,
      renderEditCell: params => (
        <GenericSelectCell
          label={'Department'}
          value={params.value}
          key={'departmentID'}
          keyvalue={'departmentName'}
          itemName={'department'}
          onChange={async event => {
            await apiRef.current.setEditCellValue({
              id: params.id,
              field: params.field,
              value: event
            })
          }}
        />
      )
    },
    {
      field: 'designation',
      headerName: 'Designation',
      width: 100,
      editable: true,
      renderEditCell: params => (
        <GenericSelectCell
          label={'Designation'}
          value={params.value}
          key={'designationID'}
          keyvalue={'designationName'}
          itemName={'designation'}
          onChange={async event => {
            await apiRef.current.setEditCellValue({
              id: params.id,
              field: params.field,
              value: event
            })
          }}
        />
      )
    },
    {
      field: 'team',
      headerName: 'Team',
      width: 100,
      editable: true,
      renderEditCell: params => (
        <GenericSelectCell
          value={params.value}
          label={'Team'}
          key={'teamNo'}
          keyvalue={'teamID'}
          itemName={'team'}
          onChange={async event => {
            await apiRef.current.setEditCellValue({
              id: params.id,
              field: params.field,
              value: event
            })
          }}
        />
      )
    },
    {
      field: 'managerID',
      headerName: 'Manager ID',
      width: 150,
      editable: true,
      renderEditCell: params => (
        <ManagerSelect
          value={params.value}
          team={apiRef.current.getCellValue(params.id, 'team')}
          onChange={async event => {
            await apiRef.current.setEditCellValue({
              id: params.id,
              field: params.field,
              value: event
            })
          }}
        />
      )
    },
    {
      field: 'office',
      headerName: 'Office code',
      width: 50,
      editable: true,
      renderEditCell: params => (
        <GenericSelectCell
          label={'Office'}
          value={params.value}
          key={'locationID'}
          keyvalue={'officeCode'}
          itemName={'officelocation'}
          extra={['city']}
          onChange={async event => {
            await apiRef.current.setEditCellValue({
              id: params.id,
              field: params.field,
              value: event
            })
          }}
        />
      )
    },
    {
      field: 'shift',
      headerName: 'Shift',
      width: 50,
      editable: true,
      renderEditCell: params => (
        <GenericSelectCell
          label={'Shift'}
          value={params.value}
          key={'shiftID'}
          keyvalue={'shiftName'}
          itemName={'shift'}
          extra={['shiftStartTime', 'shiftEndTime', 'shiftTimeZone']}
          onChange={async event => {
            await apiRef.current.setEditCellValue({
              id: params.id,
              field: params.field,
              value: event
            })
          }}
        />
      )
    },
    {
      field: 'assignedHolidayList',
      headerName: 'Holidays',
       valueFormatter: (value) => value.map(list => list.listName),
      width: 150,
      editable: true,
      renderEditCell: params => (
        <HolidayListSelect
          value={params.value}
          onChange={async event => {
            await apiRef.current.setEditCellValue({
              id: params.id,
              field: params.field,
              value: event
            })
          }}
        />
      )
    },
    {
      field: 'leaveBalancesOfEmployee',
      headerName: 'Leave Balance',
      width: 300,
      editable: true,
      renderCell: ({ value }) =>
        value
          .map(
            leavebalance =>
              dayjs(leavebalance.leaveYear).format('YYYY') +
              ':' +
              leavebalance.leaveTypeRef +
              '-' +
              leavebalance.allocatedLeave
          )
          .join(', '),
      renderEditCell: params => (
        <AddCustomLeaveBalanceForUser
          prevValue={params.value}
          open={apiRef.current.getCellMode(params.id, params.field) === 'edit'}
          userID={apiRef.current.getCellValue(params.id, 'userID')}
          onChange={async e => {
            await apiRef.current
              .setEditCellValue({
                id: params.id,
                field: params.field,
                value: e
              })
              .then(
                apiRef.current.stopCellEditMode({
                  id: params.id,
                  field: params.field
                })
              )

          }}
          handleClose={async event => {
            await apiRef.current
              .setEditCellValue({
                id: params.id,
                field: params.field,
                value: params.value
              })
              .then(
                apiRef.current.stopCellEditMode({
                  id: params.id,
                  field: params.field
                })
              )

          }}
        />
      )
    },
    {
      field: 'contact',
      headerName: 'Contact',
      width: 300,
      editable: true
    },
    {
      field: 'secondaryContact',
      headerName: 'Secondary Contact',
      width: 300,
      editable: true
    },
    {
      field: 'address',
      headerName: 'Address',
      width: 300,
      editable: true
    },
    {
      field: 'referredBy',
      headerName: 'Referred By',
      width: 150,
      editable: true
    }
  ]

  return (
    <TableComponent
      refreshTable={refreshTable}
      apiRef={apiRef}
      itemID={itemID}
      altItemID={'employeeID'}
      itemName={itemName}
      columns={columns}
    />
  )
}
