import React, { useState, useEffect } from 'react'
import { DataGrid, useGridApiRef } from '@mui/x-data-grid'
import { Typography } from '@mui/material'
import { PhoneAndroid } from '@mui/icons-material'
import dayjs from 'dayjs'
import { useAlert } from 'react-alert'
import { dataGridStyles } from '../../stylings/datagridStyles'
import CustomGridToolbarNoAdd from '../Admin/CustomGridToolbarNoAdd'
import { useAuthUser } from 'react-auth-kit'
import { fetchDeviceAssetsTransferHistory } from '../../service/deviceAssetService'

export default function DeviceAdminTransferHistoryTable ({
  setMainRefreshTable,
  deviceID,
  deviceAssetID
}) {
  const apiRef = useGridApiRef()
  const itemName = 'deviceassettransfer'
  const itemID = 'trackerID'
  const user = useAuthUser()
  const [refreshTable, setRefreshTable] = useState(false)
  const [items, setItems] = useState([])
  const [progress, setProgress] = useState(false)
  const alert = useAlert()

  async function fetchData () {
    try {
      setProgress(true)
      const res = await fetchDeviceAssetsTransferHistory(deviceID)
      const data = await res.json()
      setItems(data)
    } catch (error) {
      console.error(error)
      setProgress(false)
    } finally {
      setProgress(false)
    }
  }

  useEffect(() => {
    setRefreshTable(false)
    setMainRefreshTable(true)
    fetchData()
  }, [refreshTable])

  const columns = [
    {
      field: 'deviceAssetID',
      headerName: 'Device Asset ID',
      width: 250
    },

    {
      field: 'status',
      headerName: 'Status',
      width: 250,
      renderCell: ({ value }) =>
        value == 2
          ? 'Error'
          : value == 1
          ? 'Accepted'
          : value == 0
          ? 'Transfer Initiated'
          : value == -1
          ? 'Sent Back For Review'
          : value == -2
          ? 'Repair'
          : value == -3
          ? 'Denied'
          : value == -4
          ? 'Surrendered'
          : null
    },
    {
      field: 'transferrer',
      headerName: 'Sender',
      width: 250
    },
    {
      field: 'transferee',
      headerName: 'Receiver',
      width: 250
    },
    {
      field: 'transferDate',
      headerName: 'Date of Transfer',
      type: 'dateTime',
       valueGetter: (value) =>
        value !== null ? dayjs(value).tz('Asia/Kolkata').toDate() : null,
      width: 250
    },
    {
      field: 'transfereeResponseDate',
      headerName: 'Date of Response',
      type: 'dateTime',
       valueGetter: (value) =>
        value !== null ? dayjs(value).tz('Asia/Kolkata').toDate() : null,
      width: 250
    },
    {
      field: 'comments',
      headerName: 'Comments',
      width: 250
    },

    {
      field: 'newDamages',
      headerName: 'New Damages',
      width: 400,
      editable: false,
      renderCell: ({ value }) =>
        value
          ?.map((damage, index) => {
            let key = Object.keys(damage)[0]
            let val = Object.values(damage)[0]
            return `${key}: ${val}`
          })
          .join(', ')
    }
  ]

  return (
    <>
      <div>
        <div className='div-spaceToSides'>
          <Typography variant='h4'>Transfer History</Typography>
        </div>
        <DataGrid
          apiRef={apiRef}
          columns={columns}
          rows={items}
          getRowId={row => row[itemID]}
          sx={dataGridStyles}
          loading={progress}
          showCellVerticalBorder
          autoHeight
          autoSizeOnMount={true}
          initialState={{
            pagination: {
              paginationModel: {
                page: 0,
                pageSize: 5
              }
            },

            sorting: {
              sortModel: [{ field: 'transferDate', sort: 'desc' }]
            }
          }}
          pageSizeOptions={[5, 10, 15, 20, 100]}
          slots={{
            toolbar: CustomGridToolbarNoAdd
          }}
        />
      </div>
    </>
  )
}
