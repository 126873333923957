import React from 'react'
import './index.css'
import App from './App'
import { AuthProvider } from 'react-auth-kit'
import { createRoot } from 'react-dom/client'
import { ThemeProvider } from '@emotion/react'
import { theme } from './stylings/theme'
import AlertStylingsTemplate from './stylings/AlertStylingTemplate'
import { Provider } from 'react-alert'
import { Provider as StoreProvider } from 'react-redux'
import { reactAlertOptions } from './stylings/reactAlertOptions'
import store from './reduxstore/store.js'

const root = createRoot(document.getElementById('root'))
root.render(
  <>
    <ThemeProvider theme={theme}>
      <AuthProvider
        authType={'cookie'}
        authName={'_auth'}
        cookieDomain={window.location.hostname}
        cookieSecure={false}
      >
        <StoreProvider store={store}>
          <Provider template={AlertStylingsTemplate} {...reactAlertOptions}>
            <App />
          </Provider>
        </StoreProvider>
      </AuthProvider>
    </ThemeProvider>
  </>
)
