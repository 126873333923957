import {
  AddTaskRounded,
  CelebrationOutlined,
  ContrastRounded,
  EditCalendarOutlined,
  WarningAmberOutlined,
  Block
} from '@mui/icons-material'
import TreeLeaveIcon from '../../stylings/TreeLeaveIcon'
import dayjs from 'dayjs'
import { useMediaQuery } from 'react-responsive'
import { IconButton, Tooltip, Grid } from '@mui/material'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'
export const tileLogic = (
  attendanceData,
  holidays,
  today,
  isMobile,
  handleTodayChangeClick,
  handleHolidayClick,
  handleTodayClick,
  handleLeaveClick,
  handleYesterdayClick
) => {
  const isAlreadyMarked = date =>
    attendanceData.length > 0
      ? attendanceData.some(attendance =>
          dayjs(dayjs(attendance.date).toDate()).isSame(
            dayjs(date).toDate(),
            'day'
          )
        )
      : false
  const isFuture = date => dayjs(dayjs(date).toDate()).isAfter(today, 'day')
  const isPresent = date =>
    attendanceData.length > 0
      ? attendanceData.some(
          attendance =>
            dayjs(dayjs(attendance.date).toDate()).isSame(
              dayjs(date).toDate(),
              'day'
            ) && !attendance.leave
        )
      : false

  const isHoliday = date => {
    if (holidays.length > 0)
      return holidays.some(hol =>
        dayjs(dayjs(hol.holiday).toDate()).isSame(dayjs(date).toDate(), 'day')
      )
    else return false
  }

  const holidayName = date => {
    if (holidays.length > 0) {
      let holName = holidays.find(hol =>
        dayjs(dayjs(hol.holiday).toDate()).isSame(dayjs(date).toDate(), 'day')
      )
      holName = holName.occassion
      return holName
    } else return 'Holiday'
  }
  const isToday = date => dayjs(today).isSame(dayjs(date).toDate(), 'day')
  const isWeekend = date => dayjs(date).day() % 6 === 0
  const isInApprovalProcess = date =>
    attendanceData.length > 0
      ? attendanceData.some(
          attendance =>
            dayjs(dayjs(attendance.date).toDate()).isSame(
              dayjs(date).toDate(),
              'day'
            ) &&
            ((attendance.approved != null && !Boolean(attendance.approved)) ||
              (attendance.stillProcessing != null &&
                Boolean(attendance.approved)))
        )
      : false
  const isPast = date => dayjs(date).isBefore(dayjs(today).toDate(), 'day')
  const isLeave = date =>
    attendanceData.length > 0
      ? attendanceData.some(
          attendance =>
            attendance.leave &&
            dayjs(dayjs(attendance.date).toDate()).isSame(
              dayjs(date).toDate(),
              'day'
            )
        )
      : false
  const isHalfDay = date =>
    attendanceData.length > 0
      ? attendanceData.some(
          attendance =>
            attendance.shiftType === 'HALFDAY' &&
            dayjs(dayjs(attendance.date).toDate()).isSame(
              dayjs(date).toDate(),
              'day'
            )
        )
      : false

  const tileContent = ({ date, view }) => {
    if (view == 'month') {
      return (
        <Grid
          container
          style={{
            width: '100%',
            height: '100%',
            padding: '0% 1% 20% 1%',
            margin: '1%'
          }}
        >
          <Grid item xs={12} md={12} lg={12}>
            {isInApprovalProcess(date) ? (
              <IconButton sx={{ float: isMobile ? 'none' : 'right' }}>
                <CheckCircleIcon color='warning' fontSize='small' />
              </IconButton>
            ) : isLeave(date) ? (
              <IconButton sx={{ float: isMobile ? 'none' : 'right' }}>
                <TreeLeaveIcon />
              </IconButton>
            ) : isPast(date) && isPresent(date) ? (
              <IconButton sx={{ float: 'right' }}>
                <CheckCircleIcon color='info' fontSize='small' />
              </IconButton>
            ) : isToday(date) && isPresent(date) ? (
              <IconButton
                onClick={() => handleTodayChangeClick(date)}
                sx={{ float: isMobile ? 'none' : 'right' }}
              >
                <CheckCircleIcon color='info' fontSize='small' />
              </IconButton>
            ) : isHoliday(date) ? (
              <Tooltip title={holidayName(date)}>
                <IconButton
                  onClick={() => handleHolidayClick(date)}
                  sx={{ float: isMobile ? 'none' : 'right' }}
                >
                  <CelebrationOutlined color='warning' fontSize='small' />
                </IconButton>
              </Tooltip>
            ) : isWeekend(date) ? (
              <IconButton
                onClick={() => handleHolidayClick(date)}
                sx={{ float: isMobile ? 'none' : 'right' }}
              >
                <Block color='secondary' fontSize='small' />
              </IconButton>
            ) : !isWeekend(date) &&
              !isHoliday(date) &&
              isToday(date) &&
              !isPresent(date) ? (
              <IconButton
                onClick={() => handleTodayClick(date)}
                sx={{ float: isMobile ? 'none' : 'right' }}
              >
                <AddTaskRounded color='secondary' fontSize='small' />
              </IconButton>
            ) : isFuture(date) &&
              !(isHoliday(date) || isWeekend(date) || isLeave(date)) ? (
              <IconButton
                onClick={() => handleLeaveClick(date)}
                sx={{ float: isMobile ? 'none' : 'right' }}
              >
                <EditCalendarOutlined color='secondary' fontSize='small' />
              </IconButton>
            ) : isPast(date) ? (
              <IconButton
                onClick={() => handleYesterdayClick(date)}
                sx={{ float: isMobile ? 'none' : 'right' }}
              >
                <WarningAmberOutlined color='error' fontSize='small' />
              </IconButton>
            ) : (
              <IconButton style={{ visibility: 'hidden' }}>
                <CheckCircleIcon fontSize='small' />
              </IconButton>
            )}
            {isToday(date) && isHalfDay(date) ? (
              <IconButton
                onClick={() => {
                  console.log('Today')
                  handleTodayChangeClick(date)
                }}
                sx={{ float: isMobile ? 'none' : 'left' }}
              >
                <ContrastRounded color='secondary' fontSize='small' />
              </IconButton>
            ) : isHalfDay(date) ? (
              <IconButton
                sx={{ float: isMobile ? 'none' : 'left' }}
                onClick={() => {
                  console.log('Today wrong')
                }}
              >
                <ContrastRounded color='secondary' fontSize='small' />
              </IconButton>
            ) : null}
          </Grid>
          <Grid item xs={12} md={12} lg={12}>
            {date ? dayjs(date).format('DD') : null}
          </Grid>
        </Grid>
      )
    }
  }

  const tileClassName = ({ date, view }) => {
    if (view === 'month' && (isHoliday(date) || isWeekend(date))) {
      return 'customHolidayTile'
    }
  }
  return {
    isWeekend,
    isHoliday,
    isToday,
    isPresent,
    isPast,
    tileContent,
    tileClassName,
    isLeave,
    isAlreadyMarked
  }
}
