import React, { useEffect, useState } from 'react'
import { Paper, Typography } from '@mui/material'
import { fetchLeaveCount } from '../../service/leaveService'
import { useAuthUser } from 'react-auth-kit'
import { PieChart } from '@mui/x-charts/PieChart'
export default function LeavesTakenComponent () {
  const [leaveCount, setLeaveCount] = useState([])
  const [leavesTaken, setLeavesTaken] = useState(0)
  const [leavesBalance, setLeavesBalance] = useState(0)
  const [chartData, setChartData] = useState([])
  const user = useAuthUser()
  async function fetchData () {
    try {
      const response = await fetchLeaveCount(user().employeeID)
      const data = await response.json()
      setLeaveCount(data)
      setLeavesTaken(data.reduce((acc, count) => acc + count.LeavesTaken, 0))
      setLeavesBalance(data.reduce((acc, count) => acc + count.LeaveBalance, 0))
    } catch (error) {
      console.error(error)
    }
  }

  useEffect(() => {
    fetchData()
  }, [])

  const chartDataOptions = [
    {
      id: 1,
      value: leavesTaken,
      label: `Total Leaves Taken`
    },
    {
      id: 2,
      value: leavesBalance,
      label: `Total Leaves Balance`
    }
  ]

  return (
    <Paper
      elevation={2}
      style={{
        border: '0.5rem solid #e5f4fc',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        width: 'inherit',
        height: 'inherit'
      }}
    >
      <Typography variant='h4'>Leave Consumption</Typography>
      <PieChart
        series={[
          {
            data: chartDataOptions,
            innerRadius: 60,
            outerRadius: 150,
            paddingAngle: 0,
            cornerRadius: 4
          }
        ]}
        width={340}
        height={400}
        margin={{ right: 5 }}
        slotProps={{
          legend: {
            direction: 'row',
            position: { vertical: 'bottom', horizontal: 'middle' },
            padding: 0
          }
        }}
      />
    </Paper>
  )
}
