/*For use of BeQiSoft Pvt Ltd. */

import React, { useState, useEffect } from 'react'
import TextField from '@mui/material/TextField'
import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton
} from '@mui/material'
import AddIcon from '@mui/icons-material/Add'
import PersonAddAltIcon from '@mui/icons-material/PersonAddAlt'
import { addLeaveType } from '../../../service/leaveService'
import { CancelOutlined } from '@mui/icons-material'

export default function CreateLeaveTypeForm ({
  open,
  handleClose,
  setRefreshTable
}) {
  const [leaveType, setLeaveType] = useState(null)
  const [leavePercent, setLeavePercent] = useState(null)
  const [mandatoryIsEmpty, setMandatorIsEmpty] = useState(false)

  useEffect(() => {
    if (open) {
      resetState()
    }
  }, [open])

  const resetState = () => {
    setLeaveType(null)
    setLeavePercent(1)
    setMandatorIsEmpty(false)
  }
  const handleFormClose = () => {
    setLeaveType('')
    setLeavePercent(1)
    handleClose()
  }
  const handleSubmit = async e => {
    e.preventDefault()
    const leaveData = {
      leaveType,
      leavePercent
    }
    if ((leaveType && leavePercent) != null) {
      try {
        console.log('Leave Type Creating')
        addLeaveType(leaveData)
      } catch (error) {
        console.log(error)
      } finally {
        setRefreshTable(true)
        handleFormClose()
      }
    } else {
      console.log('User creation Error')
      setMandatorIsEmpty(true)
    }
  }

  return (
    <Dialog open={open}>
      <DialogTitle>
        <IconButton onClick={handleFormClose} sx={{ float: 'right' }}>
          <CancelOutlined />
        </IconButton>
        <PersonAddAltIcon /> Create Leave Type
      </DialogTitle>
      <DialogContent>
        <p>This will add a new leave type company-wide</p>

        <form noValidate>
          <TextField
            required
            id='outlined-basic-leaveName'
            label='Type Name'
            variant='outlined'
            fullWidth
            value={leaveType}
            onChange={e => setLeaveType(e.target.value)}
            style={{ margin: '20px auto' }}
            error={mandatoryIsEmpty && !leaveType}
          />
          <TextField
            required
            type='number'
            id='outlined-basic-email'
            label='Leave Percentage'
            variant='outlined'
            fullWidth
            value={leavePercent}
            InputProps={{
              inputProps: { min: 1 },
              onKeyDown: (e)=>{ e.preventDefault() }
            }}
            onChange={e => setLeavePercent(e.target.value)}
            style={{ margin: '20px auto' }}
            error={mandatoryIsEmpty && !leavePercent}
          />
          <Button
            variant='contained'
            className='button-gradient'
            onClick={handleSubmit}
          >
            <AddIcon />
            Add Leave Type
          </Button>
        </form>
      </DialogContent>
    </Dialog>
  )
}
