import React, { useState, useEffect } from 'react'
import { DataGrid, useGridApiRef } from '@mui/x-data-grid'
import { Typography, Button, IconButton, Paper } from '@mui/material'
import TableComponent from '../TableComponents'
import { PhoneAndroid, PhotoCamera } from '@mui/icons-material'
import dayjs from 'dayjs'
import GenericSelectCell from '../Admin/AdminSelectCells/GenericSelectCell'
import { fetchShortUserInfo } from '../../service/addUserService'
import { useAlert } from 'react-alert'
import { renderDeviceImageViewer } from '../Admin/AdminCreateForms/renderDeviceImageViewer'
import { fetchItems } from '../../service/InterfaceFunctions/fetchItems'
import { dataGridStyles } from '../../stylings/datagridStyles'
import CustomGridToolbarNoAdd from '../Admin/CustomGridToolbarNoAdd'
import DeviceTransferForm from './DeviceTransferForm'
import {
  fetchDeviceAssetsForemployee,
  repairOrSurrender,
  transferDeviceFromReceiver
} from '../../service/deviceAssetService'
import { useAuthUser } from 'react-auth-kit'
import DeviceAssetTransferTable from './DeviceAssetTransferTable'
import DeviceRepairOrSurrenderForm from './DeviceRepairOrSurrenderForm'

export default function DeviceAssetUserTable () {
  const apiRef = useGridApiRef()
  const itemID = 'deviceID'
  const user = useAuthUser()
  const [formOpen, setFormOpen] = useState(false)
  const [refreshTable, setRefreshTable] = useState(false)
  const [images, setImages] = useState([])
  const [items, setItems] = useState([])
  const [progress, setProgress] = useState(false)
  const [openTransferForm, setOpenTransferForm] = useState(false)
  const [openRepairSurrenderForm, setRepairSurrenderForm] = useState(false)
  const [status, setStatus] = useState(-2)

  const [row, setRow] = useState([])
  const alert = useAlert()
  async function fetchData () {
    try {
      setProgress(true)
      const res = await fetchDeviceAssetsForemployee(user().employeeID)
      const data = await res.json()
      setItems(data)
    } catch (error) {
      console.error(error)
      setProgress(false)
    } finally {
      setProgress(false)
    }
  }

  const handleTransferFormOpen = row => {
    setRow(row)
    setOpenTransferForm(true)
  }
  const handleRepairSurrenderFormOpen = (row, st) => {
    setRow(row)
    setStatus(st)
    setRepairSurrenderForm(true)
  }

  useEffect(() => {
    setRefreshTable(false)
    fetchData()
  }, [refreshTable])

  const renderActionsCell = params => {
    const { row } = params
    return (
      <div className='div-spaceToSides'>
        <Button
          variant='contained'
          className='button-gradient'
          sx={{ margin: '1%' }}
          onClick={async () => {
            handleTransferFormOpen(row)
          }}
        >
          Transfer
        </Button>
        <Button
          variant='outlined'
          color='warning'
          sx={{ margin: '1%' }}
          onClick={async () => {
            handleRepairSurrenderFormOpen(row, -2)
          }}
        >
          Needs Repair
        </Button>
        <Button
          variant='outlined'
          color='error'
          sx={{ margin: '1%' }}
          onClick={async () => {
            handleRepairSurrenderFormOpen(row, -4)
          }}
        >
          Surrender
        </Button>
      </div>
    )
  }

  const columns = [
    {
      field: 'deviceAssetID',
      headerName: 'Device Asset ID',
      width: 250
    },
    {
      field: 'inUse',
      headerName: 'In Use',
      width: 100,
      headerAlign:'left',
      type: 'boolean'
    },
    {
      field: 'brand',
      headerName: 'Brand',
      width: 250
    },
    {
      field: 'modelName',
      headerName: 'Model Name',
      width: 250
    },
    {
      field: 'category',
      headerName: 'Category',
      width: 250
    },
    {
      field: 'purchaseDate',
      headerName: 'Purchase Date',
      type: 'date',
       valueGetter: (value) =>
        value !== null ? dayjs(value).tz('Asia/Kolkata').toDate() : null,
      width: 150
    },

    {
      field: 'teamIdf',
      headerName: 'For Team',
      width: 150,
      editable: false
    },
    {
      field: 'empId',
      headerName: 'Employee ID',
      width: 150
    },
    {
      field: 'accessories',
      headerName: 'Accessories',
      width: 250
    },
    {
      field: 'deviceDamagesSet',
      headerName: 'Known Damages',
      width: 250,
      renderCell: ({ value }) =>
        value.map(param => param.damage + ':' + param.damageCount).join(', ')
    },
    {
      field: 'deviceImages',
      headerName: 'Images',
      width: 90,
      renderCell: params => (
        <IconButton
          size='small'
          onClick={event => {
            handleViewerClick(event, params.value)
          }}
        >
          <PhotoCamera />
        </IconButton>
      )
    },
    {
      field: 'actions',
      headerName: 'Actions',
      sortable: false,
      filterable: false,
      width: 400,
      renderCell: renderActionsCell
    }
  ]
  const [anchorEl, setAnchorEl] = useState(null)
  const [imageEditorEl, setImageEditorEl] = useState(null)

  const handleViewerClick = (event, images) => {
    setImageEditorEl(null)
    setAnchorEl(anchorEl ? null : event.currentTarget)
    setImages(images)
  }

  const renderImageViewer = renderDeviceImageViewer(
    anchorEl,
    setAnchorEl,
    images
  )

  return (
    <>
      <div className='div-paperStyle2'>
        <div>
          <div className='div-spaceToSides'>
            <Typography variant='h4'>
              <PhoneAndroid />
              Current Mobile Assets
            </Typography>
          </div>

          <DataGrid
            apiRef={apiRef}
            columns={columns}
            rows={items}
            getRowId={row => row[itemID]}
            sx={dataGridStyles}
            loading={progress}
            showCellVerticalBorder
            autoHeight
            autoSizeOnMount={true}
            initialState={{
              pagination: {
                paginationModel: {
                  page: 0,
                  pageSize: 5
                }
              }
            }}
            pageSizeOptions={[5, 10, 15, 20, 100]}
            slots={{
              toolbar: CustomGridToolbarNoAdd
            }}
          />
          {renderImageViewer()}
        </div>
        <DeviceTransferForm
          open={openTransferForm}
          deviceIDF={row.deviceID}
          deviceAssetID={row.deviceAssetID}
          deviceDamagesSet={row.deviceDamagesSet}
          handleClose={() => {
            setOpenTransferForm(false)
          }}
          alert={alert}
          setRefreshTable={setRefreshTable}
        />
        <DeviceRepairOrSurrenderForm
          open={openRepairSurrenderForm}
          deviceIDF={row.deviceID}
          deviceAssetID={row.deviceAssetID}
          deviceDamagesSet={row.deviceDamagesSet}
          handleClose={() => {
            setRepairSurrenderForm(false)
          }}
          alert={alert}
          setRefreshTable={setRefreshTable}
          status={status}
          setTransferTableRefresh={setRefreshTable}
        />
      </div>
      <div className='div-paperStyle2'>
        <DeviceAssetTransferTable
          setMainRefreshTable={setRefreshTable}
          transferTableRefresh={refreshTable}
        />
      </div>
    </>
  )
}
