import React, { useEffect, useState } from 'react'
import { IconButton } from '@mui/material'
import { Check, Cancel } from '@mui/icons-material'
import DeleteIcon from '@mui/icons-material/Delete'
import EditIcon from '@mui/icons-material/Edit'
import { useAuthUser, useIsAuthenticated } from 'react-auth-kit'
import { DataGrid,GridCellModes,GridRowModes } from '@mui/x-data-grid'
import CustomGridToolbarNoAdd from './Admin/CustomGridToolbarNoAdd'
import { confirmAlert } from 'react-confirm-alert'
import '../stylings/react-confirm-customalert.css'
import { useAlert } from 'react-alert'
import { fetchItems } from '../service/InterfaceFunctions/fetchItems.js'
import { updateItem } from '../service/InterfaceFunctions/updateItem.js'
import { deleteItem } from '../service/InterfaceFunctions/deleteItem.js'
import { dataGridStyles } from '../stylings/datagridStyles.js'

export default function TableComponent ({
  refreshTable,
  itemName,
  itemID,
  altItemID,
  intState,
  columns,
  apiRef,
  differentFetchFuction,
  fetchFunctionParametes,
  fetchFunctionParametes2,
  editDisableCondition,
  deleteDisableCondition,
  keepCheckBox,
  handleCellModesModelChange,
  customRows,
  customEdit,
  customDelete,
  setRefreshTable,
  rowSpecialColourCondition,
  updateMutation
}) {
  const isAuthenticated = useIsAuthenticated()
  const user = useAuthUser()
  const [progress, setProgress] = useState(false)
  const [rowModes, setRowModes] = useState({})
  const [items, setItems] = useState([])
  const [column, setColumn] = useState(columns)
  const [openConfirm, setOpenConfirm] = useState(false)
  const [confirmEnabled, setConfirmEnabled] = useState(true)
  const [cellModesModel, setCellModesModel] = useState([])
  const alert = useAlert()

  async function fetchData () {
    try {
      setProgress(true)
      if (typeof differentFetchFuction === 'function') {
        const res = await differentFetchFuction(
          fetchFunctionParametes,
          fetchFunctionParametes2
        )
        const data = await res.json()
        setItems(data)
        setProgress(false)
      } else if (customRows != null) {
        setItems(customRows)
        setProgress(false)
      } else {
        await fetchItems(itemName).then(result => {
          setItems(result)
          setProgress(false)
        })
      }
    } catch (error) {
      console.error(error)
      setProgress(false)
    }
  }

  useEffect(() => {
    if (user() && isAuthenticated()) {
      fetchData()
    }
  }, [
    user(),
    isAuthenticated(),
    refreshTable,
    apiRef,
    differentFetchFuction,
    fetchFunctionParametes,
    fetchFunctionParametes2,
    itemName,
    itemID,
    editDisableCondition,
    deleteDisableCondition,
    customRows,
    customEdit,
    customDelete
  ])

  const isRowInEditMode = rowID => {
    return rowModes[rowID]?.mode === GridRowModes.Edit ? true : false
  }

  const renderActionsCell = params => {
    const { row } = params
    const { mode } = rowModes[row[itemID]] || {}
    if (mode === GridRowModes.Edit) {
      return (
        <>
          <IconButton
            onClick={async () => {
              if (confirmEnabled) {
                await confirmEdit(row)
              } else {
                alert.info('Ensure all fields are ready before confirming')
                console.log('Row modes', rowModes)
              }
            }}
          >
            <Check color='success' />
          </IconButton>
          <IconButton onClick={() => handleCancelEdit(row[itemID])}>
            <Cancel color='secondary' />
          </IconButton>
        </>
      )
    } else
      return (
        <>
          <IconButton
            onClick={() => handleEdit(row[itemID])}
            disabled={
              editDisableCondition != null
                ? editDisableCondition(row[itemID])
                : false
            }
            sx={{
              visibility:
                editDisableCondition != null && editDisableCondition(row)
                  ? 'hidden'
                  : 'visible'
            }}
          >
            <EditIcon color='secondary' />
          </IconButton>
          <IconButton
            onClick={() => handleDelete(row)}
            disabled={
              deleteDisableCondition != null
                ? deleteDisableCondition(row)
                : false
            }
            sx={{
              visibility:
                deleteDisableCondition != null && deleteDisableCondition(row)
                  ? 'hidden'
                  : 'visible'
            }}
          >
            <DeleteIcon color='error' />
          </IconButton>
        </>
      )
  }

  const handleCancelEdit = itemID => {
    setRowModes(prevRowModes => ({
      ...prevRowModes,
      [itemID]: { mode: GridRowModes.View }
    }))
    fetchData()
  }

  const handleEdit = itemID => {
    setRowModes(prevRowModes => ({
      ...prevRowModes,
      [itemID]: { mode: GridRowModes.Edit }
    }))
    setOpenConfirm(true)
  }

  const confirmEdit = async item => {
    try {
      setRowModes(prevRowModes => ({
        ...prevRowModes,
        [item[itemID]]: { mode: GridRowModes.View }
      }))
      let res
      let update = {
        item: item,
        itemName: itemName
      }
      if (updateMutation != null) {
        res = await updateMutation({
          updateFunction:
            typeof customEdit === 'function' ? customEdit : updateItem,
          leaveData: typeof customEdit === 'function' ? item : update
        }).unwrap().then((payload)=>alert.show(payload))
      } else {
        res =
          typeof customEdit === 'function'
            ? await customEdit(item)
            : await updateItem(itemName, item)
      }

      if (res.ok) {
        const data = await res.text()
        alert.show(data)
        if (setRefreshTable != null) setRefreshTable(true)
      }
    } catch (error) {
      alert.error('Error in updation:', error)
      if (setRefreshTable != null) setRefreshTable(true)
    } finally {
      fetchData()
      if (setRefreshTable != null) setRefreshTable(true)
    }
  }

  const handleDelete = async item => {
    confirmAlert({
      title: 'Delete permanently?',
      buttons: [
        {
          label: 'Confirm',
          onClick: async () => {
            alert.show('Deleted')
            setRowModes(prevRowModes => ({
              ...prevRowModes,
              [item[itemID]]: { mode: GridRowModes.View }
            }))
            if (updateMutation != null) {
              let update = { item: item[itemID], itemName: itemName }
              await updateMutation({updateFunction:deleteItem,leaveData:update}).unwrap().then(() => {
                fetchData()
                if (setRefreshTable != null) setRefreshTable(true)
              })
            } else
              await deleteItem(itemName, item[itemID]).then(() => {
                fetchData()
                if (setRefreshTable != null) setRefreshTable(true)
              })
          }
        },
        {
          label: 'Cancel'
        }
      ]
    })
  }

  const columnsWithActions = [
    ...column,
    {
      field: 'actions',
      headerName: 'Actions',
      sortable: false,
      filterable: false,
      width: 150,
      renderCell: renderActionsCell
    }
  ]

  return (
    <DataGrid
      disableRowSelectionOnClick={keepCheckBox}
      checkboxSelection={keepCheckBox}
      getRowId={row => row[itemID]}
      loading={progress}
      rows={items}
      columns={columnsWithActions}
      rowModes={rowModes}
      onRowModeChange={newRowModes => setRowModes(newRowModes)}
      showCellVerticalBorder
      apiRef={apiRef}
      isCellEditable={params => {
        if (isRowInEditMode(params.row[itemID])) return 'rowLeaveColour'
      }}
      getRowClassName={params => {
        if (rowSpecialColourCondition)
          return rowSpecialColourCondition(params.row)
      }}
      sx={dataGridStyles}
      autoHeight
      autoSizeOnMount={true}
      initialState={{
        pagination: {
          paginationModel: {
            page: 0,
            pageSize: 5
          }
        },
        ...intState
      }}
      pageSizeOptions={[5, 10, 15, 20, 100]}
      slots={{
        toolbar: CustomGridToolbarNoAdd
      }}
      onCellEditStart={() => setConfirmEnabled(false)}
      onCellEditStop={() => {
        setConfirmEnabled(true)
      }}
      onCellDoubleClick={(params, event) => {
        event.defaultMuiPrevented = !isRowInEditMode(params.row[itemID])
      }}
      onCellClick={(params, event) => {
        event.defaultMuiPrevented = !isRowInEditMode(params.row[itemID])
        if (
          isRowInEditMode(params.row[itemID]) &&
          params.field == 'actions' &&
          params.cellMode == 'view'
        ) {
          setConfirmEnabled(true)
        } else if (
          isRowInEditMode(params.row[itemID]) &&
          params.field !== 'actions' &&
          params.isEditable &&
          params.cellMode == 'view'
        ) {
          setConfirmEnabled(false)
          setCellModesModel(prevModel => {
            return {
              [params.id]: {
                ...Object.keys(prevModel[params.id] || {}).reduce(
                  (acc, field) => ({
                    ...acc,
                    [field]: { mode: GridCellModes.View }
                  }),
                  {}
                ),
                [params.field]: { mode: GridCellModes.Edit }
              }
            }
          })
        }
      }}
      onCellKeyDown={(params, event) => {
        event.defaultMuiPrevented = !isRowInEditMode(params.row[itemID])
      }}
      cellModesModel={cellModesModel}
      onCellModesModelChange={newModes => setCellModesModel(newModes)}
    />
  )
}
