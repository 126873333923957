/*For use of BeQiSoft Pvt Ltd. */

import React, { useState, useEffect } from 'react'
import TextField from '@mui/material/TextField'
import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Select
} from '@mui/material'
import { CancelOutlined } from '@mui/icons-material'
import { Formik } from 'formik'
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import * as yup from 'yup'
import dayjs from 'dayjs'
import { addItem } from '../../../service/InterfaceFunctions/addItem'
import { useAlert } from 'react-alert'

export default function CreateSoftwareForm ({
  open,
  handleClose,
  setRefreshTable
}) {
  const alert = useAlert()
  const handleFormClose = () => {
    handleClose()
  }
  const handleValueSubmit = async e => {
    try {
      const res = await addItem('software', e)
      const data = await res.text()
      if (res.ok) alert.show(data)
      else alert.show('Error in creation, ensure IDs are unique')
    } catch (error) {
      console.log(error)
      alert.show('Error')
    } finally {
      setRefreshTable(true)
      handleFormClose()
    }
  }

  return (
    <Dialog open={open}>
      <DialogTitle>
        <IconButton onClick={handleFormClose} sx={{ float: 'right' }}>
          <CancelOutlined />
        </IconButton>
        Create Software
      </DialogTitle>
      <DialogContent>
        <Formik
          initialValues={{
            softwareID: null,
            softwareName: null,
            type: 'SingleUser',
            maxUsers: 1,
            purchaseDate: dayjs(new Date()),
            expirationDate: dayjs(new Date()),
            softwareKey: null,
            username: null,
            password: null,
            additionalInfo: null
          }}
          onSubmit={values => handleValueSubmit(values)}
          validationSchema={yup.object({
            softwareID: yup.string().min(7).required('Required'),
            softwareName: yup.string().min(1).required('Required'),
            maxUsers: yup
              .number()
              .min(1)
              .required('Required')
              .test(
                'max-users',
                'Max users must be 1 if type is SingleUser',
                function (value) {
                  const { parent } = this
                  if (parent.type === 'SingleUser') {
                    return value === 1
                  }
                  if (value < 1) return value === 1
                  return true
                }
              ),
            purchaseDate: yup.date().nonNullable().required('Required'),
            expirationDate: yup.date().min(yup.ref('purchaseDate'))
          })}
        >
          {props => {
            const {
              values,
              touched,
              errors,
              dirty,
              isSubmitting,
              handleChange,
              handleBlur,
              handleSubmit,
              handleReset,
              setFieldValue,
              isValid,
              submitCount
            } = props
            return (
              <form className='root' noValidate onSubmit={handleSubmit}>
                <TextField
                  id='softwareID-input'
                  label='Software ID'
                  variant='outlined'
                  fullWidth
                  value={values.softwareID}
                  required={true}
                  error={touched.softwareID && errors.softwareID}
                  onChange={handleChange('softwareID')}
                  style={{ margin: '20px auto' }}
                  helperText={'Minimum 7 characters'}
                />

                <TextField
                  id='softwareName-input'
                  label='Software Name'
                  variant='outlined'
                  required
                  fullWidth
                  value={values.softwareName}
                  error={touched.softwareName && errors.softwareName}
                  onChange={handleChange('softwareName')}
                  style={{ margin: '20px auto' }}
                />

                <FormControl
                  fullWidth
                  style={{ margin: '20px auto' }}
                  required
                  error={touched.type && errors.type}
                >
                  <InputLabel id='Software-type-label'>Type</InputLabel>
                  <Select
                    id='Software-type-select'
                    variant='outlined'
                    fullWidth
                    value={values.type}
                    label='Type'
                    error={touched.type && errors.type}
                    onChange={handleChange('type')}
                    MenuProps={{
                      anchorOrigin: {
                        vertical: 'top',
                        horizontal: 'center'
                      }
                    }}
                  >
                    <MenuItem value={'SingleUser'}>Single User</MenuItem>
                    <MenuItem value={'MultiUser'}>Multi User</MenuItem>
                  </Select>
                </FormControl>
                <TextField
                  id='maxUsers-input'
                  label='Max Users'
                  variant='outlined'
                  fullWidth
                  type='number'
                  value={values.maxUsers}
                  error={errors.maxUsers}
                  onChange={handleChange('maxUsers')}
                  style={{ margin: '20px auto' }}
                  InputProps={{
                    inputProps: {
                      min: 1
                    }
                  }}
                />
                <FormControl fullWidth style={{ margin: '20px auto' }}>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                      label='Purchase Date'
                      value={values.purchaseDate}
                      maxDate={values.expirationDate}
                      format='DD/MM/YYYY'
                      onChange={newVal =>
                        setFieldValue('purchaseDate', dayjs(newVal))
                      }
                      slotProps={{
                        textField: {
                          error:
                            touched.purchaseDate &&
                            errors.purchaseDate &&
                            values.purchaseDate != null
                        }
                      }}
                    />
                  </LocalizationProvider>
                </FormControl>
                <FormControl fullWidth style={{ margin: '20px auto' }}>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                      label='Expiration Date'
                      value={values.expirationDate}
                      
                      format='DD/MM/YYYY'
                      onChange={newVal =>
                        setFieldValue('expirationDate', dayjs(newVal))
                      }
                      minDate={values.purchaseDate}
                      slotProps={{
                        textField: {
                          error:
                            touched.expirationDate &&
                            errors.expirationDate &&
                            values.expirationDate != null
                        }
                      }}
                    />
                  </LocalizationProvider>
                </FormControl>
                <TextField
                  id='key-input'
                  label='Software Key'
                  variant='outlined'
                  fullWidth
                  value={values.softwareKey}
                  onChange={handleChange('softwareKey')}
                  style={{ margin: '20px auto' }}
                />
                <TextField
                  id='username-input'
                  label='Username'
                  variant='outlined'
                  fullWidth
                  value={values.username}
                  onChange={handleChange('username')}
                  style={{ margin: '20px auto' }}
                />
                <TextField
                  id='password-input'
                  label='Password'
                  variant='outlined'
                  fullWidth
                  value={values.password}
                  onChange={handleChange('password')}
                  style={{ margin: '20px auto' }}
                />

                <TextField
                  id='additionalInformation-input'
                  label='Comments'
                  variant='outlined'
                  fullWidth
                  value={values.additionalInfo}
                  onChange={handleChange('additionalInfo')}
                  style={{ margin: '20px auto' }}
                />
                {!isValid && submitCount > 0 && (
                  <div style={{ color: 'red', fontStyle: 'bold' }}>
                    Some fields may be empty or invalid
                  </div>
                )}
                <Button
                  variant='contained'
                  type='submit'
                  className='button-gradient'
                  style={{ margin: '20px' }}
                >
                  Submit
                </Button>
              </form>
            )
          }}
        </Formik>
      </DialogContent>
    </Dialog>
  )
}
