import AttendanceRequest from '../components/Manager/AttendanceRequests'
import {
  Container,
  Paper,
  Tabs,
  Tab,
  Typography,
  styled,
  Grid
} from '@mui/material'
import { CustomTab } from '../stylings/customtabstyle'
import React, { useState } from 'react'
import LeaveRequests from '../components/Manager/LeaveRequests'
import { useAuthUser } from 'react-auth-kit'
import AttendanceRequestsHistory from '../components/Manager/AttendanceRequestsHistory'
import LeaveRequestsHistory from '../components/Manager/LeaveRequestsHistory'
import { pageStyle } from '../stylings/pageStyle'
export default function ManagerHistoryBoard () {
  const [value, setValue] = useState(0)
  const user = useAuthUser()
  const handleChange = (event, newValue) => {
    setValue(newValue)
  }



  return user().modules.find(m=>m.name==='APPROVALS_MODULE')? (
    <Grid
      container
      columns={20}
      style={pageStyle}
    >
      <Grid item xs={20} md={20} lg={20} xl={20}>
        <Paper elevation={3} className='paperStyle2'>
          <Tabs
            indicatorColor='secondary'
            value={value}
            onChange={handleChange}
          >
            <CustomTab
              label={<Typography variant='h6'>Attendance</Typography>}
            />
            <CustomTab label={<Typography variant='h6'>Leaves</Typography>} />
          </Tabs>
          {value === 0 && <AttendanceRequestsHistory/>}
          {value === 1 && <LeaveRequestsHistory/>}
        </Paper>
      </Grid>
    </Grid>
  ) : null
}
