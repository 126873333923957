import { React, useState, useEffect } from 'react'
import {
  Dialog,
  DialogContent,
  DialogTitle,
  Button,
  IconButton,
  Select,
  MenuItem,
  Checkbox,
  Backdrop,
  CircularProgress
} from '@mui/material'
import { modifyAttendaceRequest } from '../../service/attendanceService'
import {
  FormControl,
  FormControlLabel,
  RadioGroup,
  Radio,
  InputLabel
} from '@mui/material'
import { useNavigate } from 'react-router-dom'
import { useAlert } from 'react-alert'
import { CancelOutlined } from '@mui/icons-material'
import { useAuthUser, useAuthHeader } from 'react-auth-kit'
import dayjs from 'dayjs'
import { useAddAttendanceMutation } from '../../reduxstore/features/attendanceRTKFile'
export default function TodayChangeDialog ({
  date,
  open,
  handleClose,
  attendanceDetails,
  handleRefreshTable
}) {
  const [shiftType, setShiftType] = useState(attendanceDetails.shiftType)
  const [workedO, setWorkedO] = useState([])
  const user = useAuthUser()
  const authHeader = useAuthHeader()
  const auth = authHeader()
  const [projectTitles, setProjectTitles] = useState(user().projects)
  const [progress, setProgress] = useState(false)
  const [mandatoryIsEmpty, setMandatoryIsEmpty] = useState(false)
  const [dayHalf, setDayHalf] = useState('FIRSTHALF')
  const navigate = useNavigate()
  const alert = useAlert()
  const [addAttendance, { data, isLoading, isError, isSuccess }] =
    useAddAttendanceMutation()
  const resetState = () => {
    setWorkedO([])
    setShiftType('FULLDAY')
    setDayHalf('FIRSTHALF')
    setMandatoryIsEmpty(false)
  }

  useEffect(() => {
    if (open) {
      resetState()
    }
    navigator.permissions.query({ name: 'geolocation' }).then(result => {
      if (result.state === 'denied' || result.state !== 'granted') {
        navigate('/nolocation')
      } else {
        navigate('/home')
      }
    })
  }, [open, date])

  const handleMarkAttendance = async () => {
    const authEmployeeID = user().employeeID
    console.log(authEmployeeID)
    const modifyattendanceDetails = {
      date: dayjs(attendanceDetails.date).toDate(),
      time: attendanceDetails.time,
      employee_id: authEmployeeID,
      shiftType: shiftType,
      dayHalf: dayHalf,
      leave: attendanceDetails.leave,
      workedOn: workedO.join(','),
      requestType: 'Today'
    }
    if (workedO.length) {
      setProgress(true)
      setMandatoryIsEmpty(false)
      try {
        await addAttendance({
          updateFunction: modifyAttendaceRequest,
          attendanceData: modifyattendanceDetails
        }).unwrap().then(payload => alert.show(payload))
        return data
      } catch (error) {
        console.error(error)
      } finally {
        setTimeout(() => {
          handleClose()
          handleRefreshTable(true)
          setProgress(false)
        }, 5000)
      }
    } else {
      setMandatoryIsEmpty(true)
    }
  }

  const handleChange = event => {
    const selectedOptions = event.target.value
    setWorkedO(selectedOptions)
  }

  return (
    <Dialog open={open}>
      <DialogTitle>
        Change Today's Attendance details
        <IconButton onClick={handleClose} style={{ float: 'right' }}>
          <CancelOutlined color='action' />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <p>
          Attendance details such as projects worked and type of workday(half
          day/full day) can be changed. Please note that if attendance is being
          changed from half day to full day, supervisor will be notified
        </p>
        <h4>Previous Attendance Details:</h4>
        <p>Projects Worked On: {attendanceDetails.workedOn}</p>
        <p>Type of Workday: {attendanceDetails.shiftType}</p>
        <h4>Change details to:</h4>
        <form className='div-centerstyle' noValidate autoComplete='off'>
          <FormControl required error={mandatoryIsEmpty}>
            <FormControlLabel
              label='Workday:'
              labelPlacement='start'
              control={
                <RadioGroup
                  row
                  aria-labelledby='demo-row-radio-buttons-group-label'
                  name='row-radio-buttons-group'
                  value={shiftType}
                  onChange={e => setShiftType(e.target.value)}
                >
                  <FormControlLabel
                    value='FULLDAY'
                    control={<Radio />}
                    label='Full Day'
                  />
                  <FormControlLabel
                    value='HALFDAY'
                    control={<Radio />}
                    label='Half Day'
                  />
                </RadioGroup>
              }
            />
          </FormControl>
          {shiftType == 'HALFDAY' ? (
            <FormControl required>
              <FormControlLabel
                label='Day Half:'
                labelPlacement='start'
                control={
                  <RadioGroup
                    row
                    value={dayHalf}
                    onChange={e => setDayHalf(e.target.value)}
                  >
                    <FormControlLabel
                      value='FIRSTHALF'
                      control={<Radio />}
                      label='First Half'
                    />
                    <FormControlLabel
                      value='SECONDHALF'
                      control={<Radio />}
                      label='Second Half'
                    />
                  </RadioGroup>
                }
              />
            </FormControl>
          ) : null}
          <FormControl
            required
            error={mandatoryIsEmpty && !workedO.length}
            fullWidth
            style={{ margin: '20px auto' }}
          >
            <InputLabel id='project-select-label'>Project</InputLabel>
            <Select
              value={workedO}
              multiple
              onChange={handleChange}
              id='projectID-select'
              variant='outlined'
              fullWidth
              label='Project'
              renderValue={selected => selected.join(', ')}
              MenuProps={{
                anchorOrigin: {
                  vertical: 'top',
                  horizontal: 'center'
                }
              }}
            >
              {projectTitles.map(project => (
                <MenuItem key={project.projectID} value={project.projectName}>
                  <Checkbox checked={workedO.includes(project.projectName)} />
                  {project.projectName}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <Button
            variant='contained'
            className='button-gradient'
            onClick={() => {
              handleMarkAttendance()
            }}
          >
            Re-mark Attendance
          </Button>
        </form>
      </DialogContent>
      <Backdrop
        sx={{ color: '#fff', zIndex: theme => theme.zIndex.drawer + 1 }}
        open={progress}
      >
        <CircularProgress />
      </Backdrop>
    </Dialog>
  )
}
