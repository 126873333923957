/*For use of BeQiSoft Pvt Ltd. */

import React from 'react'
import { Dialog, DialogContent, DialogTitle, IconButton } from '@mui/material'
import { CancelOutlined } from '@mui/icons-material'
import DamageCounter from './DamageCounter'

export default function AddCustomDeviceDamage ({
  open,
  handleClose,
  deviceIDFk,
  onChange,
  prevValue
}) {
  return (
    <Dialog open={open}>
      <DialogTitle>
        Damages Count
        <IconButton sx={{ float: 'right' }} onClick={handleClose}>
          <CancelOutlined />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <DamageCounter
          prevValue={prevValue}
          deviceIDFk={deviceIDFk}
          open={open}
          onChange={onChange}
          confirmButton={true}
        />
      </DialogContent>
    </Dialog>
  )
}
