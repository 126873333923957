import React, { useState, useEffect } from 'react'
import { useGridApiRef } from '@mui/x-data-grid'
import { Typography, Button, IconButton } from '@mui/material'
import TableComponent from '../TableComponents'
import {
  PhoneAndroid,
  PhotoCamera,
  RemoveRedEye,
  SwapHoriz
} from '@mui/icons-material'
import dayjs from 'dayjs'
import GenericSelectCell from '../Admin/AdminSelectCells/GenericSelectCell'
import { fetchShortUserInfo } from '../../service/addUserService'
import { useAlert } from 'react-alert'
import AddCustomDeviceDamage from '../Admin/AdminCreateForms/AddCustomDeviceDamage'
import RenderDeviceImageEditor from '../Admin/AdminCreateForms/RenderDeviceImageEditor'
import { renderDeviceImageViewer } from '../Admin/AdminCreateForms/renderDeviceImageViewer'
import DeviceAdminTransferHistoryTable from './DeviceAdminTransferHistoryTable'
import CreateDeviceAssetForm from '../Admin/AdminCreateForms/CreateDeviceAssetForm'

export default function DeviceAssetAdminTable () {
  const apiRef = useGridApiRef()
  const itemName = 'deviceasset'
  const itemID = 'deviceID'
  const [formOpen, setFormOpen] = useState(false)
  const [refreshTable, setRefreshTable] = useState(false)
  const [images, setImages] = useState([])
  const [deviceID, setDeviceID] = useState(null)
  const [deviceAssetID, setDeviceAssetID] = useState(null)
  const [isTransferView, setIsTransferView] = useState(false)
  const [refreshTransferTable, setRefreshTransferTable] = useState(false)
  const [progress, setProgress] = useState(false)
  const [anchorEl, setAnchorEl] = useState(null)
  const [imageEditorEl, setImageEditorEl] = useState(false)
  const alert = useAlert()

  useEffect(() => {
    setRefreshTable(false)
  }, [refreshTable])

  const handleFormClose = () => {
    setFormOpen(false)
  }

  const handleEditCellChangeCommitted = params => {
    const isValid =
      params.props.value &&
      (typeof params.props.value === 'string'
        ? params.props.value.trim().length > 0
        : true)
    if (!isValid) {
      alert.error('Cannot be empty')
      console.log(params)
    }
    return { ...params.props, error: isValid }
  }

  const columns = [
    {
      field: 'inUse',
      headerName: 'In Use',
      width: 100,
      type: 'boolean',
      headerAlign:'left',
      editable: true
    },
    {
      field: 'deviceAssetID',
      headerName: 'Device Asset ID*',
      width: 250,
      editable: true,
      preProcessEditCellProps: params => {
        handleEditCellChangeCommitted(params)
      }
    },

    {
      field: 'brand',
      headerName: 'Brand*',
      width: 250,
      editable: true,
      preProcessEditCellProps: params => {
        handleEditCellChangeCommitted(params)
      }
    },
    {
      field: 'modelName',
      headerName: 'Model Name*',
      width: 250,
      editable: true,
      preProcessEditCellProps: params => {
        handleEditCellChangeCommitted(params)
      }
    },

    {
      field: 'purchaseDate',
      headerName: 'Purchase Date',
      type: 'date',
       valueGetter: (value) =>
        value !== null ? dayjs(value).tz('Asia/Kolkata').toDate() : null,
      width: 150,
      editable: true,
      preProcessEditCellProps: params => {
        handleEditCellChangeCommitted(params)
      }
    },
    {
      field: 'warranty',
      headerName: 'Warranty*',
      width: 150,
      editable: true,
      preProcessEditCellProps: params => {
        handleEditCellChangeCommitted(params)
      }
    },
    {
      field: 'teamIdf',
      headerName: 'For Team',
      width: 150,
      editable: false
    },
    {
      field: 'empId',
      headerName: 'Employee ID',
      width: 150,
      editable: true,
      renderEditCell: params => (
        <GenericSelectCell
          label={'Employee'}
          value={params.value}
          key={'employeeID'}
          keyvalue={'employeeID'}
          itemName={'user'}
          fetchData={fetchShortUserInfo}
          extra={['name', 'lname']}
          onChange={async event => {
            await apiRef.current.setEditCellValue({
              id: params.id,
              field: params.field,
              value: event
            })
          }}
        />
      )
    },
    {
      field: 'firstName',
      headerName: 'First Name',
      width: 200
    },
    {
      field: 'lastName',
      headerName: 'Last Name',
      width: 200
    },
    {
      field: 'imeicode',
      headerName: 'IMEI Code*',
      width: 250,
      editable: true,
      preProcessEditCellProps: params => {
        handleEditCellChangeCommitted(params)
      }
    },
    {
      field: 'serialNo',
      headerName: 'Serial Number*',
      width: 250,
      editable: true,
      preProcessEditCellProps: params => {
        handleEditCellChangeCommitted(params)
      }
    },
    {
      field: 'processor',
      headerName: 'Processor*',
      width: 250,
      editable: true,
      preProcessEditCellProps: params => {
        handleEditCellChangeCommitted(params)
      }
    },
    {
      field: 'ram',
      headerName: 'RAM*',
      width: 250,
      editable: true,
      preProcessEditCellProps: params => {
        handleEditCellChangeCommitted(params)
      }
    },
    {
      field: 'storage',
      headerName: 'Storage*',
      width: 250,
      editable: true,
      preProcessEditCellProps: params => {
        handleEditCellChangeCommitted(params)
      }
    },
    {
      field: 'deviceDamagesSet',
      headerName: 'Known Damages',
      width: 300,
      renderCell: ({ value }) =>
       (value!=null&&value.length>0)? value.map(param => param.damage + ':' + param.damageCount).join(', '):null,
      renderEditCell: params => (
        <AddCustomDeviceDamage
          prevValue={params.value}
          open={apiRef.current.getCellMode(params.id, params.field) === 'edit'}
          deviceIDFk={apiRef.current.getCellValue(params.id, 'deviceID')}
          onChange={async e => {
            await apiRef.current
              .setEditCellValue({
                id: params.id,
                field: params.field,
                value: e
              })
              .then(
                apiRef.current.stopCellEditMode({
                  id: params.id,
                  field: params.field
                })
              )
          }}
          handleClose={async () => {
            await apiRef.current
              .setEditCellValue({
                id: params.id,
                field: params.field,
                value: params.value
              })
              .then(
                apiRef.current.stopCellEditMode({
                  id: params.id,
                  field: params.field
                })
              )
          }}
        />
      ),
      editable: true
    },
    {
      field: 'deviceImages',
      headerName: 'Images*',
      width: 60,
      editable: true,
      renderCell: params => (
        <IconButton
          size='small'
          onClick={event => {
            handleViewerClick(event, params.value, params.id, params.field)
          }}
        >
          <PhotoCamera />
        </IconButton>
      ),
      renderEditCell: params =>
        renderTheEditCell(
          apiRef,
          params,
          imageEditorEl,
          setImageEditorEl,
          alert,
          setImages,
          anchorEl,
          setAnchorEl
        )
    },
    {
      field: 'transfer',
      headerName: 'Transfer History',
      renderCell: params => (
        <Button
          onClick={() =>
            handleToggleChange(
              apiRef.current.getCellValue(params.id, 'deviceID'),
              params,
              'transfer'
            )
          }
        >
          <RemoveRedEye />
        </Button>
      ),
      width: '100',
      filterable: false,
      sortable: false
    }
  ]

  const handleViewerClick = (event, images, id, field) => {
    setAnchorEl(
      anchorEl ? null : Boolean(event.currentTarget) && !imageEditorEl
    )
    setImageEditorEl(false)
    setImages(images)
  }

  const renderImageViewer = renderDeviceImageViewer(
    anchorEl,
    setAnchorEl,
    images
  )

  const handleToggleChange = (deviceID, params, selectedValue) => {
    if (selectedValue === 'transfer') {
      setDeviceID(deviceID)
      setDeviceAssetID(params.deviceAssetID)
      setIsTransferView(true)
      setRefreshTransferTable(true)
    } else {
      setIsTransferView(false)
      setRefreshTable(true)
    }
  }

  return (
    <>
      <div className='div-paperStyle2'>
        <div className='div-spaceToSides'>
          {isTransferView ? (
            <IconButton onClick={() => handleToggleChange(null, null, 'list')}>
              <SwapHoriz fontSize='large' />
            </IconButton>
          ) : (
            <>
              <Typography variant='h4'>
                <PhoneAndroid />
                Mobile Asset
              </Typography>
              <Button
                variant='filled'
                className='button-gradient'
                onClick={() => setFormOpen(true)}
              >
                +Add new device
              </Button>
            </>
          )}
        </div>
        {isTransferView ? (
          <DeviceAdminTransferHistoryTable
            setMainRefreshTable={setRefreshTransferTable}
            deviceID={deviceID}
            setDeviceAssetID={deviceAssetID}
          />
        ) : (
          <TableComponent
            refreshTable={refreshTable}
            apiRef={apiRef}
            itemID={itemID}
            itemName={itemName}
            columns={columns}
          />
        )}
        {renderImageViewer()}
      </div>
      <CreateDeviceAssetForm
        open={formOpen}
        handleClose={handleFormClose}
        setRefreshTable={setRefreshTable}
      />
    </>
  )
}
function renderTheEditCell (
  apiRef,
  params,
  imageEditorEl,
  setImageEditorEl,
  alert,
  setImages,
  anchorEl,
  setAnchorEl
) {
  setAnchorEl(null)
  setImageEditorEl(true)
  return (
    <RenderDeviceImageEditor
      open={
        apiRef.current.getCellMode(params.id, params.field) === 'edit' &&
        !Boolean(anchorEl)
      }
      images={params.value}
      imageEditorEl={imageEditorEl}
      setImageEditorEl={setImageEditorEl}
      alert={alert}
      setImages={setImages}
      onChange={async e => {
        await apiRef.current
          .setEditCellValue({
            id: params.id,
            field: params.field,
            value: e
          })
          .then(
            apiRef.current.stopCellEditMode({
              id: params.id,
              field: params.field
            })
          )
      }}
      handleClose={async () => {
        await apiRef.current
          .setEditCellValue({
            id: params.id,
            field: params.field,
            value: params.value
          })
          .then(
            apiRef.current.stopCellEditMode({
              id: params.id,
              field: params.field
            })
          )
      }}
    />
  )
}
