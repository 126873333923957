import Cookies from 'js-cookie'
import { BASE_URL } from './apiService'


export const fetchAttendance = async (employeeID,auth) => {
  try {
    const authToken = Cookies.get('_auth')
    const response = await fetch(
      BASE_URL + '/attendance/employee/' + employeeID,
      {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': auth
        }
      }
    )
    return response
  } catch (error) {
    console.error(error)
    throw error
  }
}

export const addAttendance = async attendanceDetails => {
  try {
    const authToken = Cookies.get('_auth')
    const ipv4Address = await getIP()
    const attendance = {...attendanceDetails,ipv4Address}
    const response = await fetch(BASE_URL + '/attendance/add', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${authToken}`
      },
      body: JSON.stringify(attendance)
    })

    return response
  } catch (error) {
    console.error(error)
    throw error
  }
}

export const fetchLeaves = async (employeeID,startDate,endDate) => {
  try {
    const authToken = Cookies.get('_auth')
    const response = await fetch(
      BASE_URL + '/attendance/employee/getLeaves/' + employeeID +'?startDate='+startDate+'&endDate='+endDate,
      {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${authToken}`
        }
      }
    )
    return response
  } catch (error) {
    console.error(error)
    throw error
  }
}
export const fetchWorkAndLeaveInfo = async (employeeID,datemonth) => {
  try {
    const authToken = Cookies.get('_auth')
    const response = await fetch(
      BASE_URL + '/leave/employee/getWorkAndLeaveInfo?employeeID=' + employeeID +'&requestMonth='+datemonth,
      {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${authToken}`
        }
      }
    )
    return response
  } catch (error) {
    console.error(error)
    throw error
  }
}

export const addAttendaceRequest = async (aRequest) => {
  try {
    const authToken = Cookies.get('_auth')
    const response = await fetch(
      BASE_URL + '/attendance/attendanceRequest',
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${authToken}`
        },
        body:JSON.stringify(aRequest)
      }
    )
    return response
  } catch (error) {
    console.error(error)
    throw error
  }
}

export const modifyAttendaceRequest = async (aRequest) => {
  try {
    const authToken = Cookies.get('_auth')
    const response = await fetch(
      BASE_URL + '/attendance/modifyAttendance',
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${authToken}`
        },
        body:JSON.stringify(aRequest)
      }
    )
    return response
  } catch (error) {
    console.error(error)
    throw error
  }
}


export const addHolidayAttendaceRequest = async (aRequest) => {
  try {
    const authToken = Cookies.get('_auth')
    const response = await fetch(
      BASE_URL + '/attendance/holidayAttendanceRequest',
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${authToken}`
        },
        body:JSON.stringify(aRequest)
      }
    )
    return response
  } catch (error) {
    console.error(error)
    throw error
  }
}

export const fetchAttendaceRequests = async (team) => {
  try {
    const authToken = Cookies.get('_auth')
    const response = await fetch(
      BASE_URL + '/attendance/manager/getRequests',
      {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${authToken}`
        }
      }
    )
    return response
  } catch (error) {
    console.error(error)
    throw error
  }
}

export const fetchAttendaceRequestsForManager = async (team,employeeID) => {
  try {
    const authToken = Cookies.get('_auth')
    const response = await fetch(
      BASE_URL + '/attendance/manager/getRequests/'+employeeID,
      {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${authToken}`
        }
      }
    )
    return response
  } catch (error) {
    console.error(error)
    throw error
  }
}


export const arbitrateAttendaceRequests = async (approval,aRequest) => {
  try {
    const authToken = Cookies.get('_auth')
    const response = await fetch(
      BASE_URL + '/attendance/manager/arbitrateRequest/' + approval,
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${authToken}`
        },
        body:JSON.stringify(aRequest)
      }
    )
    return response
  } catch (error) {
    console.error(error)
    throw error
  }
}

export const fetchAttendaceRequestsHistoryForManager = async (team,employeeID) => {
  try {
    const authToken = Cookies.get('_auth')
    const response = await fetch(
      BASE_URL + '/attendance/manager/getRequestsHistory/'+employeeID,
      {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${authToken}`
        }
      }
    )
    return response
  } catch (error) {
    console.error(error)
    throw error
  }
}


export const getIP = async () => {
  const ipify = require('ipify2')
  try {
    const res = await ipify.ipv4()
    return res
  } catch (error) {
    return null
    throw error
  }
}
