import { Paper, Tabs, Tab, Typography, Grid } from '@mui/material'
import { CustomTab } from '../stylings/customtabstyle'
import React, { useEffect, useState } from 'react'
import { useAuthUser } from 'react-auth-kit'
import { pageStyle } from '../stylings/pageStyle'
import DeviceAssetAdminTable from '../components/Assets/DeviceAssetAdminTable'
import SoftwareAdminTable from '../components/Assets/SoftwareAdminTable'
import LaptopAssetAdminTable from '../components/Assets/LaptopAssetAdminTable'
import HardwareTable from '../components/Assets/HardwareTable'
export default function AssetAdminBoard () {
  const [value, setValue] = useState(0)
  const user = useAuthUser()
  const [modules, setModules] = useState([])
  const handleChange = (event, newValue) => {
    setValue(newValue)
  }
  useEffect(() => {
    if (user() && user().modules) {
      setModules(user().modules.map(p => p.name))
    }
  }, [user()])

  return modules.includes('ADMINCONTROLS_MODULE') ? (
    <Grid container columns={20} style={pageStyle}>
      <Grid item xs={20} md={20} lg={20} xl={20}>
        <Tabs indicatorColor='secondary' value={value} onChange={handleChange}>
          <CustomTab
            label={<Typography variant='h6'>Mobile Asset</Typography>}
          />
          <CustomTab
            label={<Typography variant='h6'>Laptop Asset</Typography>}
          />
          <CustomTab label={<Typography variant='h6'>Software</Typography>} />
          <CustomTab label={<Typography variant='h6'>Hardware</Typography>} />
        </Tabs>
        {value === 0 && <DeviceAssetAdminTable />}
        {value === 1 && <LaptopAssetAdminTable/>}
        {value === 2 && <SoftwareAdminTable/>}
        {value === 3 && <HardwareTable/>}
      </Grid>
    </Grid>
  ) : null
}
