import React, { useEffect, useState } from 'react'
import { Paper, Typography, Grid } from '@mui/material'
import UserTimesheet from '../components/Timesheet/UserTimesheet'
import { Event } from '@mui/icons-material'
import { useAuthUser } from 'react-auth-kit'

export default function EmployeeTimesheet () {
  const [refreshTable, setRefreshTable] = useState(false)
  const user = useAuthUser()
  useEffect(() => {
    setRefreshTable(false)
  }, [refreshTable])

  return  user().modules.find(m => m.name === 'TIMESHEET_USER_MODULE') ?<UserTimesheet refreshTable={refreshTable} setRefreshTable={setRefreshTable}/>:null
}
