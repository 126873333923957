import { useState, React } from 'react'
import { useAuthUser } from 'react-auth-kit'
import { useGridApiRef } from '@mui/x-data-grid'
import TableComponent from '../TableComponents'
import 'dayjs/locale/en-in'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import dayjs from 'dayjs'
import timezone from 'dayjs/plugin/timezone'
import '../../stylings/react-confirm-customalert.css'
import { getUserTimesheet } from '../../service/timesheetservice'
import { submitForManagerApproval } from '../../service/timesheetservice'
import {
  Backdrop,
  Button,
  Checkbox,
  CircularProgress,
  Grid,
  MenuItem,
  Paper,
  Select,
  Typography
} from '@mui/material'
import { Event } from '@mui/icons-material'
import { useAlert } from 'react-alert'
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers'
export default function UserTimesheet ({ refreshTable, setRefreshTable }) {
  dayjs.extend(timezone)
  const user = useAuthUser()
  const apiRef = useGridApiRef()
  const itemName = 'timesheet'
  const itemID = 'timesheetID'
  const [month, setMonth] = useState(dayjs(new Date()).format('YYYY-MM-DD'))
  const today = new Date()
  const [progress, setProgress] = useState(false)
  const alert = useAlert()
  const deleteDisable = row => row.attendanceDate != null
  const editDisable = row => row.approvalByManagerCount >= 0
  const rowColourConditions = row =>
    row.fullDayLeave
      ? 'rowLeaveColour'
      : row.holidayOccasion != null
      ? 'rowHolidayColour'
      : ''

  const submitToManager = async () => {
    try {
      setProgress(true)
      if (month !== null && month !== '') {
        const dt = await submitForManagerApproval(
          user().employeeID,
          dayjs(month).format('YYYY-MM-DD')
        )
        const res = await dt.text()
        alert.show(res)
        console.log(res)
      } else alert.error('Select month')
    } catch (error) {
      setProgress(false)
      alert.error('Error occurred during submission')
      console.log('Error ' + error)
    } finally {
      setRefreshTable(true)
      setProgress(false)
    }
  }

  const columns = [
    {
      field: 'attendanceDate',
      headerName: 'Date',
      width: 100,
      editable: false,
      valueGetter: value =>
        value !== null
          ? dayjs(dayjs(value).tz('Asia/Kolkata').toDate()).format('DD/MM/YYYY')
          : null
    },
    {
      field: 'approvalByManagerCount',
      headerName: 'Status',
      width: 150,
      valueGetter: value =>
        value == -2
          ? 'Needs review'
          : value == -1
          ? 'Not submitted'
          : value === 0
          ? 'Pending approval'
          : 'Approved'
    },
    {
      field: 'shiftName',
      headerName: 'Shift',
      width: 100,
      colSpan: (value, row) =>
        row.fullDayLeave || row.holidayOccasion ? 6 : 1,
      valueGetter: (row, value) =>
        Boolean(row?.fullDayLeave || row?.holidayOccasion)
          ? row?.fullDayLeave
            ? 'LEAVE'
            : row?.holidayOccasion
          : row.shiftName,
      renderCell: ({ row, value }) =>
        row.fullDayLeave || row.holidayOccasion ? (
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              width: '110%'
            }}
          >
            {row.fullDayLeave ? 'LEAVE' : row.holidayOccasion}
          </div>
        ) : (
          row.shiftName
        )
    },
    {
      field: 'shiftStartTime',
      headerName: 'Shift Start',
      width: 100,
      type: 'string',
      valueGetter: (value, row) => {
        return Boolean(row?.fullDayLeave || row?.holidayOccasion)
          ? null
          : value.toString()
      }
    },
    {
      field: 'shiftEndTime',
      headerName: 'Shift End',
      width: 100,
      valueGetter: (value, row) =>
        Boolean(row?.fullDayLeave || row?.holidayOccasion)
          ? null
          : row.shiftEndTime
    },
    {
      field: 'attendanceTime',
      headerName: 'Attendance Logged Time',
      width: 200,
      type: 'string',
      valueGetter: (value, row) =>
        Boolean(row?.fullDayLeave || row?.holidayOccasion) ? null : value
    },
    {
      field: 'workingDayType',
      headerName: 'Day',
      width: 100,
      valueGetter: (value, row) =>
        Boolean(row?.fullDayLeave || row?.holidayOccasion) ? null : value
    },
    {
      field: 'workedOnProjects',
      headerName: 'Project',
      width: 300,
      sortable: false,
      filterable: false,
      editable: true,
      valueGetter: (value, row) =>
        Boolean(row.fullDayLeave || row.holidayOccasion) ? null : value,
      renderEditCell: params => (
        <Select
          value={params.value}
          onChange={async event => {
            let projectName = event.target.value
            let newValue
            let projectNames = params.value ? params.value.split(',') : []
            let index = projectNames.findIndex(
              name => name.trim() === projectName.trim()
            )
            if (index !== -1) {
              projectNames.splice(index, 1)
            } else {
              projectNames.push(projectName)
            }
            newValue = projectNames.join(', ')
            await apiRef.current.setEditCellValue({
              id: params.id,
              field: params.field,
              value: newValue
            })
          }}
          variant='outlined'
          fullWidth
          renderValue={() => params.value}
          MenuProps={{
            anchorOrigin: {
              vertical: 'top',
              horizontal: 'center'
            }
          }}
        >
          {user().projects.map(project => (
            <MenuItem key={project.projectID} value={project.projectName}>
              <Checkbox
                checked={
                  typeof params.value === 'string' &&
                  params.value.includes(project.projectName)
                }
              />
              {project.projectName}
            </MenuItem>
          ))}
        </Select>
      )
    },
    {
      field: 'managerComments',
      headerName: 'Remarks',
      sortable: false,
      filterable: false,
      width: 200
    },
    {
      field: 'userComments',
      headerName: 'Employee Comments',
      sortable: false,
      filterable: false,
      editable: true,
      width: 200
    }
  ]
  return (
    <Grid
      container
      spacing={1}
      style={{
        width: '90vw',
        height: 'min-content',
        margin: '0 auto auto auto',
        display: 'flex',
        alignItems: 'flex-start'
      }}
    >
      <Grid item xs={12} md={12}>
        <Paper
          elevation={2}
          className='paperStyle2'
          sx={{ border: '0.5rem solid #e5f4fc' }}
        >
          <div className='div-spaceToSides'>
            <Typography variant='h4'>
              <Event />
              Timesheet
            </Typography>
            <div className='div-centerstylerow'>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <div>
                  <DatePicker
                    format='MM'
                    views={['month']}
                    label='Month'
                    value={dayjs(month)}
                    minDate={dayjs(today).subtract(1, 'months')}
                    maxDate={dayjs(today)}
                    onChange={newVal =>
                      setMonth(dayjs(newVal).format('YYYY-MM-DD'))
                    }
                  />
                </div>
              </LocalizationProvider>
              {month ? (
                <Button
                  variant='contained'
                  className='button-gradient'
                  onClick={submitToManager}
                  sx={{ margin: '20px' }}
                >
                  Submit Timesheet
                </Button>
              ) : null}
            </div>
          </div>
          {month ? (
            <TableComponent
              refreshTable={refreshTable}
              columns={columns}
              apiRef={apiRef}
              itemName={itemName}
              itemID={itemID}
              intState={{
                sorting: {
                  sortModel: [{ field: 'attendanceDate', sort: 'asc' }]
                }
              }}
              differentFetchFuction={getUserTimesheet}
              fetchFunctionParametes={user().employeeID}
              fetchFunctionParametes2={month}
              deleteDisableCondition={deleteDisable}
              editDisableCondition={editDisable}
              rowSpecialColourCondition={rowColourConditions}
            />
          ) : null}
        </Paper>
      </Grid>
    </Grid>
  )
}
