import { React } from 'react'
import { Divider, Typography } from '@mui/material'
import {useAuthUser} from 'react-auth-kit'
import LogoutButton from '../LogoutButton'
import { Link } from 'react-router-dom'
export default function Profile () {
  const auth = useAuthUser()
  console.log(auth().user!=null ? auth().user : 'null')
  return (
   
      <div className='div-centerstyle' style={{ padding: '20px' }}>
        <Typography variant='h6'>Employee ID: {auth().employeeID}</Typography>
        <Typography variant='h7'>Email: {auth().email}</Typography>
        <Typography variant='h7'>Roles: {auth().roles.join(',')}</Typography>
        <Typography variant='button' component={Link} to='/userdetails' className='outline-pill' sx={{marginBottom:'10px'}}>User Details</Typography>       
        <LogoutButton />
      </div>
    
  )
}
