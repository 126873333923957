import React, { useState, useEffect } from 'react'
import { Select, MenuItem, Checkbox } from '@mui/material'
import { fetchItems } from '../../../service/InterfaceFunctions/fetchItems'
export default function ShiftSelect ({ value, onChange }) {
  const [shiftList, setShiftList] = useState([])
  const [shiftsSelected, setShiftsSelected] = useState([])
  useEffect(() => {
    async function fetchShiftsList () {
      try {
        await fetchItems('shift').then(result => {
          setShiftList(result)
        })
        
      } catch (error) {
        console.error(error)
        setShiftList([])
      }
    }
    fetchShiftsList()
  }, [])

  const handleShiftChange = event => {
    const selectedShiftIDs = event.target.value
    const selectedShifts = shiftList.filter(shift =>
      selectedShiftIDs.includes(shift.shiftID)
    )
    setShiftsSelected(selectedShifts)
    onChange(selectedShifts)
  }

  return (
    <Select
      multiple
      value={value.map(shift => shift.shiftID)}
      onChange={handleShiftChange}
      renderValue={selectedRoles =>
        selectedRoles
          .map(
            shiftID =>
              shiftList.find(shift => shift.shiftID === shiftID)?.shiftName || ''
          )
          .join(', ')
      }
      variant='outlined'
      fullWidth
      label='Shift'
      MenuProps={{
        anchorOrigin: {
          vertical: 'top',
          horizontal: 'center'
        }
      }}
    >
      {shiftList.map(shift => (
        <MenuItem key={shift.shiftID} value={shift.shiftID}>
          <Checkbox checked={shiftsSelected.indexOf(shift)>-1} />
          {shift.shiftName}
        </MenuItem>
      ))}
    </Select>
  )
}
