import React, { useState, useEffect } from 'react'
import { Select, MenuItem, Checkbox } from '@mui/material'
import { fetchItems } from '../../../service/InterfaceFunctions/fetchItems'

export default function TeamMultiSelect ({ value, onChange }) {
  const [teamList, setTeamList] = useState([])
  const [teamsSelected, setTeamsSelected] = useState([])

  useEffect(() => {
    async function fetchTeamList () {
      try {
        const result = await fetchItems('team')
        setTeamList(result)
      } catch (error) {
        console.error(error)
        setTeamList([])
      }
    }
    fetchTeamList()
  }, [])

  const handleTeamChange = event => {
    const selectedTeamIDs = event.target.value
    const selectedTeams = teamList.filter(t =>
      selectedTeamIDs.includes(t.teamNo)
    )
    setTeamsSelected(selectedTeams)
    onChange(selectedTeams)
  }

  return (
    <Select
      multiple
      label={'Team'}
      value={value.map(t => t.teamNo)}
      onChange={handleTeamChange}
      renderValue={selectedRoles =>
        selectedRoles
          .map(
            teamNo => teamList.find(t => t.teamNo === teamNo)?.teamID || ''
          )
          .join(', ')
      }
      variant='outlined'
      fullWidth
      MenuProps={{
        anchorOrigin: {
          vertical: 'top',
          horizontal: 'center'
        }
      }}
    >
      {teamList.map(t => (
        <MenuItem key={t.teamNo} value={t.teamNo}>
          <Checkbox checked={teamsSelected.indexOf(t) > -1} />
          {t.teamID}
        </MenuItem>
      ))}
    </Select>
  )
}
