import React, { useState, useEffect } from 'react'
import {
  getAllLeaveBalanceReports,
  getAllLeaveReports
} from '../../service/reportsService'
import { CircularProgress, Backdrop } from '@mui/material'
import { useAuthUser, useIsAuthenticated } from 'react-auth-kit'
import { DataGrid, useGridApiRef } from '@mui/x-data-grid'
import CustomGridToolbarNoAdd from '../Admin/CustomGridToolbarNoAdd'
import dayjs from 'dayjs'
export default function LeaveBalance ({
  startDate,
  department,
  team,
  employee_id,
  shift,
  refreshTable
}) {
  const isAuthenticated = useIsAuthenticated()
  const user = useAuthUser()
  const [progress, setProgress] = useState(false)
  const [items, setItems] = useState([])
  const [column, setColumn] = useState([])
  const apiRef = useGridApiRef()
  async function fetchData () {
    try {
      setProgress(true)
      const res = await getAllLeaveBalanceReports({
        startDate: dayjs(startDate).format('YYYY-MM-DD'),
        team: team,
        department: department,
        empID: employee_id,
        shift: shift
      })
      const data = await res.json()
      setItems(data)
    } catch (error) {
      console.error(error)
      setItems(null)
    } finally {
      setProgress(false)
    }
  }
  const columns = [
    {
      field: 'year',
      headerName: 'Leave Year',
      valueGetter: (params) => dayjs(startDate).format('YYYY'), 
      width: '150'
    },
    {
      field: 'employeeID',
      headerName: 'Employee ID',
      width: '150'
    },
    {
      field: 'name',
      headerName: 'First Name',
      width: '150'
    },
    {
      field: 'lname',
      headerName: 'Last Name',
      width: '150'
    },
    {
      field: 'department',
      headerName: 'Department',
      width: '150'
    },
    {
      field: 'team',
      headerName: 'Team',
      width: '150'
    },
    {
      field: 'shift',
      headerName: 'Shift',
      width: '150'
    },
    {
      field: 'office',
      headerName: 'Office Location',
      width: '150'
    },
    {
      field: 'leaveType',
      headerName: 'Leave Type',
      width: '150'
    },
    {
      field: 'TotalAllocatedLeaves',
      headerName: 'Total Allocated Leaves',
      width: '150'
    },
    {
      field: 'LeavesTaken',
      headerName: 'Leaves Taken',
      width: '150'
    },
    {
      field: 'LeavesPendingApproval',
      headerName: 'Leaves Pending Approval',
      width: '150'
    },
    {
      field: 'LeaveBalance',
      headerName: 'Leave Balance',
      width: '150'
    }
  ]
  useEffect(() => {
    if (user() && isAuthenticated()) {
      fetchData()
    }
  }, [refreshTable, startDate, employee_id, department, team, shift])

  const dataGridStyles = {
    boxShadow: 2,
    margin: 1,

    '& .MuiDataGrid-columnHeader': {
      color: 'white',
      backgroundColor: '#67729D'
    },
    '& .MuiDataGrid-row.Mui-selected': {
      backgroundColor: 'primary.light'
    },
    '& .MuiDataGrid-row.Mui-selected:hover': {
      backgroundColor: 'primary.light'
    },
    '.MuiDataGrid-cell.MuiDataGrid-cell--editing': {
      border: 'turquoise 3px solid'
    }
  }
  return (
    <>
      <>
        <DataGrid
          disableRowSelectionOnClick
          disableVirtualization
          getRowId={row => `${row.employeeID}-${row.leaveType}`}
          loading={progress}
          rows={items}
          columns={columns}
          apiRef={apiRef}
          sx={dataGridStyles}
          showCellVerticalBorder
          showColumnVerticalBorder
          autoHeight
          slots={{
            toolbar: CustomGridToolbarNoAdd
          }}
          initialState={{
            pagination: {
              paginationModel: {
                page: 0,
                pageSize: 5
              }
            }
          }}
          pageSizeOptions={[5, 10, 15, 20, 100]}
        />
      </>

    </>
  )
}
