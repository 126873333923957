import React, { useState } from 'react'
import { Paper, Typography, Grid } from '@mui/material'
import LeaveReportFilterPage from './LeaveReportFilters'
import LeaveReport from './LeaveReport'
import LeaveBalance from './LeaveBalance'
export default function LeaveReportPage () {
  const [generateReport, setGenerateReport] = useState(false)
  const [generateBalance, setGenerateBalance] = useState(false)
  const [selectedValues, setSelectedValues] = useState({
    startDate: null,
    endDate: null,
    department: null,
    team: null,
    shift: null,
    employee_id: null
  })
  const [selectedValuesLB, setSelectedValuesLB] = useState({
    leaveBalanceYear: null,
    department: null,
    team: null,
    shift: null,
    employee_id: null
  })

  const handleGenerateReport = values => {
    setGenerateReport(true)
    setSelectedValues(values)
  }
  const handleGenerateBalance = values => {
    setGenerateBalance(true)
    setSelectedValuesLB(values)
  }

  return (
    <Grid container spacing={1}>
      <Grid item xs={12} md={12}>
        <Paper elevation={2} className='paperStyle2'>
          <Typography variant='h4'>Leave Report Details</Typography>
          <LeaveReportFilterPage onGenerateReport={handleGenerateReport} onGenerateBalance={handleGenerateBalance}/>
        </Paper>
      </Grid>
      {generateReport && (
        <Grid item xs={12} md={12}>
          <Paper
            elevation={2}
            className='paperStyle2'
            style={{ border: '0.5rem solid #e5f4fc' }}
          >
            <Typography variant='h4'>Leave Report</Typography>
            <LeaveReport {...selectedValues} />
          </Paper>
        </Grid>
      )}
      {generateBalance && (
        <Grid item xs={12} md={12}>
          <Paper
            elevation={2}
            className='paperStyle2'
            style={{ border: '0.5rem solid #e5f4fc' }}
          >
            <Typography variant='h4'>Leave Balance Report</Typography>
            <LeaveBalance {...selectedValuesLB} />
          </Paper>
        </Grid>
      )}
    </Grid>
  )
}
