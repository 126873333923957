import Cookies from 'js-cookie'
import { BASE_URL } from './apiService'

export const getUserTimesheet = async (employeeID,monthDate) => {
    try {
      const authToken = Cookies.get('_auth')
      const response = await fetch(BASE_URL + '/timesheet/getForEmployee?employeeID='+employeeID+'&monthDate='+monthDate, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${authToken}`
        },
      })
      return response
    } catch (error) {
      console.error(error)
      throw error
    }
  }



  export const getTeamTimesheet = async (team,empID,monthDate) => {
    try {
      const authToken = Cookies.get('_auth')
      const response = await fetch(BASE_URL + '/timesheet/getForTeam?employeeId='+empID+'&monthDate='+monthDate, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${authToken}`
        },
        body:team
      })
      return response
    } catch (error) {
      console.error(error)
      throw error
    }
  }

  export const submitForManagerApproval = async (empID,monthDate) => {
    try {
      const authToken = Cookies.get('_auth')
      const response = await fetch(BASE_URL + '/timesheet/submitForEmployee?employeeID='+empID+'&monthDate='+monthDate, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${authToken}`
        },
      })
      return response
    } catch (error) {
      console.error(error)
      throw error
    }
  }

  export const sendBackToEmployee = async (tID,managerRemark) => {
    try {
      const authToken = Cookies.get('_auth')
      const response = await fetch(BASE_URL + '/timesheet/resendForReview?timesheetID='+tID, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${authToken}`
        },
        body:managerRemark
      })
      return response
    } catch (error) {
      console.error(error)
      throw error
    }
  }

  export const approveTimesheetAndSendToAdmin = async (employeeID,monthDate) => {
    try {
      const authToken = Cookies.get('_auth')
      const response = await fetch(BASE_URL + '/timesheet/approveForEmployee?employeeID='+employeeID+'&monthDate='+monthDate, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${authToken}`
        },
      })
      return response
    } catch (error) {
      console.error(error)
      throw error
    }
  }

  export const downLoadExcelTest =async ()=>
  {
    try{
      const authToken = Cookies.get('_auth')
      const response = await fetch(BASE_URL +'/timesheet/getReportForAdmin?employeeID=AdminTestsdsd&monthDate=2024-05-10', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/javascript',
          Authorization: `Bearer ${authToken}`
        },
        body: 'Team1'
      })
      return response
    }
    catch{
      console.log('Error')
    }
  }

  export const adminTimesheetReport =async ({monthDate,employeeID,team})=>
    {
      try{
        const authToken = Cookies.get('_auth')
        const url = new URL(BASE_URL + '/timesheet/getReportForAdmin')
        let params = {
          employeeID,
          monthDate
        }
        if(employeeID!=null)
          params.employeeID=employeeID
        params.monthDate=monthDate
        url.search = new URLSearchParams(params).toString()
        const response = await fetch(url, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/javascript',
            Authorization: `Bearer ${authToken}`
          },
          body: team
        })
        return response
      }
      catch{
        console.log('Error')
      }
    }