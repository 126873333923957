import React, { useState, useEffect } from 'react'
import { CircularProgress, Backdrop, Select, MenuItem } from '@mui/material'
import { useAuthUser, useIsAuthenticated } from 'react-auth-kit'
import { DataGrid, useGridApiRef } from '@mui/x-data-grid'
import dayjs from 'dayjs'
import TableComponent from '../../TableComponents'
import {
  getAllAttendanceOverridables,
  overrideAttendance
} from '../../../service/overrideService'
export default function AttendanceOverrideTable ({
  startDate,
  endDate,
  department,
  team,
  employee_id,
  shift
}) {
  const isAuthenticated = useIsAuthenticated()
  const user = useAuthUser()
  const [progress, setProgress] = useState(false)
  const [items, setItems] = useState([])
  const apiRef = useGridApiRef()
  const itemName = 'attendance'
  const itemID = 'attendanceID'
  const [refreshTable, setRefreshTable] = useState(false)

  async function fetchData () {
    try {
      setProgress(true)
      const res = await getAllAttendanceOverridables({
        startDate: dayjs(startDate).format('YYYY-MM-DD'),
        endDate: dayjs(endDate).format('YYYY-MM-DD'),
        team: team,
        department: department,
        empID: employee_id,
        shift: shift
      })
      const data = await res.json()
      const newRows = data.map(item => ({
        attendanceID: item.attendance.attendanceID,
        approved: item.attendance.approved,
        comments: item.attendance.comments,
        date: dayjs(item.attendance.date).toDate(),
        employee_id: item.attendance.employee_id,
        ipv4Address: item.attendance.ipv4Address,
        latitude: item.attendance.latitude,
        leave: item.attendance.leave,
        longitude: item.attendance.longitude,
        managerRemark: item.attendance.managerRemark,
        requestType: item.attendance.requestType,
        shiftType: item.attendance.shiftType,
        dayHalf: item.attendance.dayHalf,
        time: item.attendance.time,
        workedOn: item.attendance.workedOn,
        name: item.name,
        lname: item.lname,
        team: item.team,
        department: item.department,
        shift: item.shift,
        office: item.officeLocation,
        requestDate: item.attendance.requestDate,
        approvedBy: item.attendance.approvedBy,
        approvedDate: item.attendance.approvedDate
      }))
      setItems(newRows)
    } catch (error) {
      console.error(error)
    } finally {
      setProgress(false)
      //eee
    }
  }


  const column = [
    {
      field: 'date',
      headerName: 'Date',
      valueGetter: value =>
        value !== null
          ? dayjs(dayjs(value).tz('Asia/Kolkata').toDate()).format('DD/MM/YYYY')
          : null,
      width: '150'
    },
    {
      field: 'name',
      headerName: 'First Name',
      width: '150'
    },
    {
      field: 'lname',
      headerName: 'Last Name',
      width: '150'
    },
    {
      field: 'employee_id',
      headerName: 'Employee ID',
      width: '150'
    },
    {
      field: 'department',
      headerName: 'Department',
      width: '150'
    },
    {
      field: 'team',
      headerName: 'Team',
      width: '150'
    },
    {
      field: 'shift',
      headerName: 'Shift',
      width: '150'
    },
    {
      field: 'office',
      headerName: 'Office Location',
      width: '150'
    },
    {
      field: 'requestDate',
      headerName: 'Requested On',
      valueGetter: value =>
        value !== null
          ? dayjs(value).tz('Asia/Kolkata').format('DD/MM/YYYY')
          : null,
      width: '150'
    },
    {
      field: 'shiftType',
      headerName: 'Workday Type',
      width: '150',
      editable: true,
      renderEditCell: params => (
        <Select
          value={params.value}
          onChange={async event => {
            await apiRef.current.setEditCellValue({
              id: params.id,
              field: params.field,
              value: event.target.value
            })
          }}
        >
          <MenuItem value={'FULLDAY'}>FULLDAY</MenuItem>
          <MenuItem value={'HALFDAY'}>HALFDAY</MenuItem>
        </Select>
      )
    },
    {
      field: 'dayHalf',
      headerName: 'Day Half',
      width: '150',
      editable: true,
      renderEditCell: params => (
        <Select
          value={params.value}
          onChange={async event => {
            await apiRef.current.setEditCellValue({
              id: params.id,
              field: params.field,
              value: event.target.value
            })
          }}
        >
          <MenuItem value={'FIRSTHALF'}>FIRSTHALF</MenuItem>
          <MenuItem value={'SECONDHALF'}>SECONDHALF</MenuItem>
        </Select>
      )
    },
    {
      field: 'workedOn',
      headerName: 'Worked On',
      width: '150'
    },
    {
      field: 'latitude',
      headerName: 'Lat',
      width: '150'
    },
    {
      field: 'longitude',
      headerName: 'Long',
      width: '150'
    },
    {
      field: 'ipv4Address',
      headerName: 'IPV4',
      width: '150'
    },
    {
      field: 'comments',
      headerName: 'Comments',
      width: '150'
    },
    {
      field: 'managerRemark',
      headerName: 'Manager Remarks',
      width: '150',
      editable: true
    },
    {
      field: 'approvedBy',
      headerName: 'Approved By',
      width: '150'
    },
    {
      field: 'approvedDate',
      headerName: 'Approved Date',
      valueGetter: value =>
        value !== null
          ? dayjs(value).tz('Asia/Kolkata').format('DD/MM/YYYY')
          : null,
      width: '150'
    }
  ]

  useEffect(() => {
    setTimeout(() => {
      fetchData().then(() => setRefreshTable(false))
    }, 500)
  }, [refreshTable, startDate, endDate, team, shift, employee_id, department])

  return (
    <TableComponent
      refreshTable={refreshTable}
      apiRef={apiRef}
      columns={column}
      loading={progress}
      customRows={items}
      itemName={itemName}
      itemID={itemID}
      customEdit={overrideAttendance}
      setRefreshTable={setRefreshTable}
    />
  )
}
