import React, { useState, useEffect } from 'react'
import { useGridApiRef } from '@mui/x-data-grid'
import { Typography, Button } from '@mui/material'
import TableComponent from '../TableComponents'
import { Cable } from '@mui/icons-material'
import dayjs from 'dayjs'
import GenericSelectCell from '../Admin/AdminSelectCells/GenericSelectCell'
import { fetchShortUserInfo } from '../../service/addUserService'
import { useAlert } from 'react-alert'
import CreateHardwareForm from '../Admin/AdminCreateForms/CreateHardwareForm'
export default function HardwareTable () {
  const apiRef = useGridApiRef()
  const itemName = 'hardware'
  const itemID = 'hardwareNo'
  const [formOpen, setFormOpen] = useState(false)
  const [refreshTable, setRefreshTable] = useState(false)
  const alert = useAlert()

  const handleEditCellChangeCommitted = params => {
    const isValid =
      params.props.value &&
      (typeof params.props.value === 'string'
        ? params.props.value.trim().length > 0
        : true)
    if (!isValid) {
      alert.error('Cannot be empty')
      console.log(params)
    }
    return { ...params.props, error: isValid }
  }
  const handleRefreshTable = newState => {
    setRefreshTable(newState)
  }
  const handleClose = () => {
    setFormOpen(false)
  }

  useEffect(() => {
    setRefreshTable(false)
  }, [refreshTable])

  const columns = [
    {
      field: 'hardwareID',
      headerName: 'Hardware ID*',
      width: 200,
      editable: true,
      preProcessEditCellProps: params => {
        handleEditCellChangeCommitted(params)
      }
    },
    {
      field: 'hardwareName',
      headerName: 'Hardware Name*',
      width: 200,
      editable: true,
      preProcessEditCellProps: params => {
        handleEditCellChangeCommitted(params)
      }
    },

    {
      field: 'purchaseDate',
      headerName: 'Purchase Date',
      width: 200,
      editable: true,
      type: 'date',
      valueGetter: value =>
        value !== null ? dayjs(value).tz('Asia/Kolkata').toDate() : null,
      preProcessEditCellProps: params => {
        handleEditCellChangeCommitted(params)
      }
    },
    {
      field: 'assignedToEmp',
      headerName: 'Assigned To Employee',
      width: 200,
      editable: true,
      renderEditCell: params => (
        <GenericSelectCell
          label={'Employee'}
          value={params.value}
          key={'employeeID'}
          keyvalue={'employeeID'}
          itemName={'user'}
          fetchData={fetchShortUserInfo}
          extra={['name', 'lname']}
          onChange={async event => {
            await apiRef.current.setEditCellValue({
              id: params.id,
              field: params.field,
              value: event
            })
          }}
        />
      )
    },
    {
      field: 'firstName',
      headerName: 'First Name',
      width: 200
    },
    {
      field: 'lastName',
      headerName: 'Last Name',
      width: 200
    },
    {
      field: 'additionalInfo',
      headerName: 'Additional Info',
      width: 200,
      editable: true
    }
  ]

  return (
    <>
      <div className='div-paperStyle2'>
        <div className='div-spaceToSides'>
          <Typography variant='h4'>
            <Cable />
            Hardware
          </Typography>
          <Button
            variant='filled'
            className='button-gradient'
            onClick={() => setFormOpen(true)}
          >
            +Add new hardware
          </Button>
        </div>
        <TableComponent
          refreshTable={refreshTable}
          apiRef={apiRef}
          itemID={itemID}
          itemName={itemName}
          columns={columns}
        />
        <CreateHardwareForm
          open={formOpen}
          handleClose={handleClose}
          setRefreshTable={setRefreshTable}
        />
      </div>
    </>
  )
}
