/*For use of BeQiSoft Pvt Ltd. */

import React, { useState, useEffect } from 'react'
import TextField from '@mui/material/TextField'
import {
  Button,
  FormControl,
  InputLabel,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Chip,
  ListItem,
  Stack,
  Divider
} from '@mui/material'
import AddIcon from '@mui/icons-material/Add'
import GenericSelectCell from '../AdminSelectCells/GenericSelectCell'
import dayjs from 'dayjs'
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { CancelOutlined } from '@mui/icons-material'

export default function AddCustomLeaveBalanceForUser ({
  open,
  handleClose,
  userID,
  onChange,
  prevValue
}) {
  const [leaveTypeRef, setLeaveTypeRef] = useState(null)
  const [leaveYear, setLeaveYear] = useState(null)
  const [allocatedLeave, setAllocatedLeave] = useState(1)
  const [mandatoryIsEmpty, setMandatoryIsEmpty] = useState(false)
  const [selectedBalances, setSelectedBalances] = useState([])
  const resetState = () => {
    setMandatoryIsEmpty(false)
    setLeaveTypeRef(null)
    setLeaveYear(null)
    setAllocatedLeave(1)
    setSelectedBalances(prevValue != null ? prevValue : [])
  }
  useEffect(() => {
    if (open) {
      resetState()
    }
  }, [open])

  const handleSubmit = async e => {
    try {
      onChange(selectedBalances)
    } catch (error) {
      console.log(error)
    }
  }

  const handleSelectedAdd = e => {
    const leaveData = {
      userIDRef: userID,
      leaveYear: dayjs(leaveYear).format('YYYY'),
      leaveTypeRef: leaveTypeRef,
      allocatedLeave: allocatedLeave
    }
    if (leaveTypeRef != null && allocatedLeave != null && leaveYear != null) {
      try {
        setMandatoryIsEmpty(false)
        const updatedCellValue = [...selectedBalances, leaveData]
        setSelectedBalances(updatedCellValue)
        return
      } catch (error) {
        console.log(error)
      }
    } else {
      setMandatoryIsEmpty(true)
      console.log('User creation Error')
    }
  }

  const handleDeleteSelected = allToDelete => {
    setSelectedBalances(sel => sel.filter(allocated => allocated !== allToDelete))
  }

  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle>
        Leave Balance
        <IconButton  sx={{ float: 'right' }} onClick={handleClose}>
          <CancelOutlined />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <ShowList />
        <Divider />
        <form noValidate style={{ marginTop: '1%' }}>
          <div className='div-spaceToSides'>
            <FormControl
              required
              error={mandatoryIsEmpty && !leaveYear}
              fullWidth
            >
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  format='YYYY'
                  views={['year']}
                  label='Year *'
                  value={leaveYear}
                  onChange={newVal => setLeaveYear(newVal)}
                  slotProps={{
                    textField: {
                      error: mandatoryIsEmpty && !leaveYear
                    }
                  }}
                />
              </LocalizationProvider>
            </FormControl>
            <FormControl
              required
              error={mandatoryIsEmpty && !leaveTypeRef}
              fullWidth
            >
              <InputLabel id='type-select-label'>Leave Type</InputLabel>
              <GenericSelectCell
                label={'Leave Type'}
                value={leaveTypeRef}
                itemName={'leavetype'}
                onChange={newVal => setLeaveTypeRef(newVal.trim())}
                key={'leaveID'}
                keyvalue={'leaveType'}
              />
            </FormControl>
            <TextField
              fullWidth
              required
              error={mandatoryIsEmpty && !allocatedLeave}
              type='number'
              id='outlined-basic-email'
              label='Leave Days'
              variant='outlined'
              value={allocatedLeave}
              onChange={e => setAllocatedLeave(e.target.value)}
            />
            <Button
              variant='contained'
              className='button-gradient'
              onClick={handleSelectedAdd}
              sx={{ margin: '1%', padding: '1%' }}
            >
              <AddIcon />
              Add
            </Button>
          </div>
        </form>
        <Button
          variant='contained'
          className='button-gradient'
          onClick={handleSubmit}
          sx={{ margin: '1%', padding: '1%' }}
        >
          Confirm
        </Button>
      </DialogContent>
    </Dialog>
  )

  function ShowList () {
    return (
      <Stack direction='row' spacing={0}>
        {selectedBalances.map(leavebalance => (
          <ListItem key={leavebalance} value={leavebalance}>
            <Chip
              variant='outlined'
              onDelete={() => handleDeleteSelected(leavebalance)}
              label={
                dayjs(leavebalance.leaveYear).format('YYYY') +
                ':' +
                leavebalance.leaveTypeRef +
                '-' +
                leavebalance.allocatedLeave
              }
            />
          </ListItem>
        ))}
      </Stack>
    )
  }
}
