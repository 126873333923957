import React, { useEffect, useState } from 'react'
import { CircularProgress, Backdrop } from '@mui/material'
import { useAuthUser, useIsAuthenticated } from 'react-auth-kit'
import { fetchAttendaceRequestsHistoryForManager } from '../../service/attendanceService'
import { DataGrid, useGridApiRef } from '@mui/x-data-grid'
import dayjs from 'dayjs'
import CustomGridToolbarNoAdd from '../Admin/CustomGridToolbarNoAdd'
import { dataGridStyles } from '../../stylings/datagridStyles'

export default function AttendanceRequestsHistory () {
  const [AttendanceRequests, setAttendanceRequest] = useState([])
  const isAuthenticated = useIsAuthenticated()
  const user = useAuthUser()
  const [rowSelectionModel, setRowSelectionModel] = useState([])
  const apiRef = useGridApiRef()
  const [progress, setProgress] = useState(false)

  async function fetchData () {
    setProgress(true)
    try {
      const response = await fetchAttendaceRequestsHistoryForManager(
        user().team,
        user().employeeID
      )
      const data = await response.json()
      const newRows = data.map(item => ({
        attendanceID: item.attendance.attendanceID,
        approved: item.attendance.approved,
        comments: item.attendance.comments,
        date: dayjs(item.attendance.date).toDate(),
        employee_id: item.attendance.employee_id,
        ipv4Address: item.attendance.ipv4Address,
        latitude: item.attendance.latitude,
        leave: item.attendance.leave,
        longitude: item.attendance.longitude,
        managerRemark: item.attendance.managerRemark,
        requestType: item.attendance.requestType,
        shiftType: item.attendance.shiftType,
        time: item.attendance.time,
        workedOn: item.attendance.workedOn,
        name: item.name,
        lname: item.lname,
        requestDate: item.attendance.requestDate,
        approvedDate: item.attendance.approvedDate,
        approvedBy: item.attendance.approvedBy
      }))
      setAttendanceRequest(newRows)
    } catch (error) {
      console.error(error)
    }
    finally{
      setProgress(false)
    }
  }

  useEffect(() => {
    if (user() && isAuthenticated()) fetchData()
  }, [user(), isAuthenticated()])

  const columns = [
    {
      field: 'approved',
      headerName: 'Status',
      width: 150,
       valueGetter: (value) => (value ? 'Approved' : 'Denied')
    },
    {
      field: 'employee_id',
      headerName: 'Employee ID',
      width: 150
    },
    {
      field: 'name',
      headerName: 'First Name',
      width: 150
    },
    {
      field: 'lname',
      headerName: 'Last Name',
      width: 150
    },
    {
      field: 'date',
      headerName: 'Date',
      type: 'date',
       valueGetter: (value) =>
        value !== null ? dayjs(value).toDate() : null,
      width: 150
    },
    {
      field: 'requestDate',
      headerName: 'Request Sent On',
      type: 'date',
       valueGetter: (value) =>
        value !== null ? dayjs(value).toDate() : null,
      width: 150
    },
    {
      field: 'approvedDate',
      headerName: 'Approved On',
      type: 'date',
       valueGetter: (value) =>
        value !== null ? dayjs(value).toDate() : null,
      width: 150
    },
    {
      field: 'approvedBy',
      headerName: 'Approval By',
      width: 300
    },
    {
      field: 'comments',
      headerName: 'Reason for Request',
      width: 200,
      sortable: false,
      filterable: false
    },
    {
      field: 'managerRemark',
      headerName: 'Remarks',
      sortable: false,
      filterable: false,
      width: 200
    }
  ]



  return (

        <DataGrid
          disableRowSelectionOnClick
          showCellVerticalBorder
          loading={progress}
          autoHeight
          rows={AttendanceRequests}
          columns={columns}
          getRowId={row => row.attendanceID}
          apiRef={apiRef}
          sx={dataGridStyles}
          slots={{
            toolbar: CustomGridToolbarNoAdd
          }}
          initialState={{
            sorting: {
              sortModel: [{ field: 'attendanceDate', sort: 'asc' }]
            },
            pagination: {
              paginationModel: {
                page: 0,
                pageSize: 5
              }
            }
          }}
          pageSizeOptions={[5, 10, 15, 20, 100]}
        />

  )
}
