import React, { useEffect, useState } from 'react'
import {
  Collapse,
  Switch,
  FormControlLabel,
  Paper,
  Button,
  Typography,
  Grid
} from '@mui/material'
import { EventBusy } from '@mui/icons-material'
import ApplyLeaves from '../components/Leave/Applyleaves'
import UserLeaveRequests from '../components/Leave/LeavesInProgress'
import HolidayList from '../components/Leave/HolidaysList'
import LeaveCount from '../components/Leave/LeaveCount'

export default function Leaves () {
  const [formOpen, setFormOpen] = useState(false)
  const [refreshTable, setRefreshTable] = useState(false)
  const [openLeaveCount, setOpenLeaveCount] = useState(false)
  const [openHolidayCalender, setOpenHolidayCalender] = useState(false)
  const handleClose = () => {
    setFormOpen(false)
  }

  useEffect(() => {
    setRefreshTable(false)
  }, [refreshTable])

  return (
    <Grid
      container
      spacing={1}
      style={{
        width: '90vw',
        height: 'min-content',
        margin: '0 auto auto auto',
        display: 'flex',
        alignItems: 'flex-start'
      }}
    >
      <Grid container spacing={1}>
        <Grid item xs={12} md={12}>
          <FormControlLabel
            control={
              <Switch
                checked={openHolidayCalender}
                onChange={() => {
                  setOpenHolidayCalender(prev => !prev)
                }}
              />
            }
            label='Show Holiday List'
          />
          <FormControlLabel
            control={
              <Switch
                checked={openLeaveCount}
                onChange={() => {
                  setOpenLeaveCount(prev => !prev)
                }}
              />
            }
            label='Show Leave Count'
          />
        </Grid>
        <Grid item xs={12} md={12}>
          <Collapse in={openLeaveCount}>
            <LeaveCount refreshTable={refreshTable}/>
          </Collapse>
        </Grid>
        <Grid item xs={12} md={12}>
          <Collapse in={openHolidayCalender}>
            <HolidayList />
          </Collapse>
        </Grid>
      </Grid>
      <Grid item xs={12} md={12}>
        <Paper
          elevation={2}
          className='paperStyle2'
          style={{ border: '0.5rem solid #e5f4fc' }}
        >
          <div className='div-spaceToSides'>
            <Typography variant='h4'>
              <EventBusy />
              Leave Requests
            </Typography>
            <Button
              variant='filled'
              className='button-gradient'
              onClick={() => setFormOpen(true)}
            >
              +Apply leave
            </Button>
          </div>
          <UserLeaveRequests refreshTable={refreshTable} />
          <ApplyLeaves
            open={formOpen}
            handleClose={handleClose}
            setRefreshTable={setRefreshTable}
          />
        </Paper>
      </Grid>
    </Grid>
  )
}
