import React, { useEffect, useState } from 'react'
import { Paper, Typography, Button } from '@mui/material'
import { fetchLeaveCount } from '../../service/leaveService'
import { useAuthUser } from 'react-auth-kit'
import { PieChart } from '@mui/x-charts/PieChart'
import {
  getAllAttendanceStats,
  getAttendanceStatsOfSingleEmployee
} from '../../service/reportsService'

import dayjs from 'dayjs'
export default function AttendanceLoggedComponent () {
  const [onTime, setOnTime] = useState(0)
  const [late, setLate] = useState(0)
  const [notMarked, setNotMarked] = useState(0)
  const [chartData, setChartData] = useState([])
  const today = dayjs(new Date()).format('YYYY-MM-DD')
  const thisYear = new Date()
  const firstDayOfYear = dayjs(
    new Date(thisYear.getFullYear(), thisYear.getMonth() - 3, 1)
  ).format('YYYY-MM-DD')
  const user = useAuthUser()
  async function fetchData () {
    try {
      const response = await getAttendanceStatsOfSingleEmployee({
        startDate: firstDayOfYear,
        endDate: today,
        empID: user().employeeID
      })
      const data = await response.json()
      setOnTime(data.OnTime)
      setLate(data.Late)
      setNotMarked(data.NotMarked)
    } catch (error) {
      console.error(error)
    }
  }

  useEffect(() => {
    fetchData()
  }, [])

  const chartDataOptions = [
    {
      id: 1,
      value: late,
      label: `Late`
    },
    {
      id: 2,
      value: notMarked,
      label: `Not Marked`
    },
    {
      id: 3,
      value: onTime,
      label: `On Time`
    }
  ]


  return (
    <Paper
      elevation={2}
      style={{
        border: '0.5rem solid #e5f4fc',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        width: 'inherit',
        height: 'inherit'
      }}
    >
      <Typography variant='h4'>Attendance Stats</Typography>
      <PieChart
        series={[
          {
            data: chartDataOptions,
            innerRadius: 60,
            outerRadius: 150,
            paddingAngle: 0,
            cornerRadius: 4
          }
        ]}
        width={340}
        height={400}
        margin={{ right: 5 }}
        slotProps={{
          legend: {
            direction: 'row',
            position: { vertical: 'bottom', horizontal: 'middle' },
            padding: 0
          }
        }}
      />
    </Paper>
  )
}
