import { addAttendance, fetchAttendance } from '../../service/attendanceService'
import { createApi } from '@reduxjs/toolkit/query/react'
import { fetchBaseQuery } from '@reduxjs/toolkit/query'
import { REHYDRATE } from 'redux-persist'
import { fetchLeave } from '../../service/leaveService'

const initialState = { entities: [], status: 'idle', error: null }
export const attendanceAndLeaveAPIs = createApi({
  baseQuery: fetchBaseQuery({ url: '/' }),
  reducerPath: 'attendanceAndLeaveAPI',
  tagTypes: ['attendance', 'leaves'],
  endpoints: build => ({
    getAttendanceData: build.query({
      queryFn: async ({ employeeID, auth }) => {
        try {
          const att = await fetchAttendance(employeeID, auth)
          if (att.ok) return { data: att.json() }
          return { data: [] }
        } catch (error) {
          return { error }
        }
      },
      providesTags: result =>
        result
          ? [
              ...result.map(({ id }) => ({ type: 'Attendance', id })),
              { type: 'Attendance', id: 'ATT' }
            ]
          : [{ type: 'Attendance', id: 'ATT' }]
    }),
    addAttendance: build.mutation({
      queryFn: async ({ updateFunction, attendanceData }) => {
        try {
          const att = await updateFunction(attendanceData)
          if (att.ok) return { data: att.text() }
          return { data: 'Error' }
        } catch (error) {
          return { error }
        }
      },
      invalidatesTags: [{ type: 'Attendance', id: 'ATT' }]
    }),
    keepUnusedDataFor: 600,
    getLeaveData: build.query({
      queryFn: async ({ employeeID }) => {
        try {
          const leaves = await fetchLeave(employeeID)
          if (leaves.ok) return { data: leaves.json() }
          return { data: [] }
        } catch (error) {
          return { error }
        }
      },
      providesTags: result =>
        result
          ? [
              ...result.map(({ id }) => ({ type: 'Leave', id })),
              { type: 'Leave', id: 'LEAVE' }
            ]
          : [{ type: 'Leave', id: 'LEAVE' }]
    }),
    addLeave: build.mutation({
      queryFn: async ({ updateFunction, leaveDetails }) => {
        try {
          const leave = await updateFunction(leaveDetails)
          if (leave.ok) return { data: leave.text() }
          return { data: 'Error' }
        } catch (error) {
          return { error }
        }
      },
      invalidatesTags: [
        { type: 'Attendance', id: 'ATT' },
        { type: 'Leave', id: 'LEAVE' }
      ]
    }),
    updateLeave: build.mutation({
      queryFn: async ({ updateFunction, leaveData }) => {
        try {
          const leaves = await updateFunction(
            leaveData.itemName,
            leaveData.item
          )
          if (leaves.ok) return { data: leaves.text() }
          return { data: 'Error' }
        } catch (error) {
          return { error }
        }
      },
      invalidatesTags: [
        { type: 'Attendance', id: 'ATT' },
        { type: 'Leave', id: 'LEAVE' }
      ]
    }),
    keepUnusedDataFor: 3600
  })
})
export const {
  useGetAttendanceDataQuery,
  useAddAttendanceMutation,
  useGetLeaveDataQuery,
  useUpdateLeaveMutation,
  useAddLeaveMutation
} = attendanceAndLeaveAPIs
