import React, { useState, useEffect } from 'react'
import { useAuthUser, useIsAuthenticated } from 'react-auth-kit'
import { DataGrid, useGridApiRef } from '@mui/x-data-grid'
import { DatePicker } from '@mui/x-date-pickers'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import 'dayjs/locale/en-in'
import dayjs from 'dayjs'
import TableComponent from '../../TableComponents'
import { TextField } from '@mui/material'
import { confirmAlert } from 'react-confirm-alert'
import {
  getAllLeaveOverridables,
  overrideLeave
} from '../../../service/overrideService'
export default function LeaveOverrideTable ({
  startDate,
  endDate,
  department,
  team,
  employee_id,
  shift
}) {
  const isAuthenticated = useIsAuthenticated()
  const user = useAuthUser()
  const [progress, setProgress] = useState(false)
  const [items, setItems] = useState([])
  const apiRef = useGridApiRef()
  const itemName = 'leave'
  const itemID = 'employeeLeaveID'
  const [refreshTable, setRefreshTable] = useState(false)

  const handleDateMismatch = () => {
    confirmAlert({
      title: 'Improper dates-Set dates properly before confirming edit',
      buttons: [
        {
          label: 'OK'
        }
      ]
    })
  }

  async function fetchData () {
    try {
      setProgress(true)
      const res = await getAllLeaveOverridables({
        startDate: dayjs(startDate).format('YYYY-MM-DD'),
        endDate: dayjs(endDate).format('YYYY-MM-DD'),
        team: team,
        department: department,
        empID: employee_id,
        shift: shift
      })
      const data = await res.json()
      const newRows = data.map(item => ({
        employeeLeaveID: item.leave.employeeLeaveID,
        approved: item.leave.approved,
        comments: item.leave.comments,
        leaveStartDate: item.leave.leaveStartDate,
        leaveEndDate: item.leave.leaveEndDate,
        halfDayDates: item.leave.halfDayDates,
        hoursOff: item.leave.hoursOff,
        daysOff: item.leave.daysOff,
        employeeIDf: item.leave.employeeIDf,
        managerRemark: item.leave.managerRemark,
        leaveType: item.leave.leaveType,
        leaveReason: item.leave.leaveReason,
        name: item.name,
        lname: item.lname,
        team: item.team,
        department: item.department,
        shift: item.shift,
        office: item.officeLocation,
        requestDate: item.leave.requestDate,
        approvedBy: item.leave.approvedBy,
        approvedDate: item.leave.approvedDate
      }))
      setItems(newRows)
    } catch (error) {
      console.error(error)
    } finally {
      setProgress(false)
    }
  }

  const column = [
    {
      field: 'leaveStartDate',
      headerName: 'Start Date',
      valueGetter: value =>
        value !== null
          ? dayjs(dayjs(value).tz('Asia/Kolkata').toDate()).format('DD/MM/YYYY')
          : null,
      renderEditCell: params => (
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DatePicker
            format='DD/MM/YYYY'
            value={dayjs(params.value)}
            onChange={async event => {
              if (
                dayjs(event).isAfter(
                  dayjs(apiRef.current.getCellValue(params.id, 'leaveEndDate')),
                  'day'
                )
              )
                handleDateMismatch()
              await apiRef.current.setEditCellValue({
                id: params.id,
                field: params.field,
                value: dayjs(event).tz('Asia/Kolkata').toDate()
              })
            }}
          />
        </LocalizationProvider>
      ),
      width: 150,
      editable: true
    },
    {
      field: 'leaveEndDate',
      headerName: 'End Date',
      valueGetter: value =>
        value !== null
          ? dayjs(dayjs(value).tz('Asia/Kolkata').toDate()).format('DD/MM/YYYY')
          : null,
      renderEditCell: params => (
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DatePicker
            value={dayjs(params.value)}
            format='DD/MM/YYYY'
            onChange={async event => {
              if (
                dayjs(event).isBefore(
                  dayjs(
                    apiRef.current.getCellValue(params.id, 'leaveStartDate')
                  ),
                  'day'
                )
              )
                handleDateMismatch()
              console.log('Date changed to:', dayjs(event))
              await apiRef.current.setEditCellValue({
                id: params.id,
                field: params.field,
                value: dayjs(event).tz('Asia/Kolkata').toDate()
              })
            }}
          />
        </LocalizationProvider>
      ),
      width: 150,
      editable: true
    },
    {
      field: 'approved',
      headerName: 'Status',
      valueGetter: value =>
        value == null ? 'Pending' : value == 0 ? 'Denied' : 'Approved',
      width: '150'
    },
    {
      field: 'name',
      headerName: 'First Name',
      width: '150'
    },
    {
      field: 'lname',
      headerName: 'Last Name',
      width: '150'
    },
    {
      field: 'employeeIDf',
      headerName: 'Employee ID',
      width: '150'
    },
    {
      field: 'department',
      headerName: 'Department',
      width: '150'
    },
    {
      field: 'team',
      headerName: 'Team',
      width: '150'
    },
    {
      field: 'shift',
      headerName: 'Shift',
      width: '150'
    },
    {
      field: 'office',
      headerName: 'Office Location',
      width: '150'
    },
    {
      field: 'requestDate',
      type: 'date',
      headerName: 'Requested On',
      valueGetter: value =>
        value !== null ? dayjs(value).tz('Asia/Kolkata').toDate() : null,
      width: '150'
    },
    {
      field: 'halfDayDates',
      headerName: 'Half Day',
      type: 'date',
      width: '150',
      valueGetter: value =>
        value !== null ? dayjs(value).tz('Asia/Kolkata').toDate() : null,
      editable: true
    },
    {
      field: 'daysOff',
      headerName: 'Days',
      width: 100,
      editable: true,
      type: 'number',
      renderEditCell: params => (
        <TextField
          type='number'
          value={params.value}
          onChange={async e =>
            await apiRef.current.setEditCellValue({
              id: params.id,
              field: params.field,
              value: e.target.value
            })
          }
          InputProps={{
            inputProps: {
              max: 1000,
              step: 0.5,
              onKeyDown: event => {
                event.preventDefault()
              }
            }
          }}
        />
      )
    },
    {
      field: 'hoursOff',
      headerName: 'Hours',
      width: 50,
      editable: false
    },
    {
      field: 'comments',
      headerName: 'Comments',
      width: '150'
    },
    {
      field: 'managerRemark',
      headerName: 'Manager Remarks',
      width: '150',
      editable: true,
      editable: true
    },
    {
      field: 'approvedBy',
      headerName: 'Approved By',
      width: '150'
    },
    {
      field: 'approvedDate',
      headerName: 'Approved Date',
      valueGetter: value =>
        value !== null
          ? dayjs(value).tz('Asia/Kolkata').format('DD/MM/YYYY')
          : null,
      width: '150'
    }
  ]

  useEffect(() => {
    setTimeout(() => {
      fetchData().then(() => setRefreshTable(false))
    }, 100)
  }, [refreshTable, startDate, endDate, team, shift, employee_id, department])

  return (
    <TableComponent
      refreshTable={refreshTable}
      apiRef={apiRef}
      columns={column}
      itemName={itemName}
      itemID={itemID}
      loading={progress}
      customRows={items}
      setRefreshTable={setRefreshTable}
      customEdit={overrideLeave}
    />
  )
}
