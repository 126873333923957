import Cookies from 'js-cookie';
import { BASE_URL } from "./apiService";


export const addHoliday = async holidayData => {
  try {
    const authToken = Cookies.get('_auth')
    const response = await fetch(BASE_URL + '/calender/add/holiday', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${authToken}`
      },
      body: JSON.stringify(holidayData)
    })

    return response
  } catch (error) {
    console.error(error)
    throw error
  }
}

export const fetchHolidaysForemployee = async (employeeID,auth) => {
  try {
    //const authToken = 
    const response = await fetch(
      BASE_URL +
        '/calender/getHolidaysFor/'+employeeID,
      {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: auth?auth:`Bearer ${Cookies.get('_auth')}`
        }
      }
    )
    return response
  } catch (error) {
    console.error(error)
    throw error
  }
}


export const fetchWorkdaysBetween = async (startDate, endDate,employeeID) => {
  try {
    const authToken = Cookies.get('_auth')
    const response = await fetch(
      BASE_URL +
        '/calender/getWorkdaysBetween?startDate=' +
        startDate +
        '&endDate=' +
        endDate+
        '&employeeID='+
        employeeID,
      {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${authToken}`
        }
      }
    )
    return response
  } catch (error) {
    console.error(error)
    throw error
  }
}

