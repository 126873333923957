import React, { useState, useEffect } from 'react'
import { DataGrid, useGridApiRef } from '@mui/x-data-grid'
import { Typography, Button, IconButton } from '@mui/material'
import { PhoneAndroid, PhotoCamera } from '@mui/icons-material'
import dayjs from 'dayjs'
import { useAlert } from 'react-alert'
import { renderDeviceImageViewer } from '../Admin/AdminCreateForms/renderDeviceImageViewer'
import { dataGridStyles } from '../../stylings/datagridStyles'
import CustomGridToolbarNoAdd from '../Admin/CustomGridToolbarNoAdd'
import {
  fetchDeviceAssetsTransferForemployee,
  transferDeviceFromReceiver
} from '../../service/deviceAssetService'
import { useAuthUser } from 'react-auth-kit'

export default function DeviceAssetTransferTable ({ setMainRefreshTable,transferTableRefresh }) {
  const apiRef = useGridApiRef()
  const itemName = 'deviceassettransfer'
  const itemID = 'trackerID'
  const user = useAuthUser()
  const [refreshTable, setRefreshTable] = useState(false)
  const [items, setItems] = useState([])
  const [progress, setProgress] = useState(false)
  const [openTransferForm, setOpenTransferForm] = useState(false)
  const [row, setRow] = useState([])
  const [images, setImages] = useState([])

  const alert = useAlert()
  const deleteDisable = row => row != null
  const handleRefreshTable = newState => {
    setRefreshTable(newState)
  }

  async function fetchData () {
    try {
      setProgress(true)
      const res = await fetchDeviceAssetsTransferForemployee(user().employeeID)
      const data = await res.json()
      setItems(data)
    } catch (error) {
      console.error(error)
      setProgress(false)
    } finally {
      setProgress(false)
    }
  }

  const handleTransfer = async (row, status) => {
    try {
      const data = await transferDeviceFromReceiver(row, status)
      const res = await data.text()
      alert.show(res)
    } catch {
      alert.error('Error in transfer')
    } finally {
      setRefreshTable(true)
    }
  }

  useEffect(() => {
    setRefreshTable(false)
    setMainRefreshTable(true)
    fetchData()
  }, [refreshTable,transferTableRefresh])

  const renderActionsCell = params => {
    const { row } = params
    return row.status == 0 ? (
      <div className='div-spaceToSides'>
        <Button
          variant='contained'
          className='button-gradient'
          sx={{ margin: '1%' }}
          onClick={async () => {
            await handleTransfer(row, 1)
          }}
        >
          Accept
        </Button>
        <Button
          variant='outlined'
          color='warning'
          sx={{ margin: '1%' }}
          onClick={async () => {
            await handleTransfer(row, -3)
          }}
        >
          Deny
        </Button>
        <Button
          variant='outlined'
          color='error'
          sx={{ margin: '1%' }}
          onClick={async () => {
            await handleTransfer(row, -1)
          }}
        >
          Review
        </Button>
      </div>
    ) : null
  }

  const columns = [
    {
      field: 'deviceAssetID',
      headerName: 'Device Asset ID',
      width: 250
    },

    {
      field: 'status',
      headerName: 'Status',
      width: 250,
      renderCell: ({ value }) =>
        value == 0
          ? 'Transfer Initiated'
          : value == -1
          ? 'Sent Back For Review'
          : value == -2
          ? 'Repair'
          : value == -3
          ? 'Denied'
          : value == -4
          ? 'Surrendered'
          : null
    },
    {
      field: 'transferDate',
      headerName: 'Date of Transfer',
      type:'dateTime',
       valueGetter: (value) =>
        value !== null
          ?  dayjs(value).tz('Asia/Kolkata').toDate()
          : null,
      width: 250
    },
    {
      field: 'transferrer',
      headerName: 'Sender',
      width: 250
    },
    {
      field: 'transferee',
      headerName: 'Receiver',
      width: 250
    },
    {
      field: 'comments',
      headerName: 'Comments',
      width: 250
    },

    {
      field: 'newDamages',
      headerName: 'New Damages',
      width: 400,
      editable: false,
      renderCell: ({ value }) =>
        value
          ?.map((damage, index) => {
            let key = Object.keys(damage)[0]
            let val = Object.values(damage)[0]
            return `${key}: ${val}`
          })
          .join(', ')
    },
    {
      field: 'damagesOfDevice',
      headerName: 'Known Damages',
      width: 300,
      renderCell: ({ value }) =>
        value.map(param => param.damage + ':' + param.damageCount).join(', ')
    },
    {
      field: 'deviceImages',
      headerName: 'Images',
      width: 90,
      renderCell: params => (
        <IconButton
          size='small'
          onClick={event => {
            handleViewerClick(event, params.value)
          }}
        >
          <PhotoCamera />
        </IconButton>
      )
    },
    {
      field: 'actions',
      headerName: 'Actions',
      sortable: false,
      filterable: false,
      width: 400,
      renderCell: renderActionsCell
    }
  ]
  const [anchorEl, setAnchorEl] = useState(null)
  const [imageEditorEl, setImageEditorEl] = useState(null)

  const handleViewerClick = (event, images) => {
    setImageEditorEl(null)
    setAnchorEl(anchorEl ? null : event.currentTarget)
    setImages(images)
  }

  const renderImageViewer = renderDeviceImageViewer(
    anchorEl,
    setAnchorEl,
    images
  )
  return (
    <>
      <div>
        <div className='div-spaceToSides'>
          <Typography variant='h4'>
            <PhoneAndroid />
            Transfer Requests
          </Typography>
        </div>
        <div className='div-spaceToSides'>
          <Typography variant='caption'>
            Note: Accepting the device will link the device listed
            to your account. Denying or sending the device back for review will
            send it back to the previous employee who has initiated this
            transfer. However, if no action is taken within 8 hours, the device
            will be automatiacally transferred to you
          </Typography>
        </div>
        <DataGrid
          apiRef={apiRef}
          columns={columns}
          rows={items}
          getRowId={row => row[itemID]}
          sx={dataGridStyles}
          loading={progress}
          showCellVerticalBorder
          autoHeight
          autoSizeOnMount={true}
          initialState={{
            pagination: {
              paginationModel: {
                page: 0,
                pageSize: 5
              }
            },
            sorting: {
              sortModel: [{ field: 'transferDate', sort: 'desc' }]
            }
          }}
          pageSizeOptions={[5, 10, 15, 20, 100]}
          slots={{
            toolbar: CustomGridToolbarNoAdd
          }}
        />
        {renderImageViewer()}
      </div>
    </>
  )
}
