import React from 'react';
import {
  Typography, IconButton,
  ImageList,
  ImageListItem,
  Dialog,
  DialogTitle
} from '@mui/material';
import { CancelOutlined } from '@mui/icons-material';

export function renderDeviceImageViewer(anchorEl, setAnchorEl, images) {
  return () => (
    <Dialog open={Boolean(anchorEl)} keepMounted={false}>
      <DialogTitle>
        View Device Images
        <IconButton onClick={() => setAnchorEl(null)} sx={{ float: 'right' }}>
          <CancelOutlined />
        </IconButton>
      </DialogTitle>
      {images.length > 0 ? (
        <div>
          <ImageList
            sx={{ width: 500, height: 300, padding: '2%' }}
            cols={2}
            rowHeight={100}
            gap={10}
          >
            {images?.map(img => {
              return (
                <ImageListItem key={img}>
                  <img
                    crossOrigin='anonymous'
                    alt='Phone Image'
                    src={`data:image/jpeg;base64,${img}`}
                    height={150}
                    width={150}
                    style={{ border: '1px solid rgba(0,0,0,0.4)' }} />
                </ImageListItem>
              );
            })}
          </ImageList>
        </div>
      ) : (
        <Typography>No images</Typography>
      )}
    </Dialog>
  );
}
