/*For use of BeQiSoft Pvt Ltd. */

import React, { useState, useEffect } from 'react'
import TextField from '@mui/material/TextField'
import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  FormControl,
  InputLabel
} from '@mui/material'
import AddIcon from '@mui/icons-material/Add'
import {
  CalendarViewDay,
  CancelOutlined,
  WarningAmberOutlined
} from '@mui/icons-material'
import { DatePicker, DateTimePicker } from '@mui/x-date-pickers'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import dayjs from 'dayjs'
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore'
import { utc } from 'dayjs'
import timezone from 'dayjs/plugin/timezone'
import { addItem } from '../../../service/InterfaceFunctions/addItem'
dayjs.extend(timezone)
dayjs.extend(utc)
dayjs.extend(isSameOrBefore)
export default function CreateAlertMessageForm ({
  open,
  handleClose,
  setRefreshTable
}) {
  const [message, setMessage] = useState(null)
  const [alertStartDate, setAlertStartDate] = useState(null)
  const [alertEndDate, setAlertEndDate] = useState(null)
  const [mandatoryIsEmpty, setMandatoryIsEmpty] = useState(false)

  const resetState = () => {
    setMandatoryIsEmpty(false)
    setMessage(null)
    setAlertStartDate(null)
    setAlertEndDate(null)
  }
  useEffect(() => {
    if (open) {
      resetState()
    }
  }, [open])
  const handleFormClose = () => {
    setMessage('')
    setAlertStartDate(null)
    setAlertEndDate(null)
    handleClose()
  }
  const handleSubmit = async e => {
    e.preventDefault()
    const alertData = {
      alertStartDate,
      alertEndDate,
      message
    }
    if (
      message != null &&
      alertStartDate != null &&
      alertEndDate != null &&
      dayjs(alertStartDate).isSameOrBefore(alertEndDate) &&
      message.trim() != ''
    ) {
      try {
        setMandatoryIsEmpty(false)
        console.log('Alert Creating')
        addItem('alertmessage', alertData)
      } catch (error) {
        console.log(error)
      } finally {
        setTimeout(() => {
          setRefreshTable(true)
          handleFormClose()
        }, 1000)
      }
    } else {
      console.log('creation Error')
      setMandatoryIsEmpty(true)
    }
  }

  return (
    <Dialog open={open}>
      <DialogTitle>
        <WarningAmberOutlined /> Add Alert
        <IconButton onClick={handleFormClose} sx={{ float: 'right' }}>
          <CancelOutlined />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <form noValidate>
          <FormControl
            required
            fullWidth
            style={{ margin: '20px auto' }}
            error={mandatoryIsEmpty && !message}
          >
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DateTimePicker
                format='DD/MM/YYYY HH:mm:ss'
                label='Alert Start Date *'
                value={dayjs(alertStartDate)}
                maxDate={alertEndDate}
                onChange={newVal => {
                  setAlertStartDate(dayjs(newVal))
                }}
                slotProps={{
                  textField: {
                    error: mandatoryIsEmpty && !alertStartDate
                  }
                }}
              />
            </LocalizationProvider>
          </FormControl>
          <FormControl
            required
            fullWidth
            style={{ margin: '20px auto' }}
            error={mandatoryIsEmpty && !message}
          >
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DateTimePicker
                minDate={alertStartDate}
                format='DD/MM/YYYY HH:mm:ss'
                label='Alert End Date *'
                value={dayjs(alertEndDate)}
                onChange={newVal => {
                  setAlertEndDate(dayjs(newVal))
                }}
                slotProps={{
                  textField: {
                    error: mandatoryIsEmpty && !alertEndDate
                  }
                }}
              />
            </LocalizationProvider>
          </FormControl>
          <TextField
            required
            id='occassion-id'
            label='Message'
            variant='outlined'
            fullWidth
            multiline
            value={message}
            onChange={e => setMessage(e.target.value)}
            error={mandatoryIsEmpty && !message}
            style={{ margin: '20px auto' }}
          />

          <Button
            variant='contained'
            className='button-gradient'
            onClick={handleSubmit}
          >
            <AddIcon />
            Add Alert
          </Button>
        </form>
      </DialogContent>
    </Dialog>
  )
}
