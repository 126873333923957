import React, { useEffect, useState } from 'react'
import { CircularProgress, Backdrop } from '@mui/material'
import { useAuthUser, useIsAuthenticated } from 'react-auth-kit'
import { fetchLeaveRequestsHistoryForManager } from '../../service/leaveService'
import { DataGrid, useGridApiRef } from '@mui/x-data-grid'
import CustomGridToolbarNoAdd from '../Admin/CustomGridToolbarNoAdd'
import dayjs from 'dayjs'
import { dataGridStyles } from '../../stylings/datagridStyles'

export default function LeaveRequestsHistory () {
  const [LeaveRequests, setLeaveRequest] = useState([])
  const isAuthenticated = useIsAuthenticated()
  const user = useAuthUser()
  const apiRef = useGridApiRef()
  const [progress, setProgress] = useState(false)
  async function fetchData () {
    setProgress(true)
    try {
      const response = await fetchLeaveRequestsHistoryForManager(
        user().team,
        user().employeeID
      )
      const data = await response.json()
      const newRows = data.map(item => ({
        employeeLeaveID: item.employeeLeave.employeeLeaveID,
        approved: item.employeeLeave.approved,
        comments: item.employeeLeave.comments,
        reason: item.employeeLeave.reason,
        days: item.employeeLeave.daysOff,
        leaveStartDate: item.employeeLeave.leaveStartDate,
        leaveEndDate: item.employeeLeave.leaveEndDate,
        employeeIDf: item.employeeLeave.employeeIDf,
        managerRemark: item.employeeLeave.managerRemark,
        leaveType: item.employeeLeave.leaveType,
        name: item.name,
        lname: item.lname,
        requestDate: item.employeeLeave.requestDate,
        approvedDate: item.employeeLeave.approvedDate,
        approvedBy: item.employeeLeave.approvedBy

      }))
      setLeaveRequest(newRows)
    } catch (error) {
      console.error(error)
    }
    finally{
      setProgress(false)
    }
  }

  useEffect(() => {
    if (user() && isAuthenticated()) fetchData()
  }, [user(), isAuthenticated()])

  const columns = [
    {
      field: 'approved',
      headerName: 'Status',
      width: 150,
       valueGetter: (value) => (value ? 'Approved' : 'Denied')
    },
    {
      field: 'employeeIDf',
      headerName: 'Employee ID',
      width: 150
    },
    {
      field: 'name',
      headerName: 'First Name',
      width: 150
    },
    {
      field: 'lname',
      headerName: 'Last Name',
      width: 150
    },
    {
      field: 'leaveStartDate',
      headerName: 'Start Date',
      type: 'date',
       valueGetter: (value) =>
        value !== null ? dayjs(value).toDate() : null,
      width: 150
    },
    {
      field: 'leaveEndDate',
      headerName: 'End Date',
      type: 'date',
       valueGetter: (value) =>
        value !== null ? dayjs(value).toDate() : null,
      width: 150
    },
    {
      field: 'leaveType',
      headerName: 'Type',
      width: 100
    },
    {
      field: 'days',
      headerName: 'Days Off',
      width: 100
    },
    {
      field: 'reason',
      headerName: 'Leave Reason',
      width: 300
    },
    {
      field: 'requestDate',
      headerName: 'Request Sent On',
      type: 'date',
       valueGetter: (value) =>
        value !== null ? dayjs(value).toDate() : null,
      width: 150
    },
    {
      field: 'approvedDate',
      headerName: 'Approved On',
      type: 'date',
       valueGetter: (value) =>
        value !== null ? dayjs(value).toDate() : null,
      width: 150
    },
    {
      field: 'approvedBy',
      headerName: 'Approval By',
      width: 300
    },
    {
      field: 'comments',
      headerName: 'Comments',
      width: 200,
      sortable: false,
      filterable: false
    },
    {
      field: 'managerRemark',
      headerName: 'Remarks',
      sortable: false,
      filterable: false,
      width: 200
    }
  ]


  return (

        <DataGrid
          disableRowSelectionOnClick
          showCellVerticalBorder
          rows={LeaveRequests}
          columns={columns}
          getRowId={row => row.employeeLeaveID}
          apiRef={apiRef}
          autoHeight
          sx={dataGridStyles}
          slots={{
            toolbar: CustomGridToolbarNoAdd
          }}
          initialState={{
            sorting: {
              sortModel: [{ field: 'attendanceDate', sort: 'asc' }]
            },
            pagination: {
              paginationModel: {
                page: 0,
                pageSize: 5
              }
            }
          }}
          pageSizeOptions={[5, 10, 15, 20, 100]}
        />)

}
