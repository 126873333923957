import { Grid } from '@mui/material'
import LeavesTakenComponent from '../components/Dashboard/LeavesTakenComponent'
import AttendanceLoggedComponent from '../components/Dashboard/AttendanceLoggedComponent'
export default function DashBoardPage () {
  return (
    <Grid
      container
      style={{
        width: '90vw',
        height: 'min-content',
        margin: '0 auto auto auto',
        display: 'flex',
        alignItems: 'flex-start'
      }}
      spacing={1}
    >
      <Grid item sm={12} md={6}>
        <LeavesTakenComponent />
      </Grid>
      <Grid item sm={12} md={6}>
        <AttendanceLoggedComponent />
      </Grid>
    </Grid>
  )
}
