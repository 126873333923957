import React, { useState, useEffect } from 'react'
import { useGridApiRef } from '@mui/x-data-grid'
import { Typography, Button } from '@mui/material'
import TableComponent from '../../TableComponents'
import { EventBusyOutlined } from '@mui/icons-material'
import CreateLeaveTypeForm from '../AdminCreateForms/CreateLeaveTypeForm'
import CreateLeaveReasonForm from '../AdminCreateForms/CreateLeaveReasonForm'

export default function LeaveReasonTable () {
  const apiRef = useGridApiRef()

  const [formOpen, setFormOpen] = useState(false)

  const [refreshTable, setRefreshTable] = useState(false)

  const handleRefreshTable = newState => {
    setRefreshTable(newState)
  }

  const handleClose = () => {
    setFormOpen(false)
  }

  useEffect(() => {
    setRefreshTable(false)
  }, [refreshTable])

  const defaultColumns = [
    {
      field: 'leaveReason',
      headerName: 'Leave Reason',
      flex: 1,
      editable: true
    }
    // {
    //   field: 'leaveColour',
    //   headerName: 'Leave Colour',
    //   flex: 1,
    //   editable: true
    // }
  ]

  return (
    <>
      <div>
        <div className='div-spaceToSides'>
          <Typography variant='h4'>
            <EventBusyOutlined />
            Leave Reasons
          </Typography>

          <Button
            variant='filled'
            className='button-gradient'
            onClick={() => {
              setFormOpen(true)
            }}
          >
            +Add new reason
          </Button>
        </div>
        <TableComponent
          refreshTable={refreshTable}
          apiRef={apiRef}
          itemID={'leaveReasonID'}
          itemName={'leavereasons'}
          columns={defaultColumns}
        />
        <CreateLeaveReasonForm
          open={formOpen}
          handleClose={handleClose}
          setRefreshTable={setRefreshTable}
        />
      </div>
    </>
  )
}
