import React, { useState, useEffect } from 'react'
import {
  Dialog,
  DialogContent,
  DialogTitle,
  Button,
  IconButton,
  Select,
  MenuItem,
  Checkbox,
  Backdrop,
  CircularProgress,
  FormControl,
  FormControlLabel,
  RadioGroup,
  Radio,
  InputLabel,
  TextField
} from '@mui/material'
import {
  addAttendance as addDailyAttendance,
  addAttendaceRequest,
  addHolidayAttendaceRequest
} from '../../service/attendanceService'
import { useAuthUser, useAuthHeader } from 'react-auth-kit'
import dayjs from 'dayjs'
import { CancelOutlined } from '@mui/icons-material'
import { useAlert } from 'react-alert'
import { useNavigate } from 'react-router-dom'
import { useAddAttendanceMutation } from '../../reduxstore/features/attendanceRTKFile'
export default function Consolidatesialog ({
  dialogType,
  date,
  open,
  handleClose,
  handleRefreshTable
}) {
  const [latitude, setLatitude] = useState(0)
  const [longitude, setLongitude] = useState(0)
  const [shiftType, setShiftType] = useState('FULLDAY')
  const [dayHalf, setDayHalf] = useState('FIRSTHALF')
  const [comments, setComments] = useState(null)
  const [addAttendance, { data, isLoading, isError, isSuccess }] =
    useAddAttendanceMutation()
  const user = useAuthUser()
  const authHeader = useAuthHeader()
  const auth = authHeader()
  const [projectTitles, setProjectTitles] = useState(
    user().projects.length
      ? user().projects.sort(
          (p1, p2) =>
            p1.projectName.toUpperCase() > p2.projectName.toUpperCase()
        )
      : []
  )
  const [workedOn, setWorkedOn] = useState(
    user().projects.length == 1 ? user().projects[0].projectName : []
  )
  const [progress, setProgress] = useState(false)
  const [mandatoryIsEmpty, setMandatorIsEmpty] = useState(false)
  const alert = useAlert()
  const navigate = useNavigate()

  const getLocation = async (attempt = 1) => {
    setProgress(true)
    if (latitude != 0 && longitude != 0) return true
    await navigator.permissions.query({ name: 'geolocation' }).then(result => {
      navigator.geolocation.getCurrentPosition(
        position => {
          setLatitude(position.coords.latitude)
          setLongitude(position.coords.longitude)
          setProgress(false)
          return true
        },
        error => {
          console.error('Error getting geolocation:', error)
          setProgress(false)
          if (attempt < 3) {
            getLocation(attempt + 1)
          } else {
            alert.show(
              'Error getting location,please enable location and refresh'
            )
            return false
          }
        },
        {
          enableHighAccuracy: true,
          maximumAge: 60000,
          timeout: 6000
        }
      )
    })
  }
  const resetState = () => {
    setComments(null)
    setWorkedOn([])
    setShiftType('FULLDAY')
    setDayHalf('FIRSTHALF')
    setMandatorIsEmpty(false)
  }

  useEffect(() => {
    if (latitude === 0 && longitude === 0) {
      getLocation()
    }
  }, [])

  const handleMarkApiCall = async () => {
    const authEmployeeID = user().employeeID
    const attendanceDetails = {
      date: dayjs(date).toDate(),
      time: date.toTimeString().split(' ')[0],
      latitude: latitude,
      longitude: longitude,
      employee_id: authEmployeeID,
      shiftType: shiftType,
      leave: false,
      comments: comments
    }
    if (attendanceDetails.shiftType == 'HALFDAY')
      attendanceDetails.dayHalf = dayHalf
    if (user().projects.length === 1) {
      attendanceDetails.workedOn = projectTitles[0].projectName
      setWorkedOn(projectTitles[0].projectName)
    } else attendanceDetails.workedOn = workedOn.join(',')
    if (
      dialogType === 'today' &&
      workedOn.length != 0 &&
      latitude != null &&
      longitude != null &&
      latitude !== 0.0 &&
      longitude !== 0.0
    ) {
      setProgress(true)
      setMandatorIsEmpty(false)
      try {
        await addAttendance({
          updateFunction: addDailyAttendance,
          attendanceData: attendanceDetails
        })
          .unwrap()
          .then(payload => alert.show(payload))
        return data
      } catch (error) {
        console.error(error)
        alert.show('Ensure all details,including geolocation are correct')
      } finally {
        setTimeout(() => {
          handleCloseDialog()
          handleRefreshTable(true)
          setProgress(false)
        }, 5000)
      }
    } else if (
      dialogType === 'missed' &&
      comments != null &&
      comments.trim() != '' &&
      workedOn.length != 0 &&
      latitude &&
      longitude &&
      latitude !== 0 &&
      longitude !== 0
    ) {
      setMandatorIsEmpty(false)
      setProgress(true)
      attendanceDetails.approved = false
      attendanceDetails.requestType = 'PastDay'
      try {
        await addAttendance({
          updateFunction: addAttendaceRequest,
          attendanceData: attendanceDetails
        })
          .unwrap()
          .then(payload => alert.show(payload))
        return data
      } catch (error) {
        console.error(error)
      } finally {
        handleCloseDialog()
        setTimeout(() => {
          handleCloseDialog()
          handleRefreshTable(true)
          setProgress(false)
        }, 5000)
      }
    } else if (
      dialogType === 'holiday' &&
      comments != null &&
      comments.trim() != '' &&
      workedOn.length != 0 &&
      latitude != null &&
      longitude != null &&
      latitude !== 0 &&
      longitude !== 0
    ) {
      setMandatorIsEmpty(false)
      setProgress(true)
      attendanceDetails.approved = false
      attendanceDetails.comments = comments
      attendanceDetails.requestType = 'Holiday'

      try {
        await addAttendance({
          updateFunction: addHolidayAttendaceRequest,
          attendanceData: attendanceDetails
        })
          .unwrap()
          .then(payload => alert.show(payload))
        return data
      } catch (error) {
        console.error(error)
      } finally {
        setTimeout(() => {
          handleCloseDialog()
          handleRefreshTable(true)
          setProgress(false)
        }, 5000)
      }
    } else {
      alert.error(
        'Please ensure that all details are correct, including geolocation'
      )
      console.log('Attendance data:', attendanceDetails)
      setMandatorIsEmpty(true)
    }
  }
  const handleMarkAttendance = async () => {
    if (latitude === 0.0 && longitude === 0.0) {
      await getLocation().then(async res => {
        if (res == true) {
          await handleMarkApiCall()
        } else {
          alert.error(
            'Please ensure that all details are correct, including geolocation'
          )
        }
      })
    } else handleMarkApiCall()
  }

  const handleChange = event => {
    const selectedOptions = event.target.value
    setWorkedOn(selectedOptions)
  }

  const handleCloseDialog = event => {
    resetState()
    handleClose()
  }

  return (
    <Dialog open={open} keepMounted={false}>
      <DialogTitle>
        {dialogType === 'today'
          ? 'Mark Attendance'
          : dialogType === 'missed'
          ? 'Request to approve missed Attendance'
          : 'Request to approve attendance on holiday'}
        <IconButton onClick={handleCloseDialog} sx={{ float: 'right' }}>
          <CancelOutlined />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        {dialogType === 'missed' && (
          <p>
            Request will be sent to manager. If approved, attendance will be
            marked as present, else it will be marked as leave.
          </p>
        )}
        {dialogType === 'holiday' && (
          <p>
            Request will be sent to manager. If approved, attendance will be
            marked as present
          </p>
        )}

        <form className='div-centerstyle' noValidate autoComplete='off'>
          <p>Date: {date ? dayjs(date).format('DD/MM/YYYY') : ''}</p>
          <FormControl required>
            <FormControlLabel
              label='Workday:'
              labelPlacement='start'
              control={
                <RadioGroup
                  row
                  aria-labelledby='demo-row-radio-buttons-group-label'
                  name='row-radio-buttons-group'
                  value={shiftType}
                  onChange={e => setShiftType(e.target.value)}
                >
                  <FormControlLabel
                    value='FULLDAY'
                    control={<Radio />}
                    label='Full Day'
                  />
                  <FormControlLabel
                    value='HALFDAY'
                    control={<Radio />}
                    label='Half Day'
                  />
                </RadioGroup>
              }
            />
          </FormControl>
          {shiftType == 'HALFDAY' ? (
            <FormControl required>
              <FormControlLabel
                label='Day Half:'
                labelPlacement='start'
                control={
                  <RadioGroup
                    row
                    value={dayHalf}
                    onChange={e => setDayHalf(e.target.value)}
                  >
                    <FormControlLabel
                      value='FIRSTHALF'
                      control={<Radio />}
                      label='First Half'
                    />
                    <FormControlLabel
                      value='SECONDHALF'
                      control={<Radio />}
                      label='Second Half'
                    />
                  </RadioGroup>
                }
              />
            </FormControl>
          ) : null}
          {user().projects.length === 1 ? (
            <TextField
              required
              fullWidth
              label='Project'
              value={projectTitles[0].projectName}
            />
          ) : (
            <FormControl
              required
              error={mandatoryIsEmpty && !workedOn.length}
              fullWidth
              style={{ margin: '20px auto' }}
            >
              <InputLabel id='project-select-label'>Project</InputLabel>
              <Select
                value={workedOn}
                multiple
                onChange={handleChange}
                id='projectID-select'
                variant='outlined'
                fullWidth
                label='Project'
                renderValue={selected => selected.join(', ')}
                MenuProps={{
                  anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'center'
                  }
                }}
              >
                {projectTitles.sort().map(project => (
                  <MenuItem key={project.projectID} value={project.projectName}>
                    <Checkbox
                      checked={workedOn.includes(project.projectName)}
                    />
                    {project.projectName}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          )}

          <TextField
            required={dialogType != 'today'}
            label={'Comments'}
            value={comments}
            onChange={e => setComments(e.target.value)}
            fullWidth
            sx={{ margin: '2%' }}
            error={dialogType != 'today' && mandatoryIsEmpty && !comments}
          />
          <Button
            variant='contained'
            className='button-gradient'
            onClick={() => {
              handleMarkAttendance()
            }}
          >
            {dialogType === 'today'
              ? 'Mark Attendance'
              : dialogType === 'missed'
              ? 'Mark Missed Attendance'
              : 'Submit Attendance Request'}
          </Button>
        </form>
      </DialogContent>
      <Backdrop
        sx={{ color: '#fff', zIndex: theme => theme.zIndex.drawer + 1 }}
        open={progress}
      >
        <CircularProgress></CircularProgress>
      </Backdrop>
    </Dialog>
  )
}
