import React, { useState,useEffect } from 'react'
import { useGridApiRef } from '@mui/x-data-grid'
import { Typography, Button } from '@mui/material'
import TableComponent from '../../TableComponents'
import CreateUserForm from '../AdminCreateForms/CreateUserForm'
import { WorkspacesOutlined } from '@mui/icons-material'
import CreateDepartmentForm from '../AdminCreateForms/CreateDepartmentForm'
export default function DepartmentTable () {
  const apiRef = useGridApiRef()
  const itemName = 'department'
  const itemID = 'departmentID'
  const [formOpen, setFormOpen] = useState(false)
  const [refreshTable, setRefreshTable] = useState(false)

  const handleRefreshTable = newState => {
    setRefreshTable(newState)
  }
  const handleClose = () => {
    setFormOpen(false)
  }

  
  useEffect(() => {
    setRefreshTable(false)
  }, [refreshTable])

  const columns = [
    {
      field: 'departmentName',
      headerName: 'Department',
      flex: 1,
      editable: true
    }
  ]
  return (
    <>
      <div>
        <div className='div-spaceToSides'>
          <Typography variant='h4'>
            <WorkspacesOutlined />
            Departments
          </Typography>
          <Button
            variant='filled'
            className='button-gradient'
            onClick={() => setFormOpen(true)}
          >
            +Add new department
          </Button>
        </div>
        <TableComponent
          refreshTable={refreshTable}
          apiRef={apiRef}
          itemID={itemID}
          itemName={itemName}
          columns={columns}
        />

        <CreateDepartmentForm
          open={formOpen}
          handleClose={handleClose}
          setRefreshTable={setRefreshTable}
        />
      </div>
    </>
  )
}
