import React, { useEffect, useState } from 'react'
import {
  TextField,
  Button,
  CircularProgress,
  Backdrop
} from '@mui/material'
import { useAuthUser, useIsAuthenticated } from 'react-auth-kit'
import {
  fetchLeaveRequestsForManager,
  arbitrateLeaveRequests
} from '../../service/leaveService'
import { DataGrid, useGridApiRef } from '@mui/x-data-grid'
import dayjs from 'dayjs'
import { dataGridStyles } from '../../stylings/datagridStyles'

export default function LeaveRequests () {
  const [LeaveRequests, setLeaveRequest] = useState([])
  const isAuthenticated = useIsAuthenticated()
  const user = useAuthUser()
  const [rowSelectionModel, setRowSelectionModel] = useState([])
  const apiRef = useGridApiRef()
  const [progress, setProgress] = useState(false)
  async function fetchData () {
    setProgress(true)
    try {
      const response = await fetchLeaveRequestsForManager(
        user().team,
        user().employeeID
      )
      const data = await response.json()
      const newRows = data.map(item => ({
        employeeLeaveID: item.employeeLeave.employeeLeaveID,
        approved: item.employeeLeave.approved,
        comments: item.employeeLeave.comments,
        reason: item.employeeLeave.reason,
        days:item.employeeLeave.daysOff,
        leaveStartDate: item.employeeLeave.leaveStartDate,
        leaveEndDate: item.employeeLeave.leaveEndDate,
        employeeIDf: item.employeeLeave.employeeIDf,
        managerRemark: item.employeeLeave.managerRemark,
        leaveType: item.employeeLeave.leaveType,
        name: item.name,
        lname: item.lname,
      }))
      setLeaveRequest(newRows)
    } catch (error) {
      console.error(error)
    }
    finally{
      setProgress(false)
    }
  }

  useEffect(() => {
    if (user() && isAuthenticated()) fetchData()
  }, [user(), isAuthenticated()])

  const renderRadioButtons = params => {
    const row = params.row
    return (
      <div className='div-spaceToSides'>
        <Button
          value={'true'}
          onClick={e => {
            handleRadioChange(e, row.employeeLeaveID)
            handleSubmit(row)
          }}
          color='secondary'
          variant='outlined'
          sx={{margin:'5%'}}

        >
          Approve
        </Button>
        <Button
          value={'false'}
          onClick={e => {
            handleRadioChange(e, row.employeeLeaveID)
            handleSubmit(row)
          }}
          color='error'
          variant='outlined'
        >
          Deny
        </Button>
      </div>
    )
  }

  const renderManagerRemarksCell = params => {
    const row = params.row
    return (
      <TextField
        fullWidth
        type='text'
        value={row.managerRemark}
        onChange={event => handleManagerRemarksChange(event, row.employeeLeaveID)}
      ></TextField>
    )
  }
  const columns = [
    {
      field: 'employeeIDf',
      headerName: 'Employee ID',
      width: 150
    },
    {
      field: 'name',
      headerName: 'First Name',
      width: 150
    },
    {
      field: 'lname',
      headerName: 'Last Name',
      width: 150
    },
    {
      field: 'leaveStartDate',
      headerName: 'Start Date',
      type: 'date',
      valueGetter: ({ value }) =>
        value !== null ? dayjs(value).toDate() : null,
      width: 150
    },
    {
      field: 'leaveEndDate',
      headerName: 'End Date',
      type: 'date',
      valueGetter: ({ value }) =>
        value !== null ? dayjs(value).toDate() : null,
      width: 150
    },
    {
      field: 'leaveType',
      headerName: 'Type',
      width: 100
    },
    {
      field: 'days',
      headerName: 'Days Off',
      width: 100
    },
    {
      field: 'reason',
      headerName: 'Leave Reason',
      width: 300
    },
    {
      field: 'comments',
      headerName: 'Comments',
      width: 200,
      sortable: false,
      filterable: false
    },
    {
      field: 'actions',
      headerName: 'Actions',
      sortable: false,
      filterable: false,
      width: 250,
      renderCell: renderRadioButtons
    },
    {
      field: 'managerRemark',
      headerName: 'Remarks',
      sortable: false,
      filterable: false,
      width: 200,
      renderCell: renderManagerRemarksCell
    }
  ]
  const handleManagerRemarksChange = (event, employeeLeaveID) => {
    const index = LeaveRequests.findIndex(
      request => request.employeeLeaveID === employeeLeaveID
    )

    if (index !== -1) {
      const updatedRequests = [...LeaveRequests]
      updatedRequests[index].managerRemark = event.target.value
      setLeaveRequest(updatedRequests)
    }
  }
  const handleRadioChange = (event, employeeLeaveID) => {
    const updatedRequests = [...LeaveRequests]
    const updatedRequest = updatedRequests.find(
      request => request.employeeLeaveID === employeeLeaveID
    )
    updatedRequest.approved = event.target.value
    setLeaveRequest(updatedRequests)
  }

  const handleApprovalOfAll = approval => {
    const updatedRequests = rowSelectionModel
      .map(employeeLeaveID => {
        return LeaveRequests.find(att => att.employeeLeaveID === employeeLeaveID)
      })
    updatedRequests.forEach(req => (req.approved = approval))
    setLeaveRequest(LeaveRequests.filter(r=>!updatedRequests.includes(r)))
    handleSubmit()
  }
  const handleSubmit = async aReq => {
    try {
      setProgress(true)
      if (aReq) {
        const res = await arbitrateLeaveRequests(aReq.approved, aReq)
      } else {
        for (const LeaveID of rowSelectionModel) {
          const selectedRequest = LeaveRequests.find(
            att => att.employeeLeaveID === LeaveID
          )
          const res = await arbitrateLeaveRequests(
            selectedRequest.approved,
            selectedRequest
          )
        }
      }
    } catch (error) {
      console.error(error)
    } finally {
      fetchData()
      setProgress(false)
      setRowSelectionModel([])
      
    }
  }


  return (
    <>
        <DataGrid
          disableRowSelectionOnClick
          checkboxSelection
          autoHeight
          loading={progress}s
          rows={LeaveRequests}
          columns={columns}
          getRowId={row => row.employeeLeaveID}
          apiRef={apiRef}
          sx={dataGridStyles}
          rowSelectionModel={rowSelectionModel}
          onRowSelectionModelChange={newRowSelectionModel =>
            setRowSelectionModel(newRowSelectionModel)
          }
          showCellVerticalBorder
          initialState={{
            sorting: {
              sortModel: [{ field: 'attendanceDate', sort: 'asc' }]
            },
            pagination: {
              paginationModel: {
                page: 0,
                pageSize: 5
              }
            }
          }}
          pageSizeOptions={[5, 10, 15, 20, 100]}
        />
        <Button
          variant='outlined'
          color='secondary'
          onClick={() => handleApprovalOfAll(true)}
          sx={{margin:'1%'}}

        >
          Approve All Selected
        </Button>
        <Button
          variant='outlined'
          color='error'
          onClick={() => handleApprovalOfAll(false)}
        >
          Deny All Selected
        </Button>
      </>

  )
}
