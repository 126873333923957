import Cookies from 'js-cookie';
import { BASE_URL } from "./apiService";

export const addUser = async (userDetails) => {
    try {
      const authToken = Cookies.get('_auth');
      const response = await fetch(BASE_URL + '/user/add', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${authToken}`
        },
        body: JSON.stringify(userDetails)
      });
      return response;
    } catch (error) {
      console.error(error);
      throw error;
    }
  }

  export const fetchShortUserInfo = async () => {
    try {
      const authToken = Cookies.get('_auth')
      const response = await fetch(`${BASE_URL}/user/getShortAll`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${authToken}`
        }
      })
      return response
    } catch (error) {
      console.error(`Error fetching alertss:`, error)
      return []
    }
  }