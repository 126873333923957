import React, { useEffect, useState } from 'react'
import { Typography } from '@mui/material'
import { useAuthUser } from 'react-auth-kit'
import { fetchWorkAndLeaveInfo } from '../../service/attendanceService'
import dayjs from 'dayjs'

export default function WorkAndLeaveInfo ({ thisMonthDate }) {
  const currentDate = thisMonthDate
  const [workingDaysInMonth, setWorkingDaysInMonth] = useState(0)
  const [leavesInMonth, setLeavesInMonth] = useState(0)
  const [leavesInYear, setLeavesInYear] = useState(0)
  const authUser = useAuthUser()
  const employeeID = authUser().employeeID

  useEffect(() => {
    fetchData()
  }, [])

  const fetchData = async () => {
    await fetchAllInfo().then(res => {
      setWorkingDaysInMonth(res.workingDaysInMonth)
      setLeavesInMonth(res.leavesInMonth)
      setLeavesInYear(res.leavesInYear)
    })
  }
  const fetchAllInfo = async () => {
    const res = await fetchWorkAndLeaveInfo(
      employeeID,
      dayjs(currentDate).get('month') + 1
    )
    const leaveInfo = await res.json()
    return leaveInfo
  }

  return (
    <div className='div-centerstyle' style={{ backgroundColor: 'whitesmoke' }}>
      <Typography variant='body1'>
        Working Days in{' '}
        {currentDate.toLocaleString('default', { month: 'long' })}:{' '}
        {workingDaysInMonth}
      </Typography>
      <Typography variant='body1'>
        Leaves taken in{' '}
        {currentDate.toLocaleString('default', { month: 'long' })}:{' '}
        {leavesInMonth}
      </Typography>
      <Typography variant='body1'>
        Leaves taken in {dayjs(currentDate).get('year')}: {leavesInYear}
      </Typography>
    </div>
  )
}
