import React, { useState,useEffect } from 'react'
import { useGridApiRef } from '@mui/x-data-grid'
import { Typography, Button } from '@mui/material'
import TableComponent from '../../TableComponents'
import { PeopleOutline, RemoveRedEye } from '@mui/icons-material'
import CreateTeamForm from '../AdminCreateForms/CreateTeamForm'
import PermissionsTableDialog from './PermissionsTableDialog'
import CreateRoleForm from '../AdminCreateForms/CreateRoleForm'
export default function RolesTable () {
  const apiRef = useGridApiRef()
  const itemName = 'role'
  const itemID = 'roleID'
  const [formOpen, setFormOpen] = useState(false)
  const [refreshTable, setRefreshTable] = useState(false)
  const [isPermissionView, setIsPermissionView] = useState(false)
  const [selectedRoleName, setSelectedRoleName] = useState(null)
  const [selectedRole, setSelectedRole] = useState(null)

  const [selectedRoleID, setSelectedRoleID] = useState(null)
  const handleRefreshTable = newState => {
    setRefreshTable(newState)
  }
  const handleClose = () => {
    setFormOpen(false)
  }
  const handlePermissionClose = () => {
    setIsPermissionView(false)
  }

  useEffect(() => {
    setRefreshTable(false)
  }, [refreshTable])
  const columns = [
    {
      field: 'roleName',
      headerName: 'Role',
      flex: 1,
      editable: true
    },
    {
      field: 'view',
      headerName: 'View Permissions',
      renderCell: params => (
        <Button onClick={() => handleToggleChange(params, 'permissions')}>
          <RemoveRedEye />
        </Button>
      ),
      flex: 0.3,
      filterable: false,
      sortable: false
    }
  ]
  const handleToggleChange = (params, selectedValue) => {
    if (selectedValue === 'permissions') {
      setSelectedRoleName(apiRef.current.getCellValue(params.id, 'roleName'))
      setSelectedRoleID(params.id)
      setIsPermissionView(true)
      handleRefreshTable(true)
    } else {
      setIsPermissionView(false)
      console.log('Role')
    }
  }
  return (
    <>
      <div>
        <div className='div-spaceToSides'>
          <Typography variant='h4'>
            <PeopleOutline />
            Roles
          </Typography>
          <Button
            variant='filled'
            className='button-gradient'
            onClick={() => setFormOpen(true)}
          >
            +Add new role
          </Button>
        </div>
        <TableComponent
          refreshTable={refreshTable}
          apiRef={apiRef}
          itemID={itemID}
          itemName={itemName}
          columns={columns}
        />
        <CreateRoleForm
          open={formOpen}
          handleClose={handleClose}
          setRefreshTable={handleRefreshTable}
        />
        <PermissionsTableDialog
          open={isPermissionView}
          roleName={selectedRoleName}
          roleID={selectedRoleID}
          handleRefreshTable={setRefreshTable}
          handleClose={handlePermissionClose}
        />
      </div>
    </>
  )
}
