import { React } from 'react'
import LoginForm from '../components/LoginForm'
import { Container, CssBaseline, Grid } from '@mui/material'
export default function LogInComponent () {
  return (
    <Grid
      container
      columns={20}
      style={{
        width: '100vw',
        display: 'flex',
        alignItems: 'center',
        justifyContent:'center',
        height:'100vh'
      }}
      className='containerStyleLogin'
    >
      <Grid item xs={20} md={20} lg={20} xl={20}>
        <LoginForm />
      </Grid>
    </Grid>
  )
}
