import React, { useState } from 'react'
import AttendanceReport from './AttendanceOverridbleTable'
import { Paper, Typography, Grid } from '@mui/material'
import LeaveOverrideFilterPage from './LeaveOverridbleFilter'
import LeaveOverrideTable from './LeaveOverridbleTable'

export default function LeaveOverridePage () {
  const [generateReport, setGenerateReport] = useState(false)
  const [refreshTable,setRefreshTable] = useState(false)
  const [selectedValues, setSelectedValues] = useState({
    startDate: null,
    endDate: null,
    department: null,
    team: null,
    shift: null,
    employee_id: null
  })

  const handleGenerateReport = values => {
    setGenerateReport(true)
    setSelectedValues(values)
  }

  return (
    <Grid container spacing={1}>
      <Grid item xs={12} md={12}>
        <Paper elevation={2} className='paperStyle2'>
          <Typography variant='h4'>Leave Details</Typography>
          <LeaveOverrideFilterPage onGenerateReport={handleGenerateReport} />
        </Paper>
      </Grid>
      {generateReport && (
        <Grid item xs={12} md={12}>
          <Paper elevation={2} className='paperStyle2'>
            <Typography variant='h4'>Leave</Typography>
            <LeaveOverrideTable {...selectedValues} refreshTable={refreshTable}/>
          </Paper>
        </Grid>
      )}

    </Grid>
  )
}
