import Cookies from 'js-cookie'
import { BASE_URL } from './apiService'
export const fetchPermissionsForRole= async (roleID) => {
    try {
      const authToken = Cookies.get('_auth')
      const response = await fetch(
        BASE_URL +
          '/role/getPermissions/'+roleID,
        {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${authToken}`
          }
        }
      )
      return response
    } catch (error) {
      console.error(error)
      throw error
    }
  }