import Cookies from 'js-cookie'
import { BASE_URL } from '../apiService'

export const updateItem = async (modelName, itemDetails) => {
    if (itemDetails) {
    try {
      const authToken = Cookies.get('_auth')
      const response = await fetch(
        `${BASE_URL}/${modelName}/edit`,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${authToken}`
          },
          body: JSON.stringify(itemDetails)
        }
      )
      return response
    } catch (error) {
      console.error(error)
      throw error
    }
  }
}
