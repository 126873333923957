/*For use of BeQiSoft Pvt Ltd. */

import React, { useState, useEffect } from 'react'
import { Select, MenuItem, Checkbox } from '@mui/material'
import { fetchItems } from '../../../service/InterfaceFunctions/fetchItems'
export default function HolidayListSelect ({ value, onChange }) {
  const [allHolidayList, setAllHolidayList] = useState([])
  const [holidayListsSelected, setHolidayListsSelected] = useState([])

  async function fetchHolidayList () {
    try {
      await fetchItems('holidaylist').then(result => {
        setAllHolidayList(result)
      })
    } catch (error) {
      console.error(error)
      setAllHolidayList([])
    }
  }
  useEffect(() => {
    fetchHolidayList()
  }, [])

  const handleholidaylistchange = event => {
    const selectedHolidayListIDs = event.target.value
    const selectedHolidayLists = allHolidayList.filter(hList =>
      selectedHolidayListIDs.includes(hList.holidayListID)
    )
    setHolidayListsSelected(selectedHolidayLists)
    onChange(selectedHolidayLists)
  }
  useEffect(() => {
    if (value && value.length > 0) {
      setHolidayListsSelected(value)
    }
  }, [value])
  return (
    <Select
      multiple
      value={value.map(hList => hList.holidayListID)}
      onChange={handleholidaylistchange}
      renderValue={selectedHolidayList =>
        selectedHolidayList
          .map(
            holidayListID =>
              allHolidayList.find(
                holiday => holiday.holidayListID === holidayListID
              )?.listName || ''
          )
          .join(', ')
      }
      id='holidayList-select'
      variant='outlined'
      fullWidth
      label='Holiday List'
      MenuProps={{
        anchorOrigin: {
          vertical: 'top',
          horizontal: 'center'
        }
      }}
    >
      {allHolidayList.map(hList => (
        <MenuItem key={hList.holidayListID} value={hList.holidayListID}>
          <Checkbox  checked={holidayListsSelected.some(hL=>hL.holidayListID===hList.holidayListID)}/>
          {hList.listName}
        </MenuItem>
      ))}
    </Select>
  )
}
