/*For use of BeQiSoft Pvt Ltd. */

import React, { useState, useEffect } from 'react'
import TextField from '@mui/material/TextField'
import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton
} from '@mui/material'
import AddIcon from '@mui/icons-material/Add'
import { addItem } from '../../../service/InterfaceFunctions/addItem'
import { CancelOutlined } from '@mui/icons-material'
import * as yup from 'yup'
import { Formik, validateYupSchema } from 'formik'

export default function CreateDeviceDamageForm ({
  open,
  handleClose,
  setRefreshTable
}) {
  const [typeName, setTypeName] = useState(null)
  const [mandatoryIsEmpty, setMandatoryIsEmpty] = useState(false)
  const resetState = () => {
    setMandatoryIsEmpty(false)
    setTypeName(null)
  }
  useEffect(() => {
    if (open) {
      resetState()
    }
  }, [open])

  const handleFormClose = () => {
    setTypeName('')
    handleClose()
  }
  const handleSubmit = async e => {
    try {
      const res = await addItem('devicedamages', e)
      const data = await res.text()
      alert.show(data)
    } catch (error) {
      console.log(error)
      alert.show('Error')
    } finally {
      setRefreshTable(true)
      handleFormClose()
    }
  }

  return (
    <Dialog open={open}>
      <DialogTitle>
        Create Damage Type
        <IconButton onClick={handleFormClose} sx={{ float: 'right' }}>
          <CancelOutlined />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <Formik
          initialValues={{
            damageName: null
          }}
          onSubmit={async values => {
            await handleSubmit(values)
          }}
          validationSchema={yup.object({
            damageName: yup.string().required('Required')
          })}
        >
          {props => {
            const {
              values,
              touched,
              errors,
              dirty,
              isSubmitting,
              handleChange,
              handleBlur,
              handleSubmit,
              handleReset,
              setFieldValue
            } = props
            return (
              <form className='root' noValidate onSubmit={handleSubmit}>
                <TextField
                  label='Damage Name'
                  variant='outlined'
                  fullWidth
                  value={values.damageName}
                  required={true}
                  error={touched.damageName && errors.damageName}
                  onChange={handleChange('damageName')}
                  style={{ margin: '20px auto' }}
                />
                <Button
                  variant='contained'
                  type='submit'
                  className='button-gradient'
                >
                  Submit
                </Button>
              </form>
            )
          }}
        </Formik>
      </DialogContent>
    </Dialog>
  )
}
