import {
  Paper,
  Tabs,
  Typography,
  Grid
} from '@mui/material'
import React, { useState } from 'react'
import { CustomTab } from '../stylings/customtabstyle'
import { useAuthUser } from 'react-auth-kit'
import AttendanceOverridePage from '../components/Admin/AdminOverrides/AttendanceOverridePage'
import LeaveOverridePage from '../components/Admin/AdminOverrides/LeaveOverridblePage'
import { pageStyle } from '../stylings/pageStyle'
export default function AdminOverrideBoard () {
  const [value, setValue] = useState(0)
  const user = useAuthUser()
  const handleChange = (event, newValue) => {
    setValue(newValue)
  }

  return (
    <Grid
      container
      columns={20}
      style={pageStyle}
    >
      <Grid item xs={20} md={20} lg={20} xl={20}>
        <Paper elevation={3} className='paperStyle2'>
          <Tabs
            indicatorColor='secondary'
            value={value}
            onChange={handleChange}
          >
            <CustomTab
              label={<Typography variant='h6'>Attendance</Typography>}
            />
            <CustomTab label={<Typography variant='h6'>Leaves</Typography>} />
          </Tabs>
          {value === 0 && <AttendanceOverridePage/>}
          {value === 1 && <LeaveOverridePage/>}
        </Paper>
      </Grid>
    </Grid>
  )
}
