import React, { useState, useEffect } from 'react'
import { DataGrid, useGridApiRef } from '@mui/x-data-grid'
import { Typography, Button, IconButton, Paper } from '@mui/material'
import TableComponent from '../TableComponents'
import { Laptop, PhoneAndroid, PhotoCamera } from '@mui/icons-material'
import dayjs from 'dayjs'
import GenericSelectCell from '../Admin/AdminSelectCells/GenericSelectCell'
import { fetchShortUserInfo } from '../../service/addUserService'
import { useAlert } from 'react-alert'
import { renderDeviceImageViewer } from '../Admin/AdminCreateForms/renderDeviceImageViewer'
import { fetchItems } from '../../service/InterfaceFunctions/fetchItems'
import { dataGridStyles } from '../../stylings/datagridStyles'
import CustomGridToolbarNoAdd from '../Admin/CustomGridToolbarNoAdd'
import DeviceTransferForm from './DeviceTransferForm'
import {
  fetchDeviceAssetsForemployee,
  repairOrSurrender,
  transferDeviceFromReceiver
} from '../../service/deviceAssetService'
import { useAuthUser } from 'react-auth-kit'
import DeviceAssetTransferTable from './DeviceAssetTransferTable'
import { fetchHardwareAssetsForemployee } from '../../service/hardwareService'
import { fetchLaptopAssetsForemployee } from '../../service/laptopService'

export default function LaptopAssetUserTable () {
  const apiRef = useGridApiRef()
  const itemName = 'laptopAsset'
  const itemID = 'laptopID'
  const user = useAuthUser()
  const [formOpen, setFormOpen] = useState(false)
  const [refreshTable, setRefreshTable] = useState(false)
  const [items, setItems] = useState([])
  const [progress, setProgress] = useState(false)
  const [openTransferForm, setOpenTransferForm] = useState(false)
  const [row, setRow] = useState([])
  const alert = useAlert()
  const deleteDisable = row => row != null
  const handleRefreshTable = newState => {
    setRefreshTable(newState)
  }
  const handleClose = () => {
    setFormOpen(false)
  }
  async function fetchData () {
    try {
      setProgress(true)
      const res = await fetchLaptopAssetsForemployee(user().employeeID)
      const data = await res.json()
      setItems(data)
    } catch (error) {
      console.error(error)
      setProgress(false)
    } finally {
      setProgress(false)
    }
  }

  useEffect(() => {
    setRefreshTable(false)
    fetchData()
  }, [refreshTable])

  const columns = [
    {
      field: 'inUse',
      headerName: 'In Use',
      type: 'boolean',
      width: 100,
      
    },
    {
      field: 'laptopAssetID',
      headerName: 'Laptop Asset ID',
      width: 200,
      
    },
    {
      field: 'brand',
      headerName: 'Brand',
      width: 200,
      
    },
    {
      field: 'modelName',
      headerName: 'Model Name',
      width: 200,
      
    },
    {
      field: 'modelNo',
      headerName: 'Model No',
      width: 200,
      
    },

    {
      field: 'imeiCode',
      headerName: 'IMEI Code',
      width: 200,
      
    },
    {
      field: 'serialNo',
      headerName: 'Serial Number',
      width: 200,
      
    },
    {
      field: 'storage',
      headerName: 'Storage',
      width: 200,
      
    },
    {
      field: 'diskDrives',
      headerName: 'Disk Drives',
      width: 200,
      
    },
    
    {
      field: 'warranty',
      headerName: 'Warranty',
      width: 200,

      
    },
    {
      field: 'screenSize',
      headerName: 'Screen Size',
      width: 200,
      
    },

    {
      field: 'accessories',
      headerName: 'Accessories',
      width: 200,
      
    },

    {
      field: 'additionalItems',
      headerName: 'Additional Items',
      width: 200,

      
    },
    {
      field: 'otherDetails',
      headerName: 'Other Details',
      width: 200,

      
    }
  ]

  return (
    <>
      <div className='div-paperStyle2'>
        <div>
          <div className='div-spaceToSides'>
            <Typography variant='h4'>
              <Laptop />
              Current Laptop Assets
            </Typography>
          </div>

          <DataGrid
            apiRef={apiRef}
            columns={columns}
            rows={items}
            getRowId={row => row[itemID]}
            sx={dataGridStyles}
            loading={progress}
            showCellVerticalBorder
            disableVirtualization
            autoHeight
            autoSizeOnMount={true}
            initialState={{
              pagination: {
                paginationModel: {
                  page: 0,
                  pageSize: 5
                }
              }
            }}
            pageSizeOptions={[5, 10, 15, 20, 100]}
            slots={{
              toolbar: CustomGridToolbarNoAdd
            }}
          />
        </div>
      </div>
    </>
  )
}
