import Cookies from 'js-cookie'
import { BASE_URL } from './apiService'

export const getAllHolidayLists = async () => {
  try {
    const authToken = Cookies.get('_auth')
    const response = await fetch(BASE_URL + '/holidaylist/getAll', {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${authToken}`
      }
    })
    return response
  } catch (error) {
    console.error(error)
    throw error
  }
}
export const fetchHolidaysForList = async (listName) => {
  try {
    const authToken = Cookies.get('_auth')
    const response = await fetch(
      BASE_URL +
        '/holidaylist/get/holidays/'+listName,
      {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${authToken}`
        }
      }
    )
    return response
  } catch (error) {
    console.error(error)
    throw error
  }
}

export const addHolidayList = async holidayListDetails => {
  try {
    const authToken = Cookies.get('_auth')
    const response = await fetch(BASE_URL + '/holidaylist/add', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${authToken}`
      },
      body: JSON.stringify(holidayListDetails)
    })
    return response
  } catch (error) {
    console.error(error)
    throw error
  }
}