import Cookies from 'js-cookie'
import { BASE_URL } from '../apiService'

export const deleteItem = async (modelName, itemId) => {
  try {
    const authToken = Cookies.get('_auth')
    const response = await fetch(`${BASE_URL}/${modelName}/delete/${itemId}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${authToken}`
      },
    })
    return response
  } catch (error) {
    console.error(error)
    throw error
  }
}
