import React, { useEffect, useState } from 'react'
import { useGridApiRef } from '@mui/x-data-grid'
import { Typography, Button } from '@mui/material'
import TableComponent from '../../TableComponents'
import CreateUserForm from '../AdminCreateForms/CreateUserForm'
import { DeveloperBoardOutlined } from '@mui/icons-material'
import CreateProjectForm from '../AdminCreateForms/CreateProjectForm'
import CreateLocationForm from '../AdminCreateForms/CreateLocationForm'
export default function ProjectTable () {
  const apiRef = useGridApiRef()
  const itemName = 'project'
  const itemID = 'projectID'
  const [formOpen, setFormOpen] = useState(false)
  const [refreshTable, setRefreshTable] = useState(false)

  const handleRefreshTable = newState => {
    setRefreshTable(newState)
  }
  const handleClose = () => {
    setFormOpen(false)
  }

  useEffect(() => {
    setRefreshTable(false)
  }, [refreshTable])
  
  const columns = [
    {
      field: 'projectName',
      headerName: 'Project',
      flex: 1,
      editable: true
    }
  ]
  return (
    <>
      <div>
        <div className='div-spaceToSides'>
          <Typography variant='h4'>
            <DeveloperBoardOutlined />
            Projects
          </Typography>
          <Button
            variant='filled'
            className='button-gradient'
            onClick={() => setFormOpen(true)}
          >
            +Add new project
          </Button>
        </div>
        <TableComponent
          refreshTable={refreshTable}
          apiRef={apiRef}
          itemID={itemID}
          itemName={itemName}
          columns={columns}
        />
        <CreateProjectForm
          open={formOpen}
          handleClose={handleClose}
          setRefreshTable={setRefreshTable}
        />
      </div>
    </>
  )
}
