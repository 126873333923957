import React, { useEffect, useState } from 'react'
import { DataGrid } from '@mui/x-data-grid'
import { Paper, Typography } from '@mui/material'
import { fetchLeaveCount } from '../../service/leaveService'
import { useAuthUser } from 'react-auth-kit'
import { dataGridStyles } from '../../stylings/datagridStyles'
export default function LeaveCount ({ refreshTable }) {
  const [holidays, setHolidays] = useState([])
  const [progress,setProgress]=useState(false)
  const user = useAuthUser()
  async function fetchData () {
    setProgress(true)
    try {
      const response = await fetchLeaveCount(user().employeeID)
      const data = await response.json()
      setHolidays(data)
    } catch (error) {
      console.error(error)
    }
    finally
    {
      setProgress(false)
    }

  }

  useEffect(() => {
    fetchData()
  }, [refreshTable])
  const columns = [
    {
      field: 'leaveType',
      headerName: 'Leave Type',
      flex: 2
    },
    {
      field: 'TotalAllocatedLeaves',
      headerName: 'Total Allocated Leaves',
      flex: 2
    },
    {
      field: 'LeavesTaken',
      headerName: 'Leaves Taken',
      flex: 2
    },
    {
      field: 'LeavesPendingApproval',
      headerName: 'Pending Approval',
      flex: 2
    },
    {
      field: 'LeaveBalance',
      headerName: 'Leave Balance',
      flex: 2
    }
  ]



  return (
    <Paper
      elevation={2}
      className='paperStyle5'
      style={{ border: '0.5rem solid #e5f4fc' }}
    >
      <Typography variant='h4'>Leave Count</Typography>
      <DataGrid
        autoHeight
        disableRowSelectionOnClick
        loading={progress}
        rows={holidays}
        columns={columns}
        getRowId={row => row.leaveType}
        sx={dataGridStyles}
        hideFooter={true}
      />
    </Paper>
  )
}
