import { Check, Close, Error, Info } from "@mui/icons-material"
import React from "react"


export default function AlertStylingsTemplate({ message, options, style, close }){
    const buttonStyle = {
        marginLeft: '20px',
        marginRight:'10px',
        border: 'none',
        backgroundColor: 'transparent',
        cursor: 'pointer',
        color: '#FFFFF'
      }
      const alertStyle = {
        backgroundColor: 'white',
        color: 'black',
        padding: '10px',
        borderRadius: '3px',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        boxShadow: '0px 2px 2px 2px rgba(0, 0, 0, 0.03)',
        border:'2px solid green',
        fontFamily: 'Arial',
        width: '300px',
        boxSizing: 'border-box'
      }
    return (
      <div style={{ ...alertStyle, ...style }}>
        {options.type === 'info' &&  <Info/>}
        {options.type === 'success' && <Check/>}
        {options.type === 'error' && <Error/>}
        <span style={{ flex: 2 }}>{message}</span>
        <button onClick={close} style={buttonStyle}>
          <Close/>
        </button>
      </div>
    )
  }