import React, { useState, useEffect } from 'react'
import { useGridApiRef } from '@mui/x-data-grid'
import { Typography, Button, FormControl } from '@mui/material'
import { PunchClockOutlined } from '@mui/icons-material'
import TableComponent from '../../TableComponents'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { TimePicker } from '@mui/x-date-pickers/TimePicker'
import dayjs from 'dayjs'
import CreateShiftForm from '../AdminCreateForms/CreateShiftForm'
import TimezoneSelect, {
  useTimezoneSelect,
  allTimezones
} from 'react-timezone-select'
import TimezoneSelectCell from '../AdminSelectCells/TimezoneSelecCell'

export default function ShiftsTable () {
  const apiRef = useGridApiRef()
  const itemName = 'shift'
  const itemID = 'shiftID'
  const [formOpen, setFormOpen] = useState(false)
  const [refreshTable, setRefreshTable] = useState(false)

  const handleRefreshTable = newState => {
    setRefreshTable(newState)
  }
  const handleClose = () => {
    setFormOpen(false)
  }

  useEffect(() => {
    setRefreshTable(false)
  }, [refreshTable])
  const columns = [
    {
      field: 'shiftName',
      headerName: 'Name',
      flex: 2,
      editable: true
    },
    {
      field: 'shiftTimeZone',
      headerName: 'Time Zone',
      flex: 7,
      editable: true,
      renderEditCell: params => (
        <TimezoneSelectCell
          value={params.value}
          onChange={async event => {
            await apiRef.current.setEditCellValue({
              id: params.id,
              field: params.field,
              value: event.value
            })
          }}
        />
      )
    },
    {
      field: 'shiftStartTime',
      headerName: 'Start',
      flex: 3,
      editable: true,
      type: 'time',
      renderEditCell: params => (
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <TimePicker
            value={dayjs(params.value)}
            label={params.headerName}
            ampm={false}
            views={['hours', 'minutes', 'seconds']}
            onChange={async event => {
              await apiRef.current.setEditCellValue({
                id: params.id,
                field: params.field,
                value: dayjs(event).format('HH:mm:ss')
              })
            }}
          />
        </LocalizationProvider>
      )
    },
    {
      field: 'shiftEndTime',
      headerName: 'End',
      flex: 3,
      editable: true,
      type: 'time',
      renderEditCell: params => (
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <TimePicker
            value={dayjs(params.value)}
            label={params.headerName}
            ampm={false}
            views={['hours', 'minutes', 'seconds']}
            onChange={async event => {
              await apiRef.current.setEditCellValue({
                id: params.id,
                field: params.field,
                value: dayjs(event).format('HH:mm:ss')
              })
            }}
          />
        </LocalizationProvider>
      )
    }
  ]
  return (
    <>
      <div>
        <div className='div-spaceToSides'>
          <Typography variant='h4'>
            <PunchClockOutlined />
            Shifts
          </Typography>
          <Button
            variant='filled'
            className='button-gradient'
            onClick={() => setFormOpen(true)}
          >
            +Add new shift
          </Button>
        </div>
        <TableComponent
          refreshTable={refreshTable}
          apiRef={apiRef}
          itemID={itemID}
          itemName={itemName}
          columns={columns}
        />
        <CreateShiftForm
          open={formOpen}
          handleClose={handleClose}
          setRefreshTable={setRefreshTable}
        />
      </div>
    </>
  )
}
