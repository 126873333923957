import { useState, useEffect, useMemo } from 'react'
import { useMediaQuery } from 'react-responsive'
import Calendar from 'react-calendar'
import '../../stylings/customcalenderstyle.css'
import { CircularProgress, Grid } from '@mui/material'
import { tileLogic } from './tileLogicFunctions'
import { useAuthUser, useAuthHeader } from 'react-auth-kit'
import Tooltip from '@mui/material/Tooltip'
import WorkAndLeaveInfo from './WorkAndLeaveInfo'
import { InfoOutlined } from '@mui/icons-material'
import TodayChangeDialog from './TodayChangeDialog'
import ConslidatedDialog from './ConsolidatedDialog'
import ApplyLeaves from '../Leave/Applyleaves'
import dayjs from 'dayjs'
import CalenderLegendTooltip from './CalenderLegendTooltip'
import { useGetAttendanceDataQuery } from '../../reduxstore/features/attendanceRTKFile'
import { useGetHolidaysDataQuery } from '../../reduxstore/features/holidaysRTKFile'
export default function AttendanceCalendar () {
  const [attendanceData, setAttendanceData] = useState([])
  const [holidays, setHolidays] = useState([])
  const [isStillLoading, setIsStillLoading] = useState(true)
  const user = useAuthUser()
  const auth = useAuthHeader()
  const [date, setDate] = useState(dayjs(new Date()).toDate())
  const [selectedMissedDate, setSelectedMissedDate] = useState(null)
  const [todayChangeDialog, setTodayChangeDialog] = useState(false)
  const [refreshTable, setRefreshTable] = useState(false)
  const today = dayjs(new Date()).toDate()
  const [dialogType, setDialogType] = useState(null)
  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1224px)' })
  const [legendToolTipOpen, setLegendToolTipOpen] = useState(false)
  const [infoToolTipOpen, setInfoToolTipOpen] = useState(false)
  const [isDialogOpen, setDialogOpen] = useState(false)
  const [leaveFormClick, setLeaveFormClick] = useState(false)
  const {
    data = attendanceData,
    isError,
    isLoading
  } = useGetAttendanceDataQuery({
    employeeID: user().employeeID,
    auth: auth()
  })
  const {
    data: holidaylist = [],
    isError: holidaysError,
    isLoading: holidaysLoading
  } = useGetHolidaysDataQuery({
    employeeID: user().employeeID,
    auth: auth()
  })
  const handleInfoToolTipOpen = () => {
    setInfoToolTipOpen(true)
  }

  const handleInfoTooltipClose = () => {
    setInfoToolTipOpen(false)
  }

  const handleLegendToolTipOpen = () => {
    setLegendToolTipOpen(true)
  }

  const handleLegendTooltipClose = () => {
    setLegendToolTipOpen(false)
  }

  const handleRefreshTable = newState => {
    setRefreshTable(newState)
  }

  const fetchAttendanceData = async () => {
    setAttendanceData(data)
    setHolidays(holidaylist)
    const isTodayAHoliday = Boolean(
      holidaylist.length > 0 &&
        holidaylist.some(hol =>
          dayjs(dayjs(hol.holiday).toDate()).isSame(
            dayjs(today).toDate(),
            'day'
          )
        )
    )
    markTodayAttendance(data, isTodayAHoliday)
    tileLogic(
      data,
      holidaylist,
      today,
      isTabletOrMobile,
      handleTodayChangeClick,
      handleHolidayClick,
      handleTodayClick,
      handleLeaveClick,
      handleYesterdayClick,
      handleTodayChangeClick
    )
  }

  const markTodayAttendance = (attdata, isTodayAHoliday) => {
    if (
      attdata.length > 0 &&
      !isTodayAHoliday &&
      !attdata.some(
        attendance =>
          dayjs(today).day() % 6 !== 0 &&
          dayjs(dayjs(attendance.date).toDate()).isSame(dayjs(today), 'day')
      )
    ) {
      handleTodayClick(today)
    }
  }

  const handleTodayChangeClick = date => {
    if (isToday(date)) {
      setSelectedMissedDate(today)
      setTodayChangeDialog(true)
    }
  }
  const closeTodayChangeDialog = () => {
    setTodayChangeDialog(false)
  }

  const handleTodayClick = date => {
    if (
      !isHoliday(date) &&
      !isWeekend(date) &&
      isToday(date) &&
      !isPresent(date)
    ) {
      setDialogOpen(true)
      setDialogType('today')
      setSelectedMissedDate(today)
    }
  }

  const handleYesterdayClick = date => {
    setDialogOpen(true)
    setSelectedMissedDate(date)
    setDialogType('missed')
  }

  const handleHolidayClick = date => {
    if (isToday(date) || isPast(date)) {
      setDialogOpen(true)
      setSelectedMissedDate(date)
      setDialogType('holiday')
    }
  }
  const closeDialog = () => {
    setDialogOpen(false)
  }

  const handleLeaveClick = date => {
    setSelectedMissedDate(date)
    setLeaveFormClick(true)
  }
  const closeLeaveForm = () => {
    setLeaveFormClick(false)
  }

  const {
    isWeekend,
    isHoliday,
    isToday,
    isPresent,
    isPast,
    tileContent,
    tileClassName,
    isLeave,
    isAlreadyMarked
  } = tileLogic(
    attendanceData,
    holidaylist,
    today,
    isTabletOrMobile,
    handleTodayChangeClick,
    handleHolidayClick,
    handleTodayClick,
    handleLeaveClick,
    handleYesterdayClick,
    handleTodayChangeClick
  )

  useEffect(() => {
    if (refreshTable) {
      setRefreshTable(false)
      fetchAttendanceData()
    } else {
      fetchAttendanceData()
    }
  }, [refreshTable, isLoading, holidaysLoading,attendanceData])

  if (isError)
    return (
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '100vh'
        }}
      >
        Error occurred
      </div>
    )
  if (isLoading && holidaysLoading)
    return (
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '100vh'
        }}
      >
        <CircularProgress />
      </div>
    )
  return (
    <Grid
      container
      style={{
        border: '0.75rem solid #e5f4fc',
        width: '90vw',
        height: isTabletOrMobile ? 'fit-content' : 'min-content',
        margin: '0 auto 0 auto',
        padding: '0.75%'
      }}
      spacing={0}
      columns={40}
      direction={isTabletOrMobile ? 'row' : 'row-reverse'}
    >
      <Grid
        item
        xs={40}
        sm={40}
        md={2}
        lg={2}
        xl={2}
        sx={{ float: isTabletOrMobile && 'right' }}
      >
        <CalenderLegendTooltip
          handleTooltipClose={handleLegendTooltipClose}
          legendToolTipOpen={legendToolTipOpen}
          handleLegendToolTipOpen={handleLegendToolTipOpen}
        />
      </Grid>
      <Grid item xs={40} sm={40} md={38} lg={38} xl={38}>
        <Calendar
          onChange={setDate}
          value={date}
          defaultView='month'
          tileContent={tileContent}
          minDetail='year'
          minDate={dayjs(
            dayjs().subtract(1, 'year').startOf('year').month(9).date(1)
          ).toDate()}
          locale='en-US'
          tileClassName={tileClassName}
          formatShortWeekday={(locale, date) =>
            isTabletOrMobile
              ? date.toLocaleDateString(locale, { weekday: 'short' })
              : date.toLocaleDateString(locale, { weekday: 'long' })
          }
          navigationLabel={({ date }) => (
            <>
              {date.toLocaleString('default', { month: 'long' }) + ' '}
              {date.getFullYear()}
              <Tooltip
                title={<WorkAndLeaveInfo thisMonthDate={date} />}
                style={{ float: 'right', marginRight: '2%' }}
                onClose={handleInfoTooltipClose}
                open={infoToolTipOpen}
              >
                <InfoOutlined
                  color='action'
                  onClick={handleInfoToolTipOpen}
                  onMouseEnter={handleInfoToolTipOpen}
                />
              </Tooltip>
            </>
          )}
        />
      </Grid>

      <ConslidatedDialog
        dialogType={dialogType}
        open={isDialogOpen}
        handleClose={closeDialog}
        date={selectedMissedDate}
        handleRefreshTable={handleRefreshTable}
      />
      <ApplyLeaves
        open={leaveFormClick}
        handleClose={closeLeaveForm}
        date={selectedMissedDate}
        setRefreshTable={handleRefreshTable}
      />
      {attendanceData != null &&
        isToday(selectedMissedDate) &&
        (isLeave(selectedMissedDate) || isPresent(selectedMissedDate)) && (
          <TodayChangeDialog
            open={todayChangeDialog}
            handleClose={closeTodayChangeDialog}
            date={selectedMissedDate}
            attendanceDetails={attendanceData.find(({ date }) =>
              dayjs(dayjs(date).toDate()).isSame(dayjs(today).toDate(), 'day')
            )}
            handleRefreshTable={handleRefreshTable}
          />
        )}
    </Grid>
  )
}
