import * as React from 'react'
import PubSub from 'pubsub-js'
import { NavLink, useLocation } from 'react-router-dom'
import {
  Avatar,
  IconButton,
  Popover,
  AppBar,
  List,
  ListItem,
  ListItemButton,
  Toolbar,
  CssBaseline,
  Box,
  Drawer,
  Tooltip
} from '@mui/material'
import Profile from './Profile'
import { useMediaQuery } from 'react-responsive'
import {
  AdminPanelSettings,
  BackupTableOutlined,
  CalendarMonthOutlined,
  Checklist,
  DashboardOutlined,
  DevicesOtherOutlined,
  History,
  ImportantDevicesOutlined,
  Menu as MenuIcon,
  PeopleAltOutlined,
  PunchClock,
  SettingsApplicationsOutlined,
  WorkOff
} from '@mui/icons-material'
import { useState, useEffect } from 'react'
import { useAuthHeader, useAuthUser, useIsAuthenticated } from 'react-auth-kit'
import ScrollAlert from './ScrollAlert'
import { profilePictureOfUser } from '../../service/userEditDetailsService'
const drawerWidth = '5vw'

export default function Sidebar () {
  const [anchorEl, setAnchorEl] = useState(null)
  const [value, setValue] = useState(0)
  const [modules, setModules] = useState([])
  const isAuthenticated = useIsAuthenticated()
  const user = useAuthUser()
  const [mobileOpen, setMobileOpen] = useState(false)
  const location = useLocation()
  const [imageUrl, setImageUrl] = useState('')
  const auth = useAuthHeader()
  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen)
  }
  const isMobile = useMediaQuery({ query: '(max-width: 1224px)' })

  useEffect(() => {
    if (isAuthenticated() && user() && user().modules) {
      setModules(user().modules.map(p => p.name))
      getProfilePicAndSet()
    }
  }, [isAuthenticated(), user()])

  useEffect(() => {
    let token = PubSub.subscribe('profilePictureUpdate', (msg, data) => {
      console.log('Profile pic update')
      if (data.data === 'refresh') {
        getProfilePicAndSet()
      }
    })

    return () => {
      PubSub.unsubscribe(token)
    }
  }, [])

  const handleProfileClick = event => {
    setAnchorEl(event.currentTarget)
  }

  const handleProfileClose = () => {
    setAnchorEl(null)
  }

  const handleChange = (event, newValue) => {
    setValue(newValue)
  }
  const isActiveRoute = route => {
    return location.pathname === route
  }
  const listOfLinks = [
    {
      ind: 1,
      to: '/dashboard',
      name: 'DASHBOARD',
      keepHidden: false,
      icon: <DashboardOutlined />
    },
    {
      ind: 2,
      to: '/home',
      name: 'ATTENDANCE',
      keepHidden: false,
      icon: <CalendarMonthOutlined />
    },
    {
      ind: 3,
      to: '/leaves',
      name: 'LEAVES',
      keepHidden: false,
      icon: <WorkOff />
    },
    {
      ind: 10,
      to: '/employeetimesheet',
      name: 'TIMESHEET',
      keepHidden: !(modules && modules.includes('TIMESHEET_USER_MODULE')),
      icon: <PunchClock />
    },
    {
      ind: 12,
      to: '/userassets',
      name: 'ASSETS',
      keepHidden: !(modules && modules.includes('DEVICEASSET_USER_MODULE')),
      icon: <DevicesOtherOutlined />
    },
    {
      ind: 4,
      to: '/managerdetails',
      name: 'APPROVALS',
      keepHidden: !(modules && modules.includes('APPROVALS_MODULE')),
      icon: <Checklist />
    },
    {
      ind: 5,
      to: '/userlist',
      name: 'USERS',
      keepHidden: !(modules && modules.includes('USERS_MODULE')),
      icon: <PeopleAltOutlined />
    },
    {
      ind: 6,
      to: '/attendancereport',
      name: 'REPORTS',
      keepHidden: !(modules && modules.includes('REPORTS_MODULE')),
      icon: <BackupTableOutlined />
    },
    {
      ind: 7,
      to: '/admincontrols',
      name: 'ADMIN CONTROL',
      keepHidden: !(modules && modules.includes('ADMINCONTROLS_MODULE')),
      icon: <SettingsApplicationsOutlined />
    },
    {
      ind: 8,
      to: '/managerhistory',
      name: 'HISTORY',
      keepHidden: !(modules && modules.includes('APPROVALS_MODULE')),
      icon: <History />
    },
    {
      ind: 9,
      to: '/adminoverride',
      name: 'ADMIN OVERRIDE',
      keepHidden: !(modules && modules.includes('ADMINCONTROLS_MODULE')),
      icon: <AdminPanelSettings />
    },
    {
      ind: 11,
      to: '/adminassets',
      name: 'ADMIN ASSETS',
      keepHidden: !(modules && modules.includes('DEVICEASSET_ADMIN_MODULE')),
      icon: <ImportantDevicesOutlined />
    }
  ]

  return (
    <Box sx={{ visibility: 'visible', display: 'flex' }}>
      <CssBaseline />
      <IconButton
        color='inherit'
        aria-label='open drawer'
        edge='start'
        onClick={handleDrawerToggle}
        sx={{ ml: 1, display: { md: 'none' } }}
      >
        <MenuIcon />
      </IconButton>
      <AppBar
        position='relative'
        elevation={0}
        sx={{
          width: { sm: `calc(100% - ${drawerWidth}%)` },
          ml: { sm: `${drawerWidth}%` }
        }}
      >
        <Toolbar>
          <ListItem className='div-rightstyle'>
            <IconButton onClick={handleProfileClick}>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center'
                }}
              >
                <Avatar
                  src={imageUrl}
                  sx={{
                    color: 'teal',
                    backgroundColor: 'white',
                    border: '3px solid #77b8b5'
                  }}
                />
              </div>
            </IconButton>
            <Popover
              open={Boolean(anchorEl)}
              anchorEl={anchorEl}
              onClose={handleProfileClose}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center'
              }}
            >
              <Profile />
            </Popover>
            {/* <NotificationBell /> */}
            <ScrollAlert />
          </ListItem>
        </Toolbar>
      </AppBar>
      <Drawer
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          flexShrink: 0,
          '& .MuiDrawer-paper': {
            width: isMobile ? 'min-content' : drawerWidth,
            boxSizing: 'border-box',
            backgroundColor: '#bef3f1'
          }
        }}
        variant={isMobile ? 'temporary' : 'permanent'}
        open={isMobile ? mobileOpen : true}
        onClose={handleDrawerToggle}
        anchor='left'
      >
        <List>
          <ListItem
            sx={{
              textAlign: 'unset',
              paddingLeft: 0,
              paddingRight: 0,
              paddingBottom: '5%'
            }}
          >
            <a href='/home'>
              <img src='BEQI LOGO.png' alt='BQ' height='127%' width='95%' />
            </a>
          </ListItem>
        </List>
        <List className='div-centerstyle'>
          {listOfLinks.map(item => {
            return item.keepHidden ? null : (
              <ListItemButton
                key={item.ind}
                component={NavLink}
                to={item.to}
                selected={isActiveRoute(item.to)}
                style={
                  isActiveRoute(item.to) ? { backgroundColor: '#77b8b5' } : null
                }
              >
                <Tooltip title={item.name} placement='right-end' arrow>
                  <IconButton sx={{ padding: '16% 8% 16% 8%' }}>
                    {item.icon}
                  </IconButton>
                </Tooltip>
              </ListItemButton>
            )
          })}
        </List>
      </Drawer>
    </Box>
  )

  async function getProfilePicAndSet () {
    await profilePictureOfUser(user().employeeID, auth())
      .then(r => (r != null ? setImageUrl(r) : setImageUrl(null)))
      .catch(error => console.error('Error fetching image:', error))
  }
}
