import { Typography } from '@mui/material'
import React, { useState, useEffect } from 'react'
import { useAuthHeader, useAuthUser, useIsAuthenticated } from 'react-auth-kit'
import { fetchItems } from '../../service/InterfaceFunctions/fetchItems'
import Marquee from 'react-fast-marquee'
export default function ScrollAlert () {
  const [alertMessage, setAlertMessage] = useState([])
  const [alertText, setAlertText] = useState(null)
  const user = useAuthUser()
  const auth = useAuthHeader()
  const isAuthenticated = useIsAuthenticated()
  async function fetchData () {
    try {
      await fetchItems('alertmessage',auth()).then(result => {
        if (result != null) {
          console.log('result not null')
          setAlertMessage(result)
          setAlertText(result.message)
        }
      })
    } catch (error) {
      console.error(error)
    }
  }
  useEffect(() => {
    if (user() && isAuthenticated()) {
      fetchData()
    }
  }, [isAuthenticated()])

  return !alertMessage.length && alertText!=null ? (
    <Marquee pauseOnHover pauseOnClick style={{ backgroundColor:'#85eeea' }}>
        <Typography variant='h6' minWidth={'100%'} color={'red'} >
          {alertText}
        </Typography>
    </Marquee>
  ) : null
}
