import React, { useState, useEffect } from 'react'
import { useGridApiRef } from '@mui/x-data-grid'
import { Typography, Button, IconButton } from '@mui/material'
import TableComponent from '../../TableComponents'
import { fetchHolidaysForList } from '../../../service/holidayListService'
import { RemoveRedEye, SwapHoriz } from '@mui/icons-material'
import CreateHolidayListForm from '../AdminCreateForms/CreateHolidayListForm'
import CreateHolidayForm from '../AdminCreateForms/CreateHolidayForm'
import dayjs from 'dayjs'
import timezone from 'dayjs/plugin/timezone'
dayjs.extend(timezone)

export default function HolidayListTable () {
  const itemID = 'calenderID'
  const itemName = 'calender'
  const [refreshTable, setRefreshTable] = useState(false)
  const apiRef = useGridApiRef()
  const apiRef2 = useGridApiRef()
  const [isHolidayView, setIsHolidayView] = useState(false)
  const [formOpen, setFormOpen] = useState(false)
  const [formOpen2, setFormOpen2] = useState(false)
  const [holidayListRefresh, setHolidayListRefresh] = useState(false)
  const [selectedListName, setSelectedListName] = useState(null)
  const [selectedListID, setSelectedListID] = useState(null)



  useEffect(() => {
    setRefreshTable(false)
    setHolidayListRefresh(false)
  }, [refreshTable,holidayListRefresh])


  const handleClose = () => {
    setFormOpen(false)
  }
  const handleClose2 = () => {
    setFormOpen2(false)
  }

  const holidayscolumns = [
    {
      field: 'holiday',
      headerName: 'Date',
      type: 'date',
       valueGetter: (value) =>
        value !== null ? dayjs(value).tz('Asia/Kolkata').toDate() : null,
      flex: 2,
      editable: true
    },
    {
      field: 'occassion',
      headerName: 'Occassion',
      flex: 2,
      editable: true
    },
    {
      field: 'day',
      headerName: 'WeekDay',
      flex: 2,
      editable: true
    }
  ]

  const holidaylistcolumns = [
    {
      field: 'listName',
      headerName: 'Name',
      flex: 2,
      editable: true
    },
    {
      field: 'listDescription',
      headerName: 'Description',
      flex: 2,
      editable: true
    },
    {
      field: 'view',
      headerName: 'View',
      renderCell: params => (
        <Button
          onClick={() =>
            handleToggleChange(
              apiRef2.current.getCellValue(params.id, 'listName'),
              params,
              'holidays'
            )
          }
        >
          <RemoveRedEye />
        </Button>
      ),
      flex: 0.5,
      filterable: false,
      sortable: false
    }
  ]

  const handleToggleChange = (listname, params, selectedValue) => {
    if (selectedValue === 'holidays') {
      setSelectedListName(listname)
      setSelectedListID(params.id)
      setIsHolidayView(true)
      setRefreshTable(true)
    } else {
      setIsHolidayView(false)
      setHolidayListRefresh(true)
    }
  }
  return (
    <>
      <div>
        <div className='div-spaceToSides'>
          <>
            <Typography variant='h4'>
              {isHolidayView
                ? 'Holidays of List ' + selectedListName
                : 'Holiday Lists'}
              {isHolidayView ? (
                <IconButton
                  onClick={() => handleToggleChange(null, null, 'list')}
                >
                  <SwapHoriz fontSize='large' />
                </IconButton>
              ) : null}
            </Typography>
          </>
          {isHolidayView ? (
            <Button
              variant='filled'
              className='button-gradient'
              onClick={() => setFormOpen2(true)}
            >
              +Add new holiday
            </Button>
          ) : (
            <Button
              variant='filled'
              className='button-gradient'
              onClick={() => setFormOpen(true)}
            >
              +Add new holiday list
            </Button>
          )}
        </div>
        {isHolidayView ? (
          <TableComponent
            key={'holidays'}
            refreshTable={refreshTable}
            intState={{
              sorting: {
                sortModel: [{ field: 'holiday', sort: 'asc' }]
              }
            }}            
            apiRef={apiRef}
            itemID={itemID}
            itemName={itemName}
            columns={holidayscolumns}
            differentFetchFuction={fetchHolidaysForList}
            fetchFunctionParametes={selectedListName}
          />
        ) : (
          <TableComponent
            key={'list'}
            refreshTable={holidayListRefresh}
            apiRef={apiRef2}
            itemID={'holidayListID'}
            itemName={'holidaylist'}
            columns={holidaylistcolumns}
          />
        )}
        <CreateHolidayForm
          open={formOpen2}
          handleClose={handleClose2}
          setRefreshTable={setRefreshTable}
          holidayListID={selectedListID}
        />
        <CreateHolidayListForm
          open={formOpen}
          handleClose={handleClose}
          setRefreshTable={setHolidayListRefresh}
        />
      </div>
    </>
  )
}
