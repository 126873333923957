import React from 'react'

export default function Footer () {
  return (
    <footer
      style={{
        backgroundColor: 'white',
        textAlign: 'center',
        width: '70%',
        bottom:'30%',
        position:'relative',
        padding: '10px 0',
        marginTop:'5%',
        marginLeft:'20%',
        color: 'gray'
      }}
    >
      © BeQisoft. All rights reserved.
    </footer>
  )
}
