/*For use of BeQiSoft Pvt Ltd. */

import React, { useState } from 'react'
import { Button, FormControl, InputLabel } from '@mui/material'
import { DatePicker } from '@mui/x-date-pickers'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import GenericSelectCell from '../Admin/AdminSelectCells/GenericSelectCell'
import dayjs from 'dayjs'
import { useAuthUser } from 'react-auth-kit'
import { fetchShortUserInfo } from '../../service/addUserService'

export default function TimesheetReportFilters ({ onGenerateReport }) {
  const [monthDate, setStartDate] = useState(dayjs(new Date()))
  const [team, setTeam] = useState(null)
  const [employeeID, setEmpID] = useState(null)
  const [emptyValues, setEmptyValues] = useState(false)

  const user = useAuthUser()
  const today = new Date()
  const handleSubmitReport = async e => {
    e.preventDefault()
    if (
      monthDate != null &&
      onGenerateReport != null &&
      (team != null || employeeID != null)
    ) {
      setEmptyValues(false)
      onGenerateReport({
        monthDate: dayjs(monthDate).format('YYYY-MM-DD'),
        employeeID,
        team
      })
    } else {
      setEmptyValues(true)
      console.log('Set values first')
    }
  }

  return (
    <form noValidate>
      <p>Note: Either team or employee must be selected to generate a report</p>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <div>
          <DatePicker
            format='MM'
            views={['month']}
            label='Month'
            value={monthDate}
            minDate={dayjs(today).subtract(1, 'months')}
            maxDate={dayjs(today)}
            onChange={newVal => setStartDate(dayjs(newVal))}
          />
        </div>
      </LocalizationProvider>
      {!user().roles.find(r => r == 'TEAMLEAD') && (
        <FormControl fullWidth style={{ margin: '20px auto' }} error={emptyValues}>
          <InputLabel id='team-select-label' >Team</InputLabel>
          <GenericSelectCell
            value={team}
            label={'Team'}
            key={'teamNo'}
            keyvalue={'teamID'}
            itemName={'team'}
            onChange={e => setTeam(e)}
          />
        </FormControl>
      )}
      <FormControl fullWidth style={{ margin: '20px auto' }} error={emptyValues} required={user().roles.find(r => r == 'TEAMLEAD')}>
        <InputLabel id='employee-select-label'>Employee</InputLabel>
        <GenericSelectCell
          label={'Employee'}
          value={employeeID}
          key={'employeeID'}
          keyvalue={'employeeID'}
          itemName={'user'}
          fetchData={fetchShortUserInfo}
          extra={['name', 'lname']}
          onChange={newValue => setEmpID(newValue)}
        />
      </FormControl>
      <Button
        variant='contained'
        className='button-gradient'
        onClick={handleSubmitReport}
      >
        Generate Timesheet
      </Button>
    </form>
  )
}
