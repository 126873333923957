/*For use of BeQiSoft Pvt Ltd. */

import React, { useState } from 'react'
import { Button, FormControl, InputLabel, Select } from '@mui/material'
import { DatePicker } from '@mui/x-date-pickers'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import GenericSelectCell from '../AdminSelectCells/GenericSelectCell'
import dayjs from 'dayjs'
import { ArrowForward } from '@mui/icons-material'
import ShiftSelect from '../AdminSelectCells/ShiftSelect'
import DepartmentSelect from '../AdminSelectCells/DepartmentSelect'
import TeamMultiSelect from '../AdminSelectCells/TeamMultiSelect'

export default function AttendanceOverrideFilterPage ({ onGenerateReport }) {
  const [startDate, setStartDate] = useState(dayjs(new Date()))
  const [endDate, setEndDate] = useState(dayjs(new Date()))
  const [department, setDepartment] = useState(null)
  const [departmentList, setDepartmentList] = useState([])
  const [team, setTeam] = useState(null)
  const [teamList, setTeamList] = useState([])
  const [shiftList, setShiftList] = useState([])
  const [shift, setShift] = useState()
  const [employee_id, setEmployee_id] = useState(null)
  const [project, setProject] = useState(null)

  const handleSubmitReport = async e => {
    e.preventDefault()
    if (startDate != null && endDate != null && onGenerateReport!=null) {
      onGenerateReport({
        startDate,
        endDate,
        department,
        team,
        shift,
        employee_id
      })
    }
  }


  return (
    <form noValidate>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <div>
          <DatePicker
            format='DD/MM/YYYY'
            label='Start date'
            value={startDate}
            onChange={newVal => setStartDate(dayjs(newVal))}
          />
          <ArrowForward style={{ margin: '1%' }} />
          <DatePicker
            format='DD/MM/YYYY'
            label='End date'
            value={endDate}
            onChange={newVal => setEndDate(dayjs(newVal))}
            minDate={startDate}
          />
        </div>
      </LocalizationProvider>
      <FormControl fullWidth style={{ margin: '20px auto' }}>
        <InputLabel id='department-select-label'>Department</InputLabel>
        <DepartmentSelect
          value={departmentList}
          onChange={newVal => {
            setDepartmentList(newVal)
            setDepartment(newVal.map(d => d.departmentName).join(','))
          }}
        />
      </FormControl>
      <FormControl fullWidth style={{ margin: '20px auto' }}>
        <InputLabel id='team-select-label'>Team</InputLabel>
        <TeamMultiSelect
          value={teamList}
          onChange={newVal => {
            setTeamList(newVal)
            setTeam(newVal.map(d => d.teamID).join(','))
          }}
        />
      </FormControl>
      <FormControl fullWidth style={{ margin: '20px auto' }}>
        <InputLabel id='shift-select-label'>Shift</InputLabel>
        <ShiftSelect
          value={shiftList}
          onChange={newVal => {
            setShiftList(newVal)
            setShift(newVal.map(shi => shi.shiftName).join(','))
          }}
        />
      </FormControl>
      <FormControl fullWidth style={{ margin: '20px auto' }}>
        <InputLabel id='employee-select-label'>Employee</InputLabel>
        <GenericSelectCell
          label={'Employee'}
          value={employee_id}
          key={'employeeID'}
          keyvalue={'employeeID'}
          itemName={'user'}
          extra={['name', 'lname']}
          onChange={newValue => setEmployee_id(newValue)}
        />
      </FormControl>
      <Button
        variant='contained'
        className='button-gradient'
        onClick={handleSubmitReport}
      >
        Fetch Attendance
      </Button>

    </form>
  )
}
