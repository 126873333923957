import { CancelOutlined } from '@mui/icons-material'
import {
  Button,
  Card,
  Dialog,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  IconButton,
  InputLabel,
  RadioGroup,
  TextField,
  Radio,
  Typography,
  Backdrop,
  CircularProgress
} from '@mui/material'
import { useEffect, useState } from 'react'
import React from 'react'
import { useAuthUser } from 'react-auth-kit'
import RenderDeviceImageEditor from '../Admin/AdminCreateForms/RenderDeviceImageEditor'
import GenericSelectCell from '../Admin/AdminSelectCells/GenericSelectCell'
import { fetchShortUserInfo } from '../../service/addUserService'
import {
  repairOrSurrender,
  transferDeviceFromSender
} from '../../service/deviceAssetService'
import DamageCounter from '../Admin/AdminCreateForms/DamageCounter'

export default function DeviceRepairOrSurrenderForm ({
  deviceIDF,
  deviceAssetID,
  deviceDamagesSet,
  open,
  setRefreshTable,
  handleClose,
  alert,
  status
}) {
  const user = useAuthUser()
  const [transferee, setTransferee] = useState(null)
  const [comments, setComments] = useState(null)
  const [imageUploader, setImageUploader] = useState(false)
  const [uploadImages, setUploadImages] = useState([])
  const [newDamages, setNewDamages] = useState([])
  const [transferWithDamage, setTransferWithDamage] = useState('NODAMAGE')
  const [mandatoryIsEmpty, setMandatorIsEmpty] = useState(false)
  const [progress, setProgress] = useState(false)

  useEffect(() => {
    resetState()
  }, [open, transferWithDamage])

  const resetState = () => {
    setMandatorIsEmpty(false)
    setImageUploader(false)
    setUploadImages([])
    setNewDamages([])
    setComments(null)
    setProgress(false)
  }
  const handleImageUploaderClose = () => {
    setImageUploader(false)
  }
  function transformData (data) {
    return data.map(item => {
      return {
        [item.damage]: item.damageCount
      }
    })
  }
  const handleSubmit = async e => {
    e.preventDefault()

    setProgress(true)
    if (
      uploadImages.length == 2 &&
      (transferWithDamage != 'NODAMAGE'
        ? Boolean(comments) && newDamages.length > 0
        : true)
    ) {
      const deviceAssetTransferTracker = {
        transferID: null,
        deviceIDF,
        transferrer: user().employeeID,
        comments,
        newDamages: newDamages.length < 1 ? null : transformData(newDamages),
        status
      }
      try {
        const submitDevice = await repairOrSurrender(
          deviceAssetTransferTracker,
          uploadImages,
          status
        )
        const res = await submitDevice.text()
        alert.info(res)
      } catch (error) {
        setProgress(false)
        alert.error('Error occurred while submitting')
      } finally {
        setProgress(false)
        setRefreshTable(true)
        handleClose()
      }
    } else {
      setMandatorIsEmpty(true)
      alert.error('Mandatory fields not filled')
      setProgress(false)
    }
  }

  return (
    <Dialog open={open} keepMounted={false}>
      <DialogTitle>
        {status>=-2?`Send Device ${deviceAssetID} For Repair`:`Surrender Device ${deviceAssetID}`}
        <IconButton sx={{ float: 'right' }} onClick={handleClose}>
          <CancelOutlined />
        </IconButton>
        <DialogContent sx={{ width: '100%' }}>
          <FormControl required fullWidth style={{ margin: '20px auto' }}>
            <FormControlLabel
              label='Transfer Type: '
              labelPlacement='start'
              control={
                <RadioGroup
                  row
                  value={transferWithDamage}
                  onChange={e => setTransferWithDamage(e.target.value)}
                >
                  <FormControlLabel
                    value='DAMAGE'
                    control={<Radio />}
                    label='With Damage'
                  />
                  <FormControlLabel
                    value='NODAMAGE'
                    control={<Radio />}
                    label='Without Damage'
                  />
                </RadioGroup>
              }
            ></FormControlLabel>
          </FormControl>
          <div style={{ boxShadow: '3px 2px 8px 1px rgba(0,0,0,0.3)' }}>
            <Typography variant='body1'>Known Damages</Typography>
            <Typography variant='body2'>
              <ul>
                {deviceDamagesSet?.map(param => (
                  <li> {param.damage + ': ' + param.damageCount}</li>
                ))}
              </ul>
            </Typography>
          </div>
          <form>
            {transferWithDamage == 'DAMAGE' ? (
              <FormControl
                key={transferWithDamage}
                style={{ margin: '20px auto' }}
              >
                <Card
                  style={{
                    margin: '20px auto'
                  }}
                >
                  <Typography variant='body1'>New Damages</Typography>
                  <Typography variant='caption'>
                    Note: These are new damages accurred with a count that will
                    add to the count of known damages
                  </Typography>

                  <DamageCounter
                    style={{ margin: '20px auto' }}
                    prevValue={newDamages}
                    deviceIDFk={deviceIDF}
                    open={open}
                    onChange={newD => setNewDamages(newD)}
                    confirmButton={false}
                    additionalError={mandatoryIsEmpty && newDamages.length <= 0}
                  />
                </Card>
              </FormControl>
            ) : null}
            <TextField
              required={transferWithDamage == 'DAMAGE'}
              label='Comments'
              variant='outlined'
              fullWidth
              value={comments}
              error={
                transferWithDamage == 'DAMAGE' && !comments && mandatoryIsEmpty
              }
              onChange={e => setComments(e.target.value.trim())}
              style={{ margin: '20px auto' }}
            />
            <RenderDeviceImageEditor
              images={uploadImages}
              open={imageUploader}
              handleClose={handleImageUploaderClose}
              onChange={newVal => {
                setUploadImages(newVal)
                handleImageUploaderClose()
              }}
              alert={alert}
              setImages={setImageUploader}
            />
            <Button
              onClick={() => {
                setImageUploader(true)
              }}
              variant='outlined'
              color={
                uploadImages.length == 2
                  ? 'success'
                  : mandatoryIsEmpty && uploadImages.length != 2
                  ? 'error'
                  : 'inherit'
              }
              style={{ margin: '20px auto' }}
            >
              Image Upload*
            </Button>
          </form>
          <Button
            onClick={handleSubmit}
            variant='contained'
            className='button-gradient'
            style={{ margin: '20px auto' }}
          >
            Submit
          </Button>
        </DialogContent>
      </DialogTitle>
      <Backdrop
        sx={{ color: '#fff', zIndex: theme => theme.zIndex.drawer + 1 }}
        open={progress}
      >
        <CircularProgress></CircularProgress>
      </Backdrop>
    </Dialog>
  )
}
