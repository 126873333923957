/*For use of BeQiSoft Pvt Ltd. */

import React, { useState, useEffect } from 'react'
import { Select, MenuItem, Checkbox } from '@mui/material'
import { fetchItems } from '../../../service/InterfaceFunctions/fetchItems'

export default function RoleSelect ({ value, onChange }) {
  const [roleList, setRoleList] = useState([])
  const [rolesSelected, setRolesSelected] = useState([])
  useEffect(() => {
    async function fetchRolesList () {
      try {
        await fetchItems('role').then(result => {
          setRoleList(result)
        })
        
      } catch (error) {
        console.error(error)
        setRoleList([])
      }
    }
    fetchRolesList()
  }, [])

  const handleRoleChange = event => {
    const selectedRoleIDs = event.target.value
    const selectedRoles = roleList.filter(role =>
      selectedRoleIDs.includes(role.roleID)
    )
    setRolesSelected(selectedRoles)
    onChange(selectedRoles)
  }
  useEffect(() => {
    if (value && value.length > 0) {
      setRolesSelected(value)
    }
  }, [value])
  return (
    <Select
      multiple
      value={value.map(role => role.roleID)}
      onChange={handleRoleChange}
      renderValue={selectedRoles =>
        selectedRoles
          .map(
            roleID =>
              roleList.find(role => role.roleID === roleID)?.roleName || ''
          )
          .join(', ')
      }
      id='role-select'
      variant='outlined'
      fullWidth
      label='Role'
      MenuProps={{
        anchorOrigin: {
          vertical: 'top',
          horizontal: 'center'
        }
      }}
    >
      {roleList.map(role => (
        <MenuItem key={role.roleID} value={role.roleID}>
          <Checkbox checked={rolesSelected.some(r=>r.roleID===role.roleID)} />
          {role.roleName}
        </MenuItem>
      ))}
    </Select>
  )
}
