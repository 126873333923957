import React, { useState,useEffect } from 'react'
import { useGridApiRef } from '@mui/x-data-grid'
import { Typography } from '@mui/material'
import TableComponent from '../../TableComponents'
import { DataThresholdingOutlined } from '@mui/icons-material'
export default function ThresholdsTable () {
  const apiRef = useGridApiRef()
  const itemName = 'schedulethreshold'
  const itemID = 'thresholdID'
  const [formOpen, setFormOpen] = useState(false)
  const [refreshTable, setRefreshTable] = useState(false)

  const handleRefreshTable = newState => {
    setRefreshTable(newState)
  }
  const handleClose = () => {
    setFormOpen(false)
  }
  
  useEffect(() => {
    setRefreshTable(false)
  }, [refreshTable])
  const columns = [
    {
      field: 'thresholdName',
      headerName: 'Threshold',
      flex: 2
    },
    {
      field: 'thresholdValue',
      headerName: 'Value',
      flex: 1,
      editable: true
    },
    {
      field: 'thresholdUnit',
      headerName: 'Unit',
      flex: 1,
      editable: true
    },
    {
      field: 'thresholdDescription',
      headerName: 'Description',
      flex: 5
    }
  ]
  return (
    <>
      <div>
        <div className='div-spaceToSides'>
          <Typography variant='h4'>
            <DataThresholdingOutlined/>
            Schedule Thresholds
          </Typography>
        </div>
        <TableComponent
          refreshTable={refreshTable}
          apiRef={apiRef}
          itemID={itemID}
          itemName={itemName}
          columns={columns}
        />
      </div>
    </>
  )
}
