/*For use of BeQiSoft Pvt Ltd. */

import React, { useState, useEffect } from 'react'
import TextField from '@mui/material/TextField'
import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  FormControl,
  InputLabel
} from '@mui/material'
import AddIcon from '@mui/icons-material/Add'
import { CalendarViewDay, CancelOutlined } from '@mui/icons-material'
import { DatePicker } from '@mui/x-date-pickers'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { addHoliday } from '../../../service/calenderService'
import dayjs from 'dayjs'
import { utc } from 'dayjs'
import timezone from 'dayjs/plugin/timezone'
dayjs.extend(timezone)
dayjs.extend(utc)
export default function CreateHolidayForm ({
  open,
  handleClose,
  setRefreshTable,
  holidayListID
}) {
  const [holiday, setHoliday] = useState(null)
  const [occassion, setOccassion] = useState(null)
  const [day, setDay] = useState(null)
  const [attemptedToSubmitWithError, setAttemptedToSubmitWithError] =
    useState(false)

  const resetState = () => {
    setAttemptedToSubmitWithError(false)
    setHoliday(null)
    setOccassion(null)
    setDay(null)
  }
  useEffect(() => {
    if (open) {
      resetState()
    }
  }, [open])
  const handleFormClose = () => {
    setHoliday('')
    setOccassion(null)
    setDay('')
    handleClose()
  }
  const handleSubmit = async e => {
    e.preventDefault()
    const holidayData = {
      holidayListID,
      holiday: dayjs(holiday).toDate(),
      occassion,
      day
    }
    if (holiday != null && occassion != null) {
      try {
        setAttemptedToSubmitWithError(false)
        console.log('Holiday list Creating')
        addHoliday(holidayData)
      } catch (error) {
        console.log(error)
      } finally {
        setTimeout(() => {}, 1000)
        setRefreshTable(true)
        handleFormClose()
      }
    } else {
      console.log('creation Error')
      setAttemptedToSubmitWithError(true)
    }
  }

  return (
    <Dialog open={open}>
      <DialogTitle>
        <CalendarViewDay /> Add holiday to list
        <IconButton onClick={handleFormClose} sx={{ float: 'right' }}>
          <CancelOutlined />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <form noValidate>
          <FormControl
            required
            fullWidth
            style={{ margin: '20px auto' }}
            error={attemptedToSubmitWithError && !holiday}
          >
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                format='DD/MM/YYYY'
                label='Holiday Date *'
                value={dayjs(holiday)}
                onChange={newVal => {
                  setHoliday(dayjs(newVal).toDate())
                  setDay(dayjs(newVal).toDate().toDateString().split(' ')[0])
                }}
                slotProps={{
                  textField: {
                    error: attemptedToSubmitWithError && !holiday
                  }
                }}
              />
            </LocalizationProvider>
          </FormControl>
          <TextField
            required
            id='occassion-id'
            label='Occassion'
            variant='outlined'
            fullWidth
            multiline
            value={occassion}
            onChange={e => setOccassion(e.target.value)}
            error={attemptedToSubmitWithError && !occassion}
            style={{ margin: '20px auto' }}
          />
          <FormControl required fullWidth style={{ margin: '20px auto' }}>
            <InputLabel>Day</InputLabel>
            <TextField
              required
              variant='filled'
              fullWidth
              value={day}
              error={attemptedToSubmitWithError && !day}
              style={{ margin: '20px auto' }}
            />
          </FormControl>
          <Button
            variant='contained'
            className='button-gradient'
            onClick={handleSubmit}
          >
            <AddIcon />
            Add Holiday
          </Button>
        </form>
      </DialogContent>
    </Dialog>
  )
}
