/*For use of BeQiSoft Pvt Ltd. */

import React, { useState, useEffect } from 'react'
import TextField from '@mui/material/TextField'
import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton
} from '@mui/material'
import AddIcon from '@mui/icons-material/Add'
import { addItem } from '../../../service/InterfaceFunctions/addItem'
import { CancelOutlined } from '@mui/icons-material'
import { Formik } from 'formik'
import * as yup from 'yup'

export default function CreateTeamForm ({ open, handleClose, setRefreshTable }) {

  const handleValueSubmit = async e => {
    if (e!= null) {
      try {
        addItem('team', e)
      } catch (error) {
        console.log(error)
      } finally {
        setRefreshTable(true)
        handleClose()
      }
    } else {
      alert.show("Error adding team")
    }
  }

  return (
    <Dialog open={open}>
      <DialogTitle>
        Create Team
        <IconButton onClick={handleClose} sx={{ float: 'right' }}>
          <CancelOutlined />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <Formik
          initialValues={{
            teamID: null
          }}
          onSubmit={values => handleValueSubmit(values)}
          validationSchema={yup.object({
            teamID: yup.string().min(1).required('Required')
          })}
        >
          {props => {
            const {
              values,
              touched,
              errors,
              handleChange,
              handleSubmit,
              setFieldValue,
              isValid,
              submitCount
            } = props
            return (
              <form noValidate onSubmit={handleSubmit}>
                <TextField
                  required
                  label='Team Name'
                  variant='outlined'
                  fullWidth
                  value={values.teamID}
                  onChange={handleChange('teamID')}
                  style={{ margin: '20px auto' }}
                  error={touched.teamID && errors.teamID}
                />
                {!isValid && submitCount > 0 && (
                  <div style={{ color: 'red', fontStyle: 'bold' }}>
                    Some fields may be empty or invalid
                  </div>
                )}
                <Button
                  variant='contained'
                  className='button-gradient'
                  type='submit'
                >
                  <AddIcon />
                  Add Team
                </Button>
              </form>
            )
          }}
        </Formik>
      </DialogContent>
    </Dialog>
  )
}
