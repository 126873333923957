import React, { useState } from 'react'
import Button from '@mui/material/Button'
import TextField from '@mui/material/TextField'
import Link from '@mui/material/Link'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import Container from '@mui/material/Container'
import { handleLogin, handleSuccessfulLogin } from '../service/loginservice'
import { useSignIn } from 'react-auth-kit'
import { useNavigate } from 'react-router-dom'
import { useAlert } from 'react-alert'
import { LockOutlined, MailOutlined } from '@mui/icons-material'
import { Backdrop, CircularProgress, InputAdornment } from '@mui/material'
import { useDispatch } from 'react-redux'
export default function LoginForm () {
  const signIn = useSignIn()
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const [progress, setProgress] = useState(false)
  const [formData, setFormData] = useState({ username: '', password: '' })
  const [errorWithLogin, setErrorWithLogin] = useState(false)
  const alert = useAlert()
  const handleSubmit = async event => {
    event.preventDefault()
    try {
      setProgress(true)
      const response = await handleLogin(formData)
      const data = await response.json()
      if (response.ok) {
        handleSuccessfulLogin(data, signIn, dispatch, navigate, alert, setErrorWithLogin)
      } else {
        setErrorWithLogin(true)
        alert.error('Incorrect Credentials. Check username and password')
      }
      setProgress(false)
    } catch (error) {
      setProgress(false)
      setErrorWithLogin(true)
      alert.error('Server connection error')
    }
  }

  return (
    <Container maxWidth='xs' className='loginCard'>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          padding: '5%',
          marginTop: '5%'
        }}
      >
        <img src='BEQIFULLOGO.png' alt='BQ' height='30%' width='50%' />
        <Box component='form' onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
          <TextField
            variant='standard'
            margin='normal'
            type='email'
            fullWidth
            id='email'
            label='Email ID'
            name='username'
            value={formData.username}
            error={errorWithLogin}
            autoFocus
            InputProps={{
              startAdornment: (
                <InputAdornment>
                  <MailOutlined />
                </InputAdornment>
              )
            }}
            onChange={e =>
              setFormData({ ...formData, username: e.target.value })
            }
          />
          <TextField
            variant='standard'
            margin='normal'
            fullWidth
            name='password'
            label='Password'
            type='password'
            id='password'
            value={formData.password}
            error={errorWithLogin}
            InputProps={{
              startAdornment: (
                <InputAdornment>
                  <LockOutlined />
                </InputAdornment>
              )
            }}
            onChange={e =>
              setFormData({ ...formData, password: e.target.value })
            }
          />
          <Button
            type='submit'
            fullWidth
            variant='contained'
            color='secondary'
            sx={{ mt: 3, mb: 2 }}
          >
            Sign In
          </Button>
          <Button
            variant='text'
            onClick={() => {
              navigate('/forgotpassword')
            }}
            sx={{ float: 'right', textTransform: 'none' }}
          >
            <Typography variant='subtitle1' color={'lightblue'}>
              Forgot Password
            </Typography>
          </Button>
        </Box>
      </Box>
      <Copyright sx={{ mt: 8, mb: 4 }} />
      <Backdrop
        sx={{ color: '#fff', zIndex: theme => theme.zIndex.drawer + 1 }}
        open={progress}
      >
        <CircularProgress></CircularProgress>
      </Backdrop>
    </Container>
  )
}

function Copyright (props) {
  return (
    <Typography
      variant='body2'
      color='text.secondary'
      align='center'
      {...props}
    >
      {'Copyright © '}
      <Link color='inherit' href='https://beqisoft.net/'>
        Beqisoft Ltd
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  )
}
