import React, { useState, useEffect } from 'react'
import { getAllAttendanceReports } from '../../service/reportsService'
import { CircularProgress, Backdrop } from '@mui/material'
import { useAuthUser, useIsAuthenticated } from 'react-auth-kit'
import { DataGrid, useGridApiRef } from '@mui/x-data-grid'
import CustomGridToolbarNoAdd from '../Admin/CustomGridToolbarNoAdd'
import dayjs from 'dayjs'
import { dataGridStyles } from '../../stylings/datagridStyles'
export default function AttendanceReport ({
  startDate,
  endDate,
  department,
  team,
  employee_id,
  shift,
  refreshTable
}) {
  const isAuthenticated = useIsAuthenticated()
  const user = useAuthUser()
  const [progress, setProgress] = useState(false)
  const [items, setItems] = useState([])
  const [column, setColumn] = useState([])
  const apiRef = useGridApiRef()
  async function fetchData () {
    try {
      setProgress(true)
      const res = await getAllAttendanceReports({
        startDate: dayjs(startDate).format('YYYY-MM-DD'),
        endDate: dayjs(endDate).format('YYYY-MM-DD'),
        team: team,
        department: department,
        empID: employee_id,
        shift: shift
      })
      const data = await res.json().then((data) => {
        if (data.length > 0) {
          const firstEntry = JSON.parse(data[0].DateStatus)
          const uniqueDates = firstEntry.map(dateStatus => {
            return dateStatus.generatedDate
          })

          const dynamicColumns = uniqueDates.map(date => ({
            field: `Date_${date}`,
            headerName: date,
            width: 400,
            height: 500,
            align: 'center'
          }))

          setColumn([
            {
              field: 'employeeID',
              headerName: 'Employee ID',
              width: '150'
            },
            {
              field: 'name',
              headerName: 'First Name',
              width: '150'
            },
            {
              field: 'lname',
              headerName: 'Last Name',
              width: '150'
            },
            {
              field: 'department',
              headerName: 'Department',
              width: '150'
            },
            {
              field: 'team',
              headerName: 'Team',
              width: '150'
            },
            {
              field: 'shift',
              headerName: 'Shift',
              width: '150'
            },
            {
              field: 'office',
              headerName: 'Office Location',
              width: '150'
            },
            ...dynamicColumns
          ])

          const entryrows = data.map(entry => {
            const erow = { ...entry }
            delete erow.DateStatus
            const dateStatusArray = JSON.parse(entry.DateStatus)
            dateStatusArray.forEach(dateStatus => {
              const parsedDateStatus = dateStatus
              const fieldName = `Date_${parsedDateStatus.generatedDate}`
              if (parsedDateStatus.status.includes('Working day')) {
                const parsedVal = JSON.parse(parsedDateStatus.status)
                let cleanedVal = ''
                for (const key in parsedVal) {
                  cleanedVal += `${key}:${parsedVal[key]} | `
                }
                erow[fieldName] = cleanedVal.trim()
              } else {
                erow[fieldName] = parsedDateStatus.status
              }
            })
            return erow
          })

          setItems(entryrows)
        }
      })
    } catch (error) {
      console.error(error)
      setProgress(false)
    } finally {
      setProgress(false)
    }
  }

  useEffect(() => {
    if (user() && isAuthenticated()) {
      fetchData()
    }
  }, [refreshTable, startDate, endDate, employee_id, department, team])


  return (
 
        <DataGrid
          disableRowSelectionOnClick
          disableVirtualization
          getRowId={row => row['employeeID']}
          loading={progress}
          rows={items}
          columns={column}
          apiRef={apiRef}
          sx={dataGridStyles}
          showCellVerticalBorder
          showColumnVerticalBorder
          autoHeight
          slots={{
            toolbar: CustomGridToolbarNoAdd
          }}
          initialState={{
            pagination: {
              paginationModel: {
                page: 0,
                pageSize: 5
              }
            }
          }}
          pageSizeOptions={[5, 10, 15, 20, 100]}
        />

  )
}
