import Cookies from 'js-cookie'
import { BASE_URL } from './apiService'

export const getTeamManagers = async teamID => {
  try {
    const authToken = Cookies.get('_auth')
    const response = await fetch(BASE_URL + '/team/getManagers', {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${authToken}`
      }
    })
    return response
  } catch (error) {
    console.error(error)
    throw error
  }
}
