import AttendanceRequest from '../components/Manager/AttendanceRequests'
import {
  Container,
  Paper,
  Tabs,
  Tab,
  Typography,
  styled,
  Grid
} from '@mui/material'
import React, { useState } from 'react'
import LeaveRequests from '../components/Manager/LeaveRequests'
import { useAuthUser } from 'react-auth-kit'
import ManagerTimesheet from '../components/Timesheet/ManagerTimeSheet'
import { pageStyle } from '../stylings/pageStyle'
import { CustomTab } from '../stylings/customtabstyle'
export default function ManagerBoard () {
  const [value, setValue] = useState(0)
  const user = useAuthUser()
  const handleChange = (event, newValue) => {
    setValue(newValue)
  }

  return user().modules.find(m => m.name === 'APPROVALS_MODULE') ? (
    <Grid container columns={20} style={pageStyle}>
      <Grid item xs={20} md={20} lg={20} xl={20}>
        <Tabs indicatorColor='secondary' value={value} onChange={handleChange}>
          <CustomTab label={<Typography variant='h6'>Attendance</Typography>} />
          <CustomTab label={<Typography variant='h6'>Leaves</Typography>} />
          <CustomTab label={<Typography variant='h6'>Timesheets</Typography>} />
        </Tabs>
        <Paper className='paperStyle2'>
        {value === 0 && <AttendanceRequest />}
        {value === 1 && <LeaveRequests />}
        {value === 2 && <ManagerTimesheet />}</Paper>
      </Grid>
    </Grid>
  ) : null
}
