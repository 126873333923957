import './App.css'
import Home from './pages/home'
import { Routes, Route, BrowserRouter, Navigate } from 'react-router-dom'
import {
  RequireAuth,
  useAuthUser,
  useIsAuthenticated,
  useSignOut
} from 'react-auth-kit'
import LogInComponent from './pages/login'
import Footer from './components/Scaffolding/Footer'
import UserDetails from './pages/userdetails'
import ManagerBoard from './pages/managerboard'
import Sidebar from './components/Scaffolding/Sidebar'
import Leaves from './pages/leaves'
import AdminUserList from './pages/userlist'
import AdminControls from './pages/admincontrols'
import NoLocationPage from './pages/nolocation'
import DashBoardPage from './pages/dashboard'
import Reports from './pages/reports'
import { React, useEffect } from 'react'
import ForgotPassword from './pages/forgotpassword'
import ManagerHistoryBoard from './pages/managerhistoryboard'
import AdminOverrideBoard from './pages/adminoverride'
import EmployeeTimesheet from './pages/usertimesheet'
import AssetAdminBoard from './pages/adminassetspage'
import AssetUserBoard from './pages/userassetspage'
export default function App () {
  const user = useAuthUser()
  const isAuthenticated = useIsAuthenticated()
  const signOut = useSignOut()
  const tokenExpiration = useAuthUser()
  useEffect(() => {
    if (isAuthenticated()) {
      const expirationTime = Date.now() + tokenExpiration().expiresIn * 1000
      const checkExpiration = () => {
        if (Date.now() >= expirationTime) {
          signOut().then(() => {
            window.location.href = '/login'
          })
        }
      }
      checkExpiration()
      const intervalId = setInterval(checkExpiration, 1000)
      return () => clearInterval(intervalId)
    }
  }, [Date.now()])

  return user() ? (
    <BrowserRouter>
      {Boolean(isAuthenticated()) && <Sidebar />}
      <div
        className={`main-content${isAuthenticated() ? '-with-sidebar' : ''}`}
      >
        <Routes>
          <Route
            path='/'
            element={
              <RequireAuth loginPath={'/login'}>
                <Home />
              </RequireAuth>
            }
          />
          <Route path='/login' element={<LogInComponent />} />
          <Route
            path='/home'
            element={
              <RequireAuth loginPath={'/login'}>
                <Home />
              </RequireAuth>
            }
          />
          <Route
            path='/dashboard'
            element={
              <RequireAuth loginPath={'/login'}>
                <DashBoardPage />
              </RequireAuth>
            }
          />
          <Route
            path='/nolocation'
            element={
              <RequireAuth loginPath={'/login'}>
                <NoLocationPage />
              </RequireAuth>
            }
          />
          <Route
            path='/userlist'
            element={
              <RequireAuth loginPath={'/login'}>
                <AdminUserList />
              </RequireAuth>
            }
          />
          <Route
            path='/userdetails'
            element={
              <RequireAuth loginPath={'/login'}>
                <UserDetails />
              </RequireAuth>
            }
          />
          <Route
            path='/leaves'
            element={
              <RequireAuth loginPath={'/login'}>
                <Leaves />
              </RequireAuth>
            }
          />
          <Route
            path='/managerdetails'
            element={
              <RequireAuth loginPath={'/login'}>
                <ManagerBoard />
              </RequireAuth>
            }
          />
          <Route
            path='/managerhistory'
            element={
              <RequireAuth loginPath={'/login'}>
                <ManagerHistoryBoard />
              </RequireAuth>
            }
          />
          <Route
            path='/admincontrols'
            element={
              <RequireAuth loginPath={'/login'}>
                <AdminControls />
              </RequireAuth>
            }
          />
          <Route
            path='/attendancereport'
            element={
              <RequireAuth loginPath={'/login'}>
                <Reports />
              </RequireAuth>
            }
          />
          <Route
            path='/adminoverride'
            element={
              <RequireAuth loginPath={'/login'}>
                <AdminOverrideBoard />
              </RequireAuth>
            }
          />
          <Route
            path='/employeetimesheet'
            element={
              <RequireAuth loginPath={'/login'}>
                <EmployeeTimesheet />
              </RequireAuth>
            }
          />
          <Route
            path='/adminassets'
            element={
              <RequireAuth loginPath={'/login'}>
                <AssetAdminBoard />
              </RequireAuth>
            }
          />
          <Route
            path='/userassets'
            element={
              <RequireAuth loginPath={'/login'}>
                <AssetUserBoard />
              </RequireAuth>
            }
          />
          <Route
            path='/test'
            element={
              <RequireAuth loginPath={'/login'}>
                <></>
              </RequireAuth>
            }
          />
        </Routes>
      </div>
      {Boolean(isAuthenticated()) && <Footer />}
    </BrowserRouter>
  ) : (
    <BrowserRouter>
      <Routes>
        <Route path='/' element={<LogInComponent />} />
        <Route path='/login' element={<LogInComponent />} />
        <Route path='/forgotpassword' element={<ForgotPassword />} />
      </Routes>
      {window.location.pathname !== '/forgotpassword' &&
        window.location.pathname !== '/' &&
        window.location.pathname !== '/login' && (
          <Navigate to='/login' replace />
        )}
    </BrowserRouter>
  )
}
