import React, { useState, useEffect } from 'react'
import UserList from '../components/Admin/AdminControlTables.js/UserList'
import { Container, Paper, Typography, Button, Grid } from '@mui/material'
import { PeopleAltOutlined } from '@mui/icons-material'
import CreateUserForm from '../components/Admin/AdminCreateForms/CreateUserForm'
import { useAuthUser } from 'react-auth-kit'
import { pageStyle } from '../stylings/pageStyle'
export default function AdminUserList () {
  const [formOpen, setFormOpen] = useState(false)
  const [refreshTable, setRefreshTable] = useState(false)
  const user = useAuthUser()
  const handleRefreshTable = newState => {
    setRefreshTable(newState)
  }
  const handleClose = () => {
    setFormOpen(false)
  }
  useEffect(() => {
    setRefreshTable(false)
  }, [refreshTable])
  return user().modules.find(m => m.name === 'USERS_MODULE') ? (
    <Grid
      container
      columns={20}
      style={pageStyle}
    >
      <Grid item xs={20} md={20} lg={20} xl={20}>
        <Paper className='paperStyle2'>
          <div className='div-spaceToSides'>
            <Typography variant='h4'>
              <PeopleAltOutlined />
              Users
            </Typography>
            <Button
              variant='filled'
              className='button-gradient'
              onClick={() => setFormOpen(true)}
            >
              +Add new user
            </Button>
          </div>
          <UserList refreshTable={refreshTable} />
          <CreateUserForm
            open={formOpen}
            handleClose={handleClose}
            setRefreshTable={setRefreshTable}
          />
        </Paper>
      </Grid>
    </Grid>
  ) : null
}
