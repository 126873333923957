/*For use of BeQiSoft Pvt Ltd. */

import React, { useState } from 'react'
import { Button, FormControl, InputLabel } from '@mui/material'
import { DatePicker } from '@mui/x-date-pickers'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import GenericSelectCell from '../Admin/AdminSelectCells/GenericSelectCell'
import dayjs from 'dayjs'
import { ArrowForward } from '@mui/icons-material'
import ShiftSelect from '../Admin/AdminSelectCells/ShiftSelect'
import DepartmentSelect from '../Admin/AdminSelectCells/DepartmentSelect'
import TeamMultiSelect from '../Admin/AdminSelectCells/TeamMultiSelect'
import { useAuthUser } from 'react-auth-kit'
import { fetchShortUserInfo } from '../../service/addUserService'

export default function AttendanceReportFilterPage ({
  onGenerateReport,
  onGenerateStat
}) {
  const [startDate, setStartDate] = useState(dayjs(new Date()))
  const [endDate, setEndDate] = useState(dayjs(new Date()))
  const [department, setDepartment] = useState(null)
  const [departmentList, setDepartmentList] = useState([])
  const [team, setTeam] = useState(null)
  const [teamList, setTeamList] = useState([])
  const [shiftList, setShiftList] = useState([])
  const [shift, setShift] = useState()
  const [employee_id, setEmployee_id] = useState(null)
  const [project, setProject] = useState(null)
  const user = useAuthUser()
  const handleSubmitReport = async e => {
    e.preventDefault()
    if (startDate != null && endDate != null && onGenerateReport != null) {
      onGenerateReport({
        startDate,
        endDate,
        department,
        team,
        shift,
        employee_id
      })
    }
  }
  const handleSubmitStat = async e => {
    e.preventDefault()
    if (startDate != null && endDate != null && onGenerateStat != null) {
      onGenerateStat({
        startDate,
        endDate,
        department,
        team,
        shift,
        employee_id
      })
    }
  }

  return (
    <form noValidate>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <div>
          <DatePicker
            format='DD/MM/YYYY'
            label='Start date'
            value={startDate}
            onChange={newVal => setStartDate(dayjs(newVal))}
          />
          <ArrowForward style={{ margin: '1%' }} />
          <DatePicker
            format='DD/MM/YYYY'
            label='End date'
            value={endDate}
            onChange={newVal => setEndDate(dayjs(newVal))}
            minDate={startDate}
            maxDate={startDate.add(19,'days')}
          />
        </div>
      </LocalizationProvider>
      {!user().roles.find(r=>r=='TEAMLEAD') &&
      <FormControl fullWidth style={{ margin: '20px auto' }}>
        <InputLabel id='department-select-label'>Department</InputLabel>
        <DepartmentSelect
          value={departmentList}
          onChange={newVal => {
            setDepartmentList(newVal)
            setDepartment(newVal.map(d => d.departmentName).join(','))
          }}
        />
      </FormControl>}
      {!user().roles.find(r=>r=='TEAMLEAD') &&
        <FormControl fullWidth style={{ margin: '20px auto' }}>
          <InputLabel id='team-select-label'>Team</InputLabel>
          <TeamMultiSelect
            value={teamList}
            onChange={newVal => {
              setTeamList(newVal)
              setTeam(newVal.map(d => d.teamID).join(','))
            }}
          />
        </FormControl>
      }
      <FormControl fullWidth style={{ margin: '20px auto' }}>
        <InputLabel id='shift-select-label'>Shift</InputLabel>
        <ShiftSelect
          value={shiftList}
          onChange={newVal => {
            setShiftList(newVal)
            setShift(newVal.map(shi => shi.shiftName).join(','))
          }}
        />
      </FormControl>
      <FormControl fullWidth style={{ margin: '20px auto' }}>
        <InputLabel id='employee-select-label'>Employee</InputLabel>
        <GenericSelectCell
          label={'Employee'}
          value={employee_id}
          key={'employeeID'}
          keyvalue={'employeeID'}
          itemName={'user'}
          fetchData={fetchShortUserInfo}
          extra={['name', 'lname']}
          onChange={newValue => setEmployee_id(newValue)}
        />
      </FormControl>
      <Button
        variant='contained'
        className='button-gradient'
        onClick={handleSubmitReport}
      >
        Generate Attendance Report
      </Button>
      <Button
        variant='contained'
        className='button-gradient'
        onClick={handleSubmitStat}
        sx={{ marginLeft: '5%' }}
      >
        Generate Attendance Stat
      </Button>
    </form>
  )
}
