import { React, useState, useEffect, useRef } from 'react'
import {
  Typography,
  IconButton,
  ImageList,
  ImageListItem,
  Dialog,
  DialogTitle,
  ImageListItemBar,
  Backdrop,
  CircularProgress,
  Button
} from '@mui/material'
import { CancelOutlined, Delete, ImageOutlined } from '@mui/icons-material'
import { confirmAlert } from 'react-confirm-alert'
import { debounce } from 'lodash'

export default function RenderDeviceImageEditor ({
  open,
  images,
  alert,
  setImages,
  onChange,
  handleClose
}) {
  const [selectedImages, setSelectedImages] = useState([])
  const [isInputDisabled, setIsInputDisabled] = useState(false)
  useRef(1)
  const resetState = () => {
    setSelectedImages(images != null ? images : [])
  }
  useEffect(() => {
    if (open) {
      console.log('Opened again')
      resetState()
    }
  }, [open])
  async function handleImageUpload (e) {
    e.defaultPrevented = true
    setIsInputDisabled(true)
    const allowedExtensions = ['jpg', 'jpeg', 'png', 'svg']
    if (e.target.files.length !== 0) {
      const file = e.target.files[0]
      if (!allowedExtensions.includes(file.type.split('/')[1])) {
        alert.error('Please select image file only')
        e.target.value = null
        URL.revokeObjectURL(file)
        setIsInputDisabled(false)
      }
      if (selectedImages.length >= 2) {
        alert.error('Only 2 images can exist')
        URL.revokeObjectURL(file)
      }

      if (file.size <= 2 * 1024 * 1024 && selectedImages.length < 2) {
        await loadImageAndProcess(file)
          .then(imageURL => {
            setSelectedImages(prev => [...prev, imageURL])
          })
          .catch(error => {
            console.error(error.message)
            setIsInputDisabled(false)
            alert.error('Error loading image')
          })
          .finally(() => {
            setIsInputDisabled(false)
            URL.revokeObjectURL(file)
          })
      }
    } else {
      setIsInputDisabled(false)
      alert.show('Error with file')
      URL.revokeObjectURL(e.target.files)
    }
  }
  function loadImageAndProcess (file) {
    return new Promise((resolve, reject) => {
      if (file.size > 2 * 1024 * 1024) {
        reject(new Error('Image size exceeds 2MB'))
        alert.show('File size esceeds 2MB')
        return
      }
      const imageSelected = new Image()
      imageSelected.src = URL.createObjectURL(file)
      imageSelected.onload = () => {
        const canvas = document.createElement('canvas')
        const ctx = canvas.getContext('2d')
        canvas.width = imageSelected.width
        canvas.height = imageSelected.height
        ctx.drawImage(
          imageSelected,
          0,
          0,
          imageSelected.width,
          imageSelected.height
        )
        const imageURL = canvas.toDataURL()
        resolve(imageURL)
      }

      imageSelected.onerror = () => {
        reject(new Error('Failed to load the image'))
        alert.show('Error with file upload')
      }
    })
  }

  const handleImageDelete = e => {
    console.log('Image deleting')
    const i = selectedImages.filter(a => a != e)
    setSelectedImages(i)
  }
  const handleDialogClose = () => {
    console.log('Dialog closed')
    handleClose()
  }
  const debouncedHandleImageUpload = debounce(handleImageUpload, 300)
  const handleConfirm = async () => {
    try {
      if (selectedImages.length == 2) {
        var newArr = []
        selectedImages.forEach(imageSelected => {
          if (imageSelected.includes('base64,'))
            newArr.push(imageSelected.split('base64,')[1])
          else newArr.push(imageSelected)
        })
        newArr.join('')
        setImages(newArr)
        onChange(newArr)
      } else {
        alert.info('Need two images')
      }
    } catch (error) {
      alert.error('Error adding images', error)
    }
  }
  return (
    <Dialog open={open} keepMounted={true}>
      <DialogTitle>
        Edit Device Images
        <IconButton onClick={handleDialogClose} sx={{ float: 'right' }}>
          <CancelOutlined />
        </IconButton>
      </DialogTitle>
      <div>
        <div
          className='div-spaceToSides'
          style={{ margin: '1%', padding: '1%' }}
        >
          <Button
            onMouseUp={(e)=>{
              e.preventDefault()
              e.stopPropagation()
            }}
            component='label'
            style={{color:'black',backgroundColor:'lightgray'}}
          >
            <ImageOutlined /> Add Image
            <input
              type='file'
              accept='.jpg,.jpeg,.png,.svg'
              size={'2MB'}
              style={{display:'hidden'}}
              hidden
              onInputCapture={async e => {
                e.stopPropagation()
                e.preventDefault()
                await debouncedHandleImageUpload(e)
              }}
              onClick={e => {
                e.stopPropagation()
              }}
            />
          </Button>
          <Button
            variant='contained'
            className='button-gradient'
            onClick={handleConfirm}
          >
            Confirm
          </Button>
        </div>
        {selectedImages.length > 0 ? (
          <ImageList
            sx={{ width: 500, height: 300, padding: '2%' }}
            cols={2}
            rowHeight={100}
            gap={10}
          >
            {selectedImages?.map((imageSelected, ind) => {
              return (
                <ImageListItem key={ind}>
                  <img
                    crossOrigin='anonymous'
                    alt='Phone Image'
                    src={
                      imageSelected.includes('data:image')
                        ? imageSelected
                        : `data:image/jpeg;base64,${imageSelected}`
                    }
                    height={150}
                    width={150}
                    style={{ border: '1px solid rgba(0,0,0,0.4)' }}
                  />
                  <ImageListItemBar
                    position='top'
                    sx={{ background: 'rgba(255,255,255,0.0)' }}
                    actionIcon={
                      <IconButton
                        style={{
                          border: '1px solid rgba(0,0,0,0.4)',
                          background: 'rgba(255,255,255,0.5)'
                        }}
                        onClick={() => handleImageDelete(imageSelected)}
                      >
                        <Delete color='error' size='small' />
                      </IconButton>
                    }
                  />
                </ImageListItem>
              )
            })}
          </ImageList>
        ) : (
          <Typography>No images</Typography>
        )}
      </div>
      <Backdrop
        sx={{ color: '#fff', zIndex: theme => theme.zIndex.drawer + 1 }}
        open={isInputDisabled}
      >
        <CircularProgress></CircularProgress>
      </Backdrop>
    </Dialog>
  )
}
