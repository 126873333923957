import AttendanceReportPage from '../components/Report/AttendanceReportPage'
import LeaveReportPage from '../components/Report/LeaveReportPage'
import {
  Tabs,
  Typography,
  Grid
} from '@mui/material'
import { CustomTab } from '../stylings/customtabstyle'
import React, { useState } from 'react'
import TimesheetReportPage from '../components/Report/TimesheetReportPage'
import { pageStyle } from '../stylings/pageStyle'
export default function Reports () {
  const [value, setValue] = useState(0)
  const handleChange = (event, newValue) => {
    setValue(newValue)
  }

  return (
    <Grid
      container
      columns={20}
      style={pageStyle}
    >
      <Grid item xs={20} md={20} lg={20} xl={20}>
          <Tabs
            indicatorColor='secondary'
            value={value}
            onChange={handleChange}
          >
            <CustomTab
              label={<Typography variant='h6'>Attendance</Typography>}
            />
            <CustomTab label={<Typography variant='h6'>Leaves</Typography>} />
            <CustomTab label={<Typography variant='h6'>Timesheet</Typography>} />
          </Tabs>
          {value === 0 && <AttendanceReportPage />}
          {value === 1 && <LeaveReportPage />}
          {value=== 2 &&<TimesheetReportPage/>}
      </Grid>
    </Grid>
  )

}

