import { React, useState, useEffect } from 'react'
import {
  Dialog,
  DialogContent,
  DialogTitle,
  Button,
  IconButton,
  CircularProgress,
  Switch,
  Divider
} from '@mui/material'
import { FormControlLabel } from '@mui/material'
import { CancelOutlined } from '@mui/icons-material'
import { fetchItems } from '../../../service/InterfaceFunctions/fetchItems'
import { fetchPermissionsForRole } from '../../../service/roleService'
import { updateItem } from '../../../service/InterfaceFunctions/updateItem'
import { confirmAlert } from 'react-confirm-alert'
import '../../../stylings/react-confirm-customalert.css'
import { useAlert } from 'react-alert'
export default function PermissionsTableDialog ({
  roleName,
  open,
  roleID,
  handleRefreshTable,
  handleClose
}) {
  const [allPermssions, setAllPermissions] = useState([])
  const [rolePermissions, setRolePermissions] = useState([])
  const [progress, setProgress] = useState(true)
  const alert = useAlert()

  async function getAllPermissions () {
    if (roleID != null) {
      try {
        setProgress(true)
        await fetchItems('privilege').then(result =>
          setAllPermissions(
            result.sort((per1, per2) => per1.modules === per2.modules)
          )
        )
        const res = await fetchPermissionsForRole(roleID)
        const data = await res.json()
        setRolePermissions(data)
      } catch (error) {
        console.error(error)
        setAllPermissions([])
        setRolePermissions([])
      } finally {
        setProgress(false)
      }
    } else {
      setAllPermissions([])
      setRolePermissions([])
      setProgress(false)
    }
  }

  const handleSubmit = async () => {
    const roleData = {
      roleName: roleName,
      roleID: roleID,
      privilegesOfRoles: rolePermissions
    }
    await updateItem('role', roleData).then(() => {
      handleClose()
      handleRefreshTable(true)
    })
  }

  useEffect(() => {
    if (open) {
      getAllPermissions()
    }
  }, [open, roleID])

  const checkedValue = rP => {
    return rolePermissions.some(rolePriv => rP === rolePriv.privilegeID)
      ? true
      : false
  }

  const handleChange = r => {
    if (!checkedValue(r.privilegeID)) {
      setRolePermissions([...rolePermissions, r])
    } else {
      setRolePermissions(
        rolePermissions.filter(curPer => curPer.privilegeID !== r.privilegeID)
      )
    }
  }

  const PermissionControl = rP => {
    return (
      <>
        <FormControlLabel
          key={rP.privilegeID}
          control={
            <Switch
              checked={rolePermissions.some(
                rolePriv => rP.privilegeID === rolePriv.privilegeID
              )}
              onChange={() => {
                handleChange(rP)
              }}
            />
          }
          label={rP.name}
        />
        <Divider />
      </>
    )
  }

  return (
    <Dialog open={open}>
      <DialogTitle>
        Permissions for role
        <IconButton onClick={handleClose} style={{ float: 'right' }}>
          <CancelOutlined color='action' />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <form>
          {progress && <CircularProgress />}
          <h4>Attendance Page Permissions</h4>
          {allPermssions
            .filter(r => r.modules === 'Attendance')
            .map(rP => PermissionControl(rP))}
          <h4>Leaves Page Permissions</h4>
          {allPermssions
            .filter(r => r.modules === 'Leave')
            .map(rP => PermissionControl(rP))}
          <h4>Approvals Page Permissions</h4>
          {allPermssions
            .filter(r => r.modules === 'Approvals')
            .map(rP => PermissionControl(rP))}
          <h4>Users Page Permissions</h4>
          {allPermssions
            .filter(r => r.modules === 'Users')
            .map(rP => PermissionControl(rP))}
          <h4>Admin Controls Page Permissions</h4>
          {allPermssions
            .filter(r => r.modules === 'Admin Control')
            .map(rP => PermissionControl(rP))}
          <h4>Reports Page Permissions</h4>
          {allPermssions
            .filter(r => r.modules === 'Reports')
            .map(rP => PermissionControl(rP))}
          <h4>Admin Override Permissions</h4>
          {allPermssions
            .filter(r => r.modules === 'Admin Override')
            .map(rP => PermissionControl(rP))}
          <h4>Timesheet permissions</h4>
          {allPermssions
            .filter(r => r.modules === 'Timesheet')
            .map(rP => PermissionControl(rP))}
          <h4>Device Asset permissions</h4>
          {allPermssions
            .filter(r => r.modules === 'Deviceasset')
            .map(rP => PermissionControl(rP))}
          <h4>Device Damage permissions</h4>
          {allPermssions
            .filter(r => r.modules === 'Deviceassetdamage')
            .map(rP => PermissionControl(rP))}
          <h4>Laptop Asset Permissions</h4>
          {allPermssions
            .filter(r => r.modules === 'Laptopasset')
            .map(rP => PermissionControl(rP))}
          <h4>Software Permissions</h4>
          {allPermssions
            .filter(r => r.modules === 'Software')
            .map(rP => PermissionControl(rP))}
          <h4>Hardware Permissions</h4>
          {allPermssions
            .filter(r => r.modules === 'Hardware')
            .map(rP => PermissionControl(rP))}
          <h4>Utility Permissions</h4>
          <h6>
            (Permissions not limited to one module and required for utility)
          </h6>
          {allPermssions
            .filter(r => r.modules === 'Multiple Modules')
            .map(rP => PermissionControl(rP))}
          <Button
            variant='filled'
            className='button-gradient'
            onClick={handleSubmit}
          >
            Save Permissions
          </Button>
        </form>
      </DialogContent>
    </Dialog>
  )
}
