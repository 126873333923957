/*For use of BeQiSoft Pvt Ltd. */

import React, {  } from 'react'
import TextField from '@mui/material/TextField'
import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton
} from '@mui/material'
import AddIcon from '@mui/icons-material/Add'
import { addHolidayList } from '../../../service/holidayListService'
import { CancelOutlined } from '@mui/icons-material'
import { Formik } from 'formik'
import * as yup from 'yup'
export default function CreateHolidayListForm ({
  open,
  handleClose,
  setRefreshTable
}) {

  const handleValueSubmit = async e => {
    if (e!= null) {
      try {
        addHolidayList(e)
      } catch (error) {
        console.log(error)
      } finally {
        setRefreshTable(true)
        handleClose()
      }
    } else {
      alert.show("Error adding Holiday List")
    }
  }

  return (
    <Dialog open={open}>
      <DialogTitle>
        Create Holiday List
        <IconButton onClick={handleClose} sx={{ float: 'right' }}>
          <CancelOutlined />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <Formik
          initialValues={{
            listName: null,
            listDescription:null
          }}
          onSubmit={values => handleValueSubmit(values)}
          validationSchema={yup.object({
            listName: yup.string().trim().min(1).required('Required'),
            listDescription: yup.string().trim().min(1).required('Required')
          })}
        >
          {props => {
            const {
              values,
              touched,
              errors,
              handleChange,
              handleSubmit,
              setFieldValue,
              isValid,
              submitCount
            } = props
            return (
              <form noValidate onSubmit={handleSubmit}>
                <TextField
                  required
                  label='List Name'
                  variant='outlined'
                  fullWidth
                  value={values.listName}
                  onChange={handleChange('listName')}
                  style={{ margin: '20px auto' }}
                  error={touched.listName && errors.listName}
                />
                <TextField
                  required
                  label='List Description'
                  variant='outlined'
                  fullWidth
                  value={values.listDescription}
                  onChange={handleChange('listDescription')}
                  style={{ margin: '20px auto' }}
                  error={touched.listDescription && errors.listDescription}
                />
                {!isValid && submitCount > 0 && (
                  <div style={{ color: 'red', fontStyle: 'bold',width:'100%' }}>
                    Some fields may be empty or invalid
                  </div>
                )}
                <Button
                  variant='contained'
                  className='button-gradient'
                  type='submit'
                >
                  <AddIcon />
                  Add Holiday List
                </Button>
              </form>
            )
          }}
        </Formik>
      </DialogContent>
    </Dialog>
  )
}
