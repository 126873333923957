import { React, useEffect } from 'react'
import { Paper, Typography, Grid, Button } from '@mui/material'
import { WarningAmberOutlined } from '@mui/icons-material'
import { useNavigate } from 'react-router-dom'
export default function NoLocationPage () {
  const navigate = useNavigate()


  const handleLocationCheck = () => {
    navigator.permissions.query({ name: 'geolocation' }).then(result => {
      if (result.state === 'granted') {
        navigate('/home')
      }

    })
  }

  return (
    <Grid
      container
      spacing={1}
      style={{
        width: '90vw',
        height: 'min-content',
        margin: '0 auto auto auto',
        display: 'flex',
        alignItems: 'flex-start'
      }}
    >
      <Grid item xs={12} md={12}>
        <Paper
          elevation={2}
          className='paperStyle2'
          style={{ border: '0.5rem solid #e5f4fc' }}
        >
          <Typography variant='h4' className='div-centerstyle'>
            <WarningAmberOutlined color='error' fontSize='large' />
            Location must be enabled to view and mark attendance. Ensure that
            'Remember this Decision' is enabled
            <Button onClick={handleLocationCheck} className='button-gradient' variant='contained'>Back To Calender</Button>
          </Typography>
        </Paper>
      </Grid>
    </Grid>
  )
}
