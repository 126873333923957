import React, { useState, useEffect } from 'react'
import { useGridApiRef } from '@mui/x-data-grid'
import { Typography, Button } from '@mui/material'
import TableComponent from '../TableComponents'
import { Terminal } from '@mui/icons-material'
import { useAlert } from 'react-alert'
import LaptoptSelect from '../Admin/AdminSelectCells/LaptopSelect'
import CreateSoftwareForm from '../Admin/AdminCreateForms/CreateSoftwareForm'
import { DatePicker } from '@mui/x-date-pickers'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import 'dayjs/locale/en-in'
import dayjs from 'dayjs'
import timezone from 'dayjs/plugin/timezone'
import { confirmAlert } from 'react-confirm-alert'
import '../../stylings/react-confirm-customalert.css'
export default function SoftwareAdminTable () {
  const apiRef = useGridApiRef()
  const itemName = 'software'
  const itemID = 'softwareNo'
  const [formOpen, setFormOpen] = useState(false)
  const [refreshTable, setRefreshTable] = useState(false)
  const alert = useAlert()

  useEffect(() => {
    setRefreshTable(false)
  }, [refreshTable])
  const handleClose = () => {
    setFormOpen(false)
  }
  const handleEditCellChangeCommitted = params => {
    const isValid =
      params.props.value &&
      (typeof params.props.value === 'string'
        ? params.props.value.trim().length > 0
        : true)
    if (!isValid) {
      alert.error('Cannot be empty')
      console.log(params)
    }
    return { ...params.props, error: isValid }
  }
  const handleDateMismatch = () => {
    confirmAlert({
      title: 'Improper dates-Set dates properly before confirming edit',
      buttons: [
        {
          label: 'OK'
        }
      ]
    })
  }
  const columns = [
    {
      field: 'softwareID',
      headerName: 'Software ID*',
      editable: true,
      width: 150,
      preProcessEditCellProps: params => {
        handleEditCellChangeCommitted(params)
      }
    },
    {
      field: 'softwareName',
      headerName: 'Software Name*',
      editable: true,
      width: 150,
      preProcessEditCellProps: params => {
        handleEditCellChangeCommitted(params)
      }
    },
    {
      field: 'type',
      headerName: 'Type*',
      editable: true,
      width: 150,
      type: 'singleSelect',
      valueOptions: ['SingleUser', 'MultiUser'],
      preProcessEditCellProps: params => {
        handleEditCellChangeCommitted(params)
      }
    },
    {
      field: 'maxUsers',
      headerName: 'Max Users*',
      editable: true,
      type: 'number',
      width: 100,
      headerAlign:'left',
      align:'left'
    },
    {
      field: 'purchaseDate',
      headerName: 'Purchase Date*',
      editable: true,
      width: 150,
      type: 'date',
       valueGetter: (value) => (value !== null ? dayjs(value).tz('Asia/Kolkata').toDate() : null),
      renderEditCell: params => (
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DatePicker
            value={dayjs(params.value)}
            format='DD/MM/YYYY'
            maxDate={dayjs(apiRef.current.getCellValue(params.id,'expirationDate'))}
            onChange={async event => {
              if (
                dayjs(event).isAfter(
                  dayjs(
                    apiRef.current.getCellValue(params.id, 'expirationDate')
                  ),
                  'day'
                )
              )
                handleDateMismatch()
              await apiRef.current.setEditCellValue({
                id: params.id,
                field: params.field,
                value: dayjs(event).tz('Asia/Kolkata').toDate()
              })
            }}
          />
        </LocalizationProvider>
      ),
      
    },
    {
      field: 'expirationDate',
      headerName: 'Expiration Date*',
      editable: true,
      width: 150,
      type: 'date',
       valueGetter: (value) => (value !== null ? dayjs(value).tz('Asia/Kolkata').toDate() : null),
      renderEditCell: params => (
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DatePicker
            value={dayjs(params.value)}
            format='DD/MM/YYYY'
            minDate={dayjs(apiRef.current.getCellValue(params.id,'purchaseDate'))}
            onChange={async event => {
              if (
                dayjs(event).isBefore(
                  dayjs(
                    apiRef.current.getCellValue(params.id, 'purchaseDate')
                  ),
                  'day'
                )
              )
                handleDateMismatch()
              console.log('Date changed to:', dayjs(event))
              await apiRef.current.setEditCellValue({
                id: params.id,
                field: params.field,
                value: dayjs(event).tz('Asia/Kolkata').toDate()
              })
            }}
          />
        </LocalizationProvider>
      ),
    },
    {
      field: 'softwareKey',
      headerName: 'Software Key',
      editable: true,
      width: 150
    },
    {
      field: 'username',
      headerName: 'Username',
      editable: true,
      width: 150
    },
    {
      field: 'password',
      headerName: 'Password',
      editable: true,
      width: 150
    },
    {
      field: 'assignedToLaptops',
      headerName: 'Assigned To Laptops',
      editable: true,
      width: 400,
      sortable: false,
       valueFormatter: (value) => value.map(laptop => laptop.laptopAssetID),
      renderEditCell: params => (
        <LaptoptSelect
          value={params.value}
          type={apiRef.current.getCellValue(params.id, 'type')}
          max={apiRef.current.getCellValue(params.id, 'maxUsers')}
          onChange={async event => {
            await apiRef.current.setEditCellValue({
              id: params.id,
              field: params.field,
              value: event
            })
          }}
        />
      )
    },
    {
      field: 'additionalInformation',
      headerName: 'Comments',
      editable: true,
      width: 300
    }
  ]

  return (
    <>
      <div className='div-paperStyle2'>
        <div className='div-spaceToSides'>
          <>
            <Typography variant='h4'>
              <Terminal />
              Software
            </Typography>
            <Button
              variant='filled'
              className='button-gradient'
              onClick={() => setFormOpen(true)}
            >
              +Add new software
            </Button>
          </>
        </div>
        <CreateSoftwareForm
          open={formOpen}
          handleClose={handleClose}
          setRefreshTable={setRefreshTable}
        />
        <TableComponent
          refreshTable={refreshTable}
          apiRef={apiRef}
          itemID={itemID}
          itemName={itemName}
          columns={columns}
        />
      </div>
    </>
  )
}
