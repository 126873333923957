import Cookies from 'js-cookie'
import { BASE_URL } from './apiService'
import dayjs from 'dayjs'

export const fetchLeave = async employeeID => {
  try {
    const authToken = Cookies.get('_auth')
    const response = await fetch(BASE_URL + '/leave/employee/' + employeeID, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${authToken}`
      }
    })
    return response
  } catch (error) {
    console.error(error)
    throw error
  }
}
export const fetchLeaveCount = async employeeID => {
  try {
    const authToken = Cookies.get('_auth')
    const response = await fetch(
      BASE_URL + '/leave/employee/leavecount/' + employeeID,
      {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${authToken}`
        }
      }
    )
    return response
  } catch (error) {
    console.error(error)
    throw error
  }
}

export const fetchLeaveTypes = async () => {
  try {
    const authToken = Cookies.get('_auth')
    const response = await fetch(BASE_URL + '/leavetype/getAll', {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${authToken}`
      }
    })
    return response
  } catch (error) {
    console.error(error)
    throw error
  }
}

export const addLeaveType = async leavetype => {
  try {
    const authToken = Cookies.get('_auth')
    const response = await fetch(BASE_URL + '/leavetype/add', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${authToken}`
      },
      body: JSON.stringify(leavetype)
    })
    return response
  } catch (error) {
    console.error(error)
    throw error
  }
}

export const addLeaveCustomAllowance = async leavetype => {
  try {
    const authToken = Cookies.get('_auth')
    const response = await fetch(
      BASE_URL +
        '/customteamleaveallowance/add/' +
        leavetype.teamIDf +
        '/' +
        leavetype.leaveType +
        '?newCustomAllowancePercent=' +
        leavetype.customLeavePercent,
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${authToken}`
        },
        body: JSON.stringify(leavetype)
      }
    )
    return response
  } catch (error) {
    console.error(error)
    throw error
  }
}

export const addLeave = async leaveDetails => {
  try {
    const authToken = Cookies.get('_auth')
    const response = await fetch(BASE_URL + '/leave/add', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${authToken}`
      },
      body: JSON.stringify(leaveDetails)
    })
    return response
  } catch (error) {
    console.error(error)
    throw error
  }
}

export const addLeaveRequest = async aRequest => {
  try {
    const authToken = Cookies.get('_auth')
    const response = await fetch(BASE_URL + '/leave/leaveRequest', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${authToken}`
      },
      body: JSON.stringify(aRequest)
    })
    return response
  } catch (error) {
    console.error(error)
    throw error
  }
}

export const modifyLeaveRequest = async aRequest => {
  try {
    const authToken = Cookies.get('_auth')
    const response = await fetch(BASE_URL + '/leave/modifyLeave', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${authToken}`
      },
      body: JSON.stringify(aRequest)
    })
    return response
  } catch (error) {
    console.error(error)
    throw error
  }
}

export const fetchLeaveRequests = async team => {
  try {
    const authToken = Cookies.get('_auth')
    const response = await fetch(
      BASE_URL + '/leave/manager/getRequests',
      {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${authToken}`
        }
      }
    )
    return response
  } catch (error) {
    console.error(error)
    throw error
  }
}

export const fetchLeaveRequestsForManager = async (team, employeeID) => {
  try {
    const authToken = Cookies.get('_auth')
    const response = await fetch(
      BASE_URL + '/leave/manager/getRequests/' + employeeID,
      {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${authToken}`
        }
      }
    )
    return response
  } catch (error) {
    console.error(error)
    throw error
  }
}

export const arbitrateLeaveRequests = async (approval, aRequest) => {
  try {
    const authToken = Cookies.get('_auth')
    const response = await fetch(
      BASE_URL + '/leave/manager/arbitrateRequest/' + approval,
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${authToken}`
        },
        body: JSON.stringify(aRequest)
      }
    )
    return response
  } catch (error) {
    console.error(error)
    throw error
  }
}

export const fetchLeaveRequestsHistoryForManager = async (team, employeeID) => {
  try {
    const authToken = Cookies.get('_auth')
    const response = await fetch(
      BASE_URL + '/leave/manager/getRequestsHistory/' + employeeID,
      {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${authToken}`
        }
      }
    )
    return response
  } catch (error) {
    console.error(error)
    throw error
  }
}

export const calculateWeekends = (startDate, endDate) => {
  let weekends = 0
  let sd = dayjs(startDate)
  let ed = dayjs(endDate)
  while (dayjs(sd).isSameOrBefore(dayjs(ed))) {
    if (dayjs(sd).day() === 6||dayjs(sd).day() === 0) {
      weekends = weekends + 1
    }
    sd = dayjs(sd).add(1, 'days')
  }
  return weekends
}
