import { styled, Tab } from '@mui/material'
export const CustomTab = styled(Tab)(() => ({
  textTransform: 'none',
  minWidth: 0,
  color: '#e5f4fc',
  padding: '2px',
  borderRadius: '5%',
  '&:hover': {
    backgroundColor: '#e5f4fc',
    opacity: 1
  },
  '&.Mui-selected': {
    color: '#1890ff',
    backgroundColor: '#c0e7f3',
    opacity: 3
  },
  '& .Mui-error': {
    backgroundColor: `rgb(126,10,15,0.1)`,
    color: 'red'
  }
}))
