/*For use of BeQiSoft Pvt Ltd. */

import React, { useState, useEffect } from 'react'
import { Select, MenuItem, Checkbox } from '@mui/material'
import { fetchItems } from '../../../service/InterfaceFunctions/fetchItems'
export default function ProjectSelect ({ value, onChange }) {
  const [projectList, setprojectList] = useState([])
  const [projectsSelected, setProjectsSelected] = useState(value)
  async function fetchProjectsList () {
    try {
      await fetchItems('project').then(result => {
        setprojectList(result.sort())
      })
    } catch (error) {
      console.error(error)
      setprojectList([])
    }
  }
  useEffect(() => {
      fetchProjectsList()
  }, [])
  useEffect(() => {
    if (value && value.length > 0) {
      setProjectsSelected(value)
    }
  }, [value])
  const handleprojectChange = event => {
    const selectedprojectIDs = event.target.value
    const selectedprojects = projectList.filter(project =>
      selectedprojectIDs.includes(project.projectID)
    )
    console.log('Projects sel:', selectedprojects)
    setProjectsSelected(selectedprojects)
    onChange(selectedprojects)
  }

  return (
    <Select
      multiple
      value={value.map(project => project.projectID)}
      onChange={handleprojectChange}
      renderValue={selectedprojects =>
        selectedprojects
          .map(
            projectID =>
              projectList.find(project => project.projectID === projectID)
                ?.projectName || ''
          )
          .join(', ')
      }
      id='project-select'
      variant='outlined'
      fullWidth
      label='project'
      MenuProps={{
        anchorOrigin: {
          vertical: 'top',
          horizontal: 'center'
        }
      }}
    >
      {projectList.map(project => (
        <MenuItem key={project.projectID} value={project.projectID}>
          <Checkbox checked={projectsSelected.some(p => p.projectID === project.projectID)} />
          {project.projectName}
        </MenuItem>
      ))}
    </Select>
  )
}
