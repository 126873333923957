import React, { useState, useEffect } from 'react'
import { Grid, TextField, Button, Paper } from '@mui/material'
import {
  CameraAltOutlined,
  Crop,
  ImageOutlined,
  LockOutlined,
  PhoneAndroid
} from '@mui/icons-material'
import { useAuthHeader, useAuthUser, useSignOut } from 'react-auth-kit'
import { useNavigate } from 'react-router-dom'
import {
  resetPassword,
  resetOtherDetails,
  uploadProfilePicture,
  profilePictureOfUser,
  deleteProfilePicture
} from '../service/userEditDetailsService'
import { Typography } from '@mui/material'
import { useAlert } from 'react-alert'
import ReactCrop from 'react-image-crop'
import 'react-image-crop/dist/ReactCrop.css'
import PubSub from 'pubsub-js'

export default function EditUserDetailsForm () {
  const [formType, setFormType] = useState(null)
  const [email, setEmail] = useState('')
  const [name, setName] = useState('')
  const [password, setPassword] = useState('')
  const [contact, setContact] = useState('')
  const [image, setImage] = useState(null)
  const [value, setValue] = useState(null)
  const [crop, setCrop] = useState({ width: 0, height: 0, x: 0, y: 0 })
  const [open, setOpen] = useState(false)
  const [mimeType, setMimeType] = useState(null)
  const maxWidth = 500
  const maxHeight = 500
  const loggedInUserData = useAuthUser()
  const signOut = useSignOut()
  const navigate = useNavigate()
  const alert = useAlert()
  const user = useAuthUser()
  const [previousimageUrl, setPreviousImageUrl] = useState(null)
  const [isCropped, setIsCropped] = useState(false)
  const [isUploaded, setIsUploaded] = useState(false)
  const token = PubSub.subscribe('profilePictureUpdate', (msg, data) => {})
  const auth = useAuthHeader()

  useEffect(() => {
    getProfilePicAndSet()
  }, [])
  async function getProfilePicAndSet () {
    try {
      const result = await profilePictureOfUser(user().employeeID, auth()).then(
        res => {
          if (res && res.length > 40) {
            setPreviousImageUrl(res)
            setImage(res)
            setValue(res)
          } else {
            setPreviousImageUrl(null)
            setImage(null)
            setValue(null)
          }
        }
      )
    } catch (error) {
      setPreviousImageUrl(null)
      setImage(null)
      setValue(null)
    }
  }

  function handleImageUpload (e) {
    const allowedExtensions = ['jpg', 'jpeg', 'png']

    if (e.target.files.length !== 0) {
      const file = e.target.files[0]
      if (!allowedExtensions.includes(file.type.split('/')[1])) {
        console.log(file.type.split('/')[1])
        alert.error('Please select image file only')
        URL.revokeObjectURL(file)
        e.target.value = null
        return
      }
      if (file.size <= 2 * 1024 * 1024) {
        setImage()
        setValue()
        URL.revokeObjectURL(file)
        const img = new Image()
        img.src = URL.createObjectURL(file)
        img.onload = function () {
          let width = img.width
          let height = img.height
          if (width > height && width > maxWidth) {
            height *= maxWidth / width
            width = maxWidth
          } else if (height > maxHeight) {
            width *= maxHeight / height
            height = maxHeight
          }

          const canvas = document.createElement('canvas')
          const ctx = canvas.getContext('2d')
          canvas.width = width
          canvas.height = height
          ctx.drawImage(img, 0, 0, width, height)
          const resizedImageUrl = canvas.toDataURL()
          setIsUploaded(true)
          setValue(file)
          setMimeType(file.type)
          setImage(resizedImageUrl)
          setOpen(true)
          setFormType('image')
          e.target.value = null
        }
      } else {
        alert.error('File size exceeds 2MB')
      }
    } else {
      alert.error('Error with file upload')
    }
  }

  const handleCancelImage = () => {
    URL.revokeObjectURL(image)
    setIsCropped(false)
    setImage(previousimageUrl)
    setValue(previousimageUrl)
    setIsUploaded(false)
    setCrop({ width: 0, height: 0, x: 0, y: 0 })
  }
  const handleRemovePreviousImage = async () => {
    try {
      const res = await deleteProfilePicture(user().employeeID)
      const data = await res.text()
      alert.show(data)
      URL.revokeObjectURL(image)
      PubSub.publish('profilePictureUpdate', { data: 'refresh' })
      setImage(null)
      setValue(null)
      setPreviousImageUrl(null)
      setIsCropped(false)
    } catch {
      alert.error('Error occurred.Please try again later')
    }
  }
  const handleSubmit = async ft => {
    if (value != null) {
      switch (formType || ft) {
        case 'password':
          try {
            const res = await resetPassword(loggedInUserData().employeeID, {
              password: password.trim()
            })
            const responsePayload = await res.text()
            alert.info(responsePayload)
            setPassword('')
          } catch (error) {
            throw error
          }
          break
        case 'contact':
          {
            resetOtherDetails(loggedInUserData().employeeID, { contact })
            setContact('')
          }
          break
        case 'image':
          {
            const formData = new FormData()
            if (value !== null && value instanceof Blob) {
              formData.append('image', value, 'cropped.' + mimeType)
              const res = await uploadProfilePicture(
                loggedInUserData().employeeID,
                formData
              )
              const responsePayload = await res.text()
              alert.info(responsePayload)
              PubSub.publish('profilePictureUpdate', { data: 'refresh' })
              URL.revokeObjectURL(image)
              setImage(null)
              setIsCropped(false)
              setCrop(null)
              setIsUploaded(false)
              getProfilePicAndSet()
            }
            else
            {
              alert.error('Crop existing image or upload new image')
            }
          }
          break
        default:
          {
            alert.error('Error occurred. Please refresh page and try again')
          }
          break
      }
      setValue(null)
    }
  }

  const cropImage = async () => {
    const canvas = document.createElement('canvas')
    const ctx = canvas.getContext('2d')
    setIsCropped(true)
    if (image) {
      const img = new Image()
      img.src = image
      img.onload = async () => {
        const scaleX = img.naturalWidth / img.width
        const scaleY = img.naturalHeight / img.height
        const pixelRatio = window.devicePixelRatio || 1
        canvas.width = crop.width * pixelRatio
        canvas.height = crop.height * pixelRatio
        ctx.scale(pixelRatio, pixelRatio)
        ctx.drawImage(
          img,
          crop.x * scaleX,
          crop.y * scaleY,
          crop.width * scaleX,
          crop.height * scaleY,
          0,
          0,
          crop.width,
          crop.height
        )

        ctx.save()
        const blob = await canvas.toBlob(
          blob => {
            if (!blob) {
              console.error('Failed to generate Blob from canvas.')
              return
            }
            const url = URL.createObjectURL(blob)
            setImage(url)
            setValue(blob)
          },
          mimeType,
          1
        )
        if (!blob) {
          console.error('Failed to generate Blob from canvas.')
          return
        }
      }
    }
  }

  function resizeImageToFitComponent (img, maxWidth, maxHeight) {
    var canvas = document.createElement('canvas')
    var ctx = canvas.getContext('2d')

    var width = img.width
    var height = img.height

    if (width > height) {
      if (width > maxWidth) {
        height *= maxWidth / width
        width = maxWidth
      }
    } else {
      if (height > maxHeight) {
        width *= maxHeight / height
        height = maxHeight
      }
    }

    canvas.width = width
    canvas.height = height

    ctx.drawImage(img, 0, 0, width, height)

    return canvas.toDataURL()
  }

  return (
    <>
      <Grid
        container
        columns={20}
        rowGap={1}
        style={{
          width: '90vw',
          height: 'min-content',
          margin: '0 auto auto auto',
          display: 'flex',
          alignItems: 'flex-start',
          padding: '1%'
        }}
      >
        <Grid
          item
          xs={20}
          md={20}
          lg={20}
          xl={20}
          style={{ border: '0.75rem solid #e5f4fc' }}
        >
          <Paper elevation={3} className='paperStyle2'>
            <Typography variant='h1'>
              <CameraAltOutlined className='icon-filled' />
              Update Profile Picture
            </Typography>

            <form
              id='pictureform'
              noValidate
              autoComplete='off'
              className='div-centerstyle'
            >
              <ul>
                <p>Image Restrictions:</p>
                <li>Maximum size:2MB</li>
                <li>Must be an image file(jpeg,jpg or png)</li>
                <li>Larger images(exceeding 500px) will be resized</li>
              </ul>

              <div
                style={{
                  border: '2px dashed gray',
                  minWidth: '200px',
                  minHeight: '200px',
                  padding: '2%'
                }}
                className='div-centerstyle'
              >
                <label className='button-outlined2'>
                  <ImageOutlined /> Select File
                  <input
                    type='file'
                    style={{ display: 'none' }}
                    onChange={handleImageUpload}
                    accept='.jpg,.jpeg,.png'
                    size={'2MB'}
                  />
                </label>

                {image !== null ? (
                  <>
                    <Button
                      sx={{ float: 'right', margin: '1%' }}
                      onClick={cropImage}
                      variant='outlined'
                      color='success'
                    >
                      <Crop /> Crop Image
                    </Button>
                    <ReactCrop
                      src={image}
                      crop={crop}
                      onChange={newCrop => setCrop(newCrop)}
                      onComplete={newCrop => setCrop(newCrop)}
                      aspect={4 / 5}
                      maxWidth={maxWidth}
                      maxHeight={maxHeight}
                      crossorigin='anonymous'
                      ruleOfThirds={true}
                    />
                  </>
                ) : null}
              </div>
              <div className='div-spaceToSides'>
                {previousimageUrl !== null && !isCropped && !isUploaded && (
                  <Button
                    variant='outlined'
                    color='error'
                    onClick={handleRemovePreviousImage}
                    sx={{ margin: '3%' }}
                  >
                    Delete
                  </Button>
                )}
                {image != null && (isCropped || isUploaded) && (
                  <>
                    <Button
                      variant='contained'
                      className='button-gradient'
                      onClick={() => handleSubmit('image')}
                      sx={{ margin: '3%' }}
                    >
                      Upload
                    </Button>
                    <Button
                      variant='outlined'
                      color='secondary'
                      onClick={handleCancelImage}
                      sx={{ margin: '3%' }}
                    >
                      Cancel
                    </Button>
                  </>
                )}
              </div>
            </form>
          </Paper>
        </Grid>
        <Grid
          item
          xs={20}
          md={20}
          lg={20}
          xl={20}
          style={{ border: '0.75rem solid #e5f4fc' }}
        >
          <Paper elevation={3} className='paperStyle2'>
            <Typography
              variant='h1'
              style={{ display: 'flex', alignItems: 'center' }}
            >
              <LockOutlined className='icon-filled' />
              Reset Password
            </Typography>
            <form
              id='passwordForm'
              className='div-centerstyle'
              noValidate
              autoComplete='off'
            >
              <p>Password must contain:</p>
              <ul>
                <li>Minimum 8 characters,max 50</li>
                <li>1 Upper case character</li>
                <li>1 Lower case character</li>
                <li>1 Special character(@$!%*?&)</li>
              </ul>
              <TextField
                required
                id='outlined-basic-password'
                label='Password'
                variant='outlined'
                fullWidth
                value={password}
                onChange={e => {
                  setPassword(e.target.value.trim())
                  setFormType('password')
                  setValue(e.target.value)
                }}
                style={{ margin: '20px auto' }}
              />
              <Button
                variant='contained'
                className='button-gradient'
                onClick={handleSubmit}
              >
                Reset
              </Button>
            </form>
          </Paper>
        </Grid>

        <Grid
          item
          xs={20}
          md={20}
          lg={20}
          xl={20}
          style={{ border: '0.75rem solid #e5f4fc' }}
        >
          <Paper elevation={3} className='paperStyle2'>
            <Typography
              variant='h1'
              style={{ display: 'flex', alignItems: 'center' }}
            >
              {' '}
              <PhoneAndroid className='icon-filled' /> Reset Contact
            </Typography>
            <form
              id='contactForm'
              className='div-centerstyle'
              noValidate
              autoComplete='off'
            >
              <TextField
                required
                id='outlined-basic-contact'
                label='Contact'
                variant='outlined'
                fullWidth
                value={contact}
                onChange={e => {
                  setContact(e.target.value)
                  setFormType('contact')
                  setValue(e.target.value)
                }}
                style={{ margin: '20px auto' }}
              />
              <Button
                variant='contained'
                className='button-gradient'
                onClick={handleSubmit}
              >
                Reset
              </Button>
            </form>
          </Paper>
        </Grid>
      </Grid>
    </>
  )
}
