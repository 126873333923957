import React, { useEffect, useState } from 'react'
import { DataGrid } from '@mui/x-data-grid'
import { Paper, Typography } from '@mui/material'
import { useAuthHeader, useAuthUser } from 'react-auth-kit'
import dayjs from 'dayjs'
import timezone from 'dayjs/plugin/timezone'
import { dataGridStyles } from '../../stylings/datagridStyles'
import { useGetHolidaysDataQuery } from '../../reduxstore/features/holidaysRTKFile'
dayjs.extend(timezone)
export default function HolidayList () {
  const [holidays, setHolidays] = useState([])
  const user = useAuthUser()
  const auth = useAuthHeader()

  const {
    data: holidaylist = [],
    isError: holidaysError,
    isLoading: holidaysLoading
  } = useGetHolidaysDataQuery({
    employeeID: user().employeeID,
    auth: auth()
  })

  useEffect(() => {
    setHolidays(holidaylist)
  }, [holidaylist])

  const columns = [
    {
      field: 'holiday',
      headerName: 'Date',
      type: 'date',
       valueGetter: (value) =>
        value !== null ? dayjs(value).tz('Asia/Kolkata').toDate() : null,
      flex: 2
    },
    {
      field: 'occassion',
      headerName: 'Occassion',
      flex: 2
    },
    {
      field: 'day',
      headerName: 'WeekDay',
      flex: 2
    }
  ]

  return (
    <Paper
      elevation={2}
      className='paperStyle5'
      style={{ border: '0.5rem solid #e5f4fc' }}
    >
      <Typography variant='h4'>Holiday List</Typography>
      <DataGrid
        autoHeight
        progress={holidaysLoading}
        disableRowSelectionOnClick
        loading={holidaysLoading}
        rows={holidays}
        columns={columns}
        initialState={{
          sorting: {
            sortModel: [{ field: 'holiday', sort: 'asc' }]
          }
        }}
        getRowId={row => row.calenderID}
        sx={dataGridStyles}
      />
    </Paper>
  )
}
