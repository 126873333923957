import React, { useMemo, useState } from 'react'
import {
  Select,
  MenuItem,
  LinearProgress,
  ListSubheader,
  TextField
} from '@mui/material'
import { fetchItems } from '../../../service/InterfaceFunctions/fetchItems'
export default function GenericSelectCell ({
  value,
  onChange,
  fetchData,
  label,
  key,
  keyvalue,
  extra,
  itemName
}) {
  const [options, setOptions] = useState([])
  const [isLoading, setIsLoading] = useState(true)
  const [search, setSearch] = useState('')
  const fetchDataAndSetOptions = async () => {
    setIsLoading(true)
    try {
      if (fetchData == null) {
        await fetchItems(itemName).then(result => {
          setOptions(result)
        })
      } else {
        const temp = await fetchData()
        const res = await temp.json()
        setOptions(res)
      }
    } catch (error) {
      setOptions([])
    } finally {
      setIsLoading(false)
    }
  }
  useMemo(() => {
    fetchDataAndSetOptions()
  }, [fetchData])
  const filteredOptions =
    options != null && options.length > 0
      ? options.filter(
          option =>
            option[keyvalue].toUpperCase().includes(search.toUpperCase()) ||
            (extra &&
              extra.some(element =>
                option[element]?.toUpperCase().includes(search.toUpperCase())
              ))
        )
      : []
  return (
    <>
      <Select
        value={value}
        onChange={event => onChange(event.target.value)}
        variant='outlined'
        label={label}
        renderValue={() => (value == null ? ' ' : value)}
        MenuProps={{
          anchorOrigin: {
            vertical: 'top',
            horizontal: 'center'
          }
        }}
      >
        {isLoading ? <LinearProgress variant='indeterminate' /> : null}
        <ListSubheader>
          <TextField
            value={search}
            onChange={event => setSearch(event.target.value)}
            onKeyDown={event => event.stopPropagation()}
            placeholder='Search'
            fullWidth
          />
        </ListSubheader>
        <MenuItem key={'null'} value={null}>
          Select
        </MenuItem>
        {filteredOptions.length
          ? filteredOptions.map(option => (
              <MenuItem key={option[key]} value={option[keyvalue]}>
                {option[keyvalue]}{' '}
                {extra != null
                  ? ' | ' + extra.map(item => option[item]).join(' - ')
                  : null}
              </MenuItem>
            ))
          : null}
      </Select>
    </>
  )
}
