import Cookies from 'js-cookie'
import { BASE_URL } from './apiService'
import dayjs from 'dayjs'

export const fetchLaptopAssetsForemployee = async employeeID => {
    try {
      const authToken = Cookies.get('_auth')
      const response = await fetch(
        BASE_URL + '/laptopasset/getAssigned/' + employeeID,
        {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${authToken}`
          }
        }
      )
      return response
    } catch (error) {
      console.error(error)
      throw error
    }
  }