import Cookies from 'js-cookie'
import { BASE_URL } from "./apiService";
export const fetchAlertsForAdmin = async () => {
  try {
    const authToken = Cookies.get('_auth')
    const response = await fetch(`${BASE_URL}/alertmessage/getAllForAdmin`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${authToken}`
      }
    })
    return response
  } catch (error) {
    console.error(`Error fetching alertss:`, error)
    return []
  }
}
