import { BASE_URL } from './apiService'

export const handleLogin = async credentials => {
  try {
    const { username, password } = credentials
    const authString = `${username}:${password}`
    const response = await fetch(BASE_URL + '/auth/login', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Basic ' + btoa(authString)
      },
      body: JSON.stringify(credentials)
    })
    return response
  } catch (error) {
    console.error(error)
    throw error
  }
}
export function handleSuccessfulLogin(data, signIn, dispatch, navigate, alert, setErrorWithLogin) {
  const expiresInInSeconds = data.expiresIn / 1000 / 60
  let isSignedIn = signIn({
    token: data.accessToken,
    tokenType: data.tokenType || 'Bearer',
    expiresIn: expiresInInSeconds,
    authState: {
      email: data.email,
      employeeID: data.employeeID,
      roles: data.roles,
      team: data.team,
      projects: data.assignedProjects,
      modules: data.modules,
      expiresIn: data.expiresIn
    }
  })
  if (isSignedIn) {
    navigate('/home')
  } else {
    alert.error('Incorrect Credentials')
    setErrorWithLogin(true)
  }
}
