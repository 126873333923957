import React from 'react'
import { useSignOut } from 'react-auth-kit'
import { Button } from '@mui/material'
import handleLogout from '../service/logoutService'
import { useNavigate } from 'react-router-dom'
export default function LogoutButton () {
  const signOut = useSignOut()
  const navigate = useNavigate()

  return (
    <Button
      variant='filled'
      className='button-gradient'
      onClick={() => handleLogout({ signOut: signOut, navigate: navigate })}
    >
      Sign Out
    </Button>
  )
}
