import Cookies from 'js-cookie'
import { BASE_URL } from './apiService'

export const getAllAttendanceReports = async ({
  startDate,
  endDate,
  team,
  department,
  shift,
  empID
}) => {
  try {
    const authToken = Cookies.get('_auth')
    const url = new URL(BASE_URL + '/attendance/getReport')
    let params = {
      startDate,
      endDate
    }
    if (team != null) params.team = team

    if (department != null) params.department = department
    if (shift != null) params.shift = shift
    if (empID != null) params.empID = empID
    url.search = new URLSearchParams(params).toString()
    const response = await fetch(url, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${authToken}`
      }
    })

    return response
  } catch (error) {
    console.error(error)
    throw error
  }
}
export const getAllLeaveReports = async ({
  startDate,
  endDate,
  team,
  department,
  shift,
  empID
}) => {
  try {
    const authToken = Cookies.get('_auth')
    const url = new URL(BASE_URL + '/leave/getReport')
    let params = {
      startDate,
      endDate
    }
    if (team != null) params.team = team

    if (department != null) params.department = department
    if (shift != null) params.shift = shift
    if (empID != null) params.empID = empID
    url.search = new URLSearchParams(params).toString()
    const response = await fetch(url, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${authToken}`
      }
    })

    return response
  } catch (error) {
    console.error(error)
    throw error
  }
}

export const getAllAttendanceStats = async ({
  startDate,
  endDate,
  team,
  department,
  shift,
  empID
}) => {
  try {
    const authToken = Cookies.get('_auth')
    const url = new URL(BASE_URL + '/attendance/getReportStat')
    let params = {
      startDate,
      endDate
    }
    if (team != null) params.team = team

    if (department != null) params.department = department
    if (shift != null) params.shift = shift
    if (empID != null) params.empID = empID
    url.search = new URLSearchParams(params).toString()
    const response = await fetch(url, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${authToken}`
      }
    })

    return response
  } catch (error) {
    console.error(error)
    throw error
  }
}

export const getAllLeaveBalanceReports = async ({
  startDate,
  team,
  department,
  shift,
  empID
}) => {
  try {
    const authToken = Cookies.get('_auth')
    const url = new URL(BASE_URL + '/leave/getLeaveBalanceReport')
    let params = {
      startDate
    }
    if (team != null) params.team = team

    if (department != null) params.department = department
    if (shift != null) params.shift = shift
    if (empID != null) params.empID = empID
    url.search = new URLSearchParams(params).toString()
    const response = await fetch(url, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${authToken}`
      }
    })

    return response
  } catch (error) {
    console.error(error)
    throw error
  }
}

export const getAttendanceStatsOfSingleEmployee = async ({
  startDate,
  endDate,
  empID
}) => {
  try {
    const authToken = Cookies.get('_auth')
    const url = new URL(BASE_URL + '/attendance/getEmployeeAttendanceStat')
    let params = {
      startDate,
      endDate,
      empID
    }
    url.search = new URLSearchParams(params).toString()
    const response = await fetch(url, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${authToken}`
      }
    })

    return response
  } catch (error) {
    console.error(error)
    throw error
  }
}
