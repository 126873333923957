import React, { useState,useEffect } from 'react'
import { useGridApiRef } from '@mui/x-data-grid'
import {
  Typography,
  Button} from '@mui/material'
import TableComponent from '../../TableComponents'
import { EventBusyOutlined } from '@mui/icons-material'
import CreateLeaveTypeForm from '../AdminCreateForms/CreateLeaveTypeForm'

export default function LeaveTypeTable () {
  const apiRef = useGridApiRef()

  const [formOpen, setFormOpen] = useState(false)

  const [refreshTable, setRefreshTable] = useState(false)
 

  const handleRefreshTable = newState => {
    setRefreshTable(newState)
  }


  const handleClose = () => {
    setFormOpen(false)
  }

  useEffect(() => {
    setRefreshTable(false)
  }, [refreshTable])
  
  const defaultColumns = [
    {
      field: 'leaveType',
      headerName: 'Leave Type',
      flex: 1,
      editable: true
    },
    {
      field: 'leavePercent',
      headerName: 'Leave Percentage',
      flex: 1,
      editable: true
    }
  ]


  return (
    <>
      <div>
        <div className='div-spaceToSides'>
          <Typography variant='h4'>
            <EventBusyOutlined />
            Leave types
          </Typography>

          <Button
            variant='filled'
            className='button-gradient'
            onClick={()=>{setFormOpen(true)}}
          >
            +Add new Leave Type
          </Button>
        </div>
        <TableComponent
          key={'defaultView'}
          refreshTable={refreshTable}
          apiRef={apiRef}
          itemID={'leaveID'}
          itemName={'leavetype'}
          columns={defaultColumns}
        />

          <CreateLeaveTypeForm
            open={formOpen}
            handleClose={handleClose}
            setRefreshTable={setRefreshTable}
          />
      </div>
    </>
  )
}
