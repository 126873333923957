import React, { useState } from 'react'
import {
  Paper,
  Typography,
  Grid,
  Button,
  Backdrop,
  CircularProgress
} from '@mui/material'
import {
  adminTimesheetReport,
  downLoadExcelTest
} from '../../service/timesheetservice'
import TimesheetReportFilters from './TimesheetReportFilters'

export default function TimesheetReportPage () {
  const [generateReport, setGenerateReport] = useState(false)
  const [progress, setProgress] = useState(false)
  const [selectedValues, setSelectedValues] = useState({
    monthDate: null,
    employeeID: null,
    team: null
  })

  const handleGenerateReport = async values => {
    setGenerateReport(true)
    setProgress(true)
    setSelectedValues(values)
    await downloadExcel(values)
  }

  const downloadExcel = async values => {
    try {
      const response = await adminTimesheetReport(values)
      let contentDisposition = response.headers.get('content-disposition')
      console.log("CD:",contentDisposition)
      let filename = 'default_filename.xlsx'
      if (contentDisposition) {
        const filenameRegex = /filename="([^"]*)"/
        const matches = filenameRegex.exec(contentDisposition)
        if (matches != null && matches[1]) {
          filename = matches[1]
        }
      }
      const blob = await response.blob()
      const url = window.URL.createObjectURL(blob)
      const link = document.createElement('a')
      link.href = url
      link.setAttribute('download', filename)
      document.body.appendChild(link)
      link.click()
      document.body.removeChild(link)
      setProgress(false)
    } catch (error) {
      console.error(error)
      setProgress(false)
    }
  }

  return (
    <>
      <Grid container spacing={1}>
        <Grid item xs={12} md={12}>
          <Paper elevation={2} className='paperStyle2'>
            <Typography variant='h4'>Timesheet Report</Typography>
            <TimesheetReportFilters onGenerateReport={handleGenerateReport} />
          </Paper>
        </Grid>
      </Grid>
      {generateReport && (
        <Backdrop
          sx={{ color: '#fff', zIndex: theme => theme.zIndex.drawer + 1 }}
          open={progress}
        >
          <CircularProgress />
        </Backdrop>
      )}
    </>
  )
}
