/*For use of BeQiSoft Pvt Ltd. */

import React, { useState, useMemo, useEffect } from 'react'
import { Select, MenuItem, LinearProgress } from '@mui/material'
import { getTeamManagers } from '../../../service/teamService'
export default function ManagerSelect ({ value, team, onChange }) {
  const [managers, setManagers] = useState([])
  const [isLoading, setIsLoading] = useState(true)
  async function fetchManagers () {
    try {
      const temp = await getTeamManagers(team)
      const res = await temp.json()
      setManagers(res)
    } catch (error) {
      console.error(error)
      setManagers([])
    } finally {
      setIsLoading(false)
    }
  }
  useEffect(() => {
    fetchManagers()
  }, [])

  return (
    <Select
      value={value}
      onChange={event => onChange(event.target.value)}
      renderValue={() => (value == null ? ' ' : value)}
      id='manager-select'
      variant='outlined'
      fullWidth
      label='Manager'
      MenuProps={{
        anchorOrigin: {
          vertical: 'top',
          horizontal: 'center'
        }
      }}
    >
      {isLoading ? <LinearProgress variant='indeterminate' /> : null}
      {managers.length ?
        managers.map(manager => (
          <MenuItem key={manager.employeeID} value={manager.employeeID}>
            {manager.employeeID}-{manager.name}
          </MenuItem>
        )):null}
    </Select>
  )
}
