import React, { useState, useEffect } from 'react'
import { Select, MenuItem, Checkbox } from '@mui/material'
import { fetchItems } from '../../../service/InterfaceFunctions/fetchItems'
export default function DepartmentSelect ({ value, onChange }) {
  const [departmentList, setDepartmentList] = useState([])
  const [departmentsSelected, setDepartmentsSelected] = useState([])
  useEffect(() => {
    async function fetchDepartmentsList () {
      try {
        await fetchItems('department').then(result => {
          setDepartmentList(result)
        })
        
      } catch (error) {
        console.error(error)
        setDepartmentList([])
      }
    }
    fetchDepartmentsList()
  }, [])

  const handleDepartmentChange = event => {
    const selectedDepartmentIDs = event.target.value
    const selectedDepartments = departmentList.filter(d =>
      selectedDepartmentIDs.includes(d.departmentID)
    )
    setDepartmentsSelected(selectedDepartments)
    onChange(selectedDepartments)
  }

  return (
    <Select
      multiple
      label={'Department'}
      value={value.map(d => d.departmentID)}
      onChange={handleDepartmentChange}
      renderValue={selectedRoles =>
        selectedRoles
          .map(
            departmentID =>
              departmentList.find(d => d.departmentID === departmentID)?.departmentName || ''
          )
          .join(', ')
      }
      variant='outlined'
      fullWidth
      MenuProps={{
        anchorOrigin: {
          vertical: 'top',
          horizontal: 'center'
        }
      }}
    >
      {departmentList.map(d => (
        <MenuItem key={d.departmentID} value={d.departmentID}>
          <Checkbox checked={departmentsSelected.indexOf(d)>-1} />
          {d.departmentName}
        </MenuItem>
      ))}
    </Select>
  )
}
