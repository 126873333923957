import { createApi } from '@reduxjs/toolkit/query/react'
import { fetchBaseQuery } from '@reduxjs/toolkit/query'
import { fetchHolidaysForemployee } from '../../service/calenderService'

const initialState = { entities: [], status: 'idle', error: null }
export const holidaysAPIs = createApi({
  baseQuery: fetchBaseQuery({ url: '/' }),
  reducerPath: 'holidaysAPI',
  endpoints: build => ({
    getHolidaysData: build.query({
      queryFn: async ({ employeeID,auth }) => {
        try {
          const hols = await fetchHolidaysForemployee(employeeID,auth)
          if (hols.ok) return { data: hols.json() }
          return { data: [] }
        } catch (error) {
          return { error }
        }
      }
    }),
    keepUnusedDataFor: 3600
  })
})
export const { useGetHolidaysDataQuery } = holidaysAPIs
