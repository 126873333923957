/*For use of BeQiSoft Pvt Ltd. */

import React, { useState, useEffect } from 'react'
import TextField from '@mui/material/TextField'
import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  FormControl,
  IconButton,
  InputLabel
} from '@mui/material'
import AddIcon from '@mui/icons-material/Add'
import { Cable, CancelOutlined } from '@mui/icons-material'
import { Formik, validateYupSchema } from 'formik'
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import * as yup from 'yup'
import GenericSelectCell from '../AdminSelectCells/GenericSelectCell'
import dayjs from 'dayjs'
import { fetchShortUserInfo } from '../../../service/addUserService'
import { addItem } from '../../../service/InterfaceFunctions/addItem'
import { useAlert } from 'react-alert'

export default function CreateHardwareForm ({
  open,
  handleClose,
  setRefreshTable
}) {
  const [mandatoryIsEmpty, setMandatorIsEmpty] = useState(false)
  const alert = useAlert()
  useEffect(() => {
    if (open) {
      resetState()
    }
  }, [open])

  const resetState = () => {
    setMandatorIsEmpty(false)
  }
  const handleFormClose = () => {
    handleClose()
  }
  const handleValueSubmit = async e => {
    try {
      const res = await addItem('hardware', e)
      const data = await res.text()
      if (res.ok) alert.show(data)
      else alert.show('Error in creation, ensure IDs are unique')
    } catch (error) {
      console.log(error)
      alert.show('Error')
    } finally {
      setRefreshTable(true)
      handleFormClose()
    }
  }

  return (
    <Dialog open={open}>
      <DialogTitle>
        <IconButton onClick={handleFormClose} sx={{ float: 'right' }}>
          <CancelOutlined />
        </IconButton>
        Create Hardware
      </DialogTitle>
      <DialogContent>
        <Formik
          initialValues={{
            hardwareID: null,
            hardwareName: null,
            purchaseDate: dayjs(new Date()),
            additionalInfo: null,
            assignedToEmp: null
          }}
          onSubmit={values => handleValueSubmit(values)}
          validationSchema={yup.object({
            hardwareID: yup.string().min(7).required('Required'),
            hardwareName: yup.string().required('Required'),
            purchaseDate: yup.date().nonNullable().required('Required')
          })}
        >
          {props => {
            const {
              values,
              touched,
              errors,
              handleChange,
              handleSubmit,
              setFieldValue,
              isValid,
              submitCount
            } = props
            return (
              <form noValidate onSubmit={handleSubmit}>
                <TextField
                  required
                  label='Hardware ID'
                  variant='outlined'
                  fullWidth
                  helperText={'Minimum 7 characters'}
                  value={values.hardwareID}
                  onChange={handleChange('hardwareID')}
                  style={{ margin: '20px auto' }}
                  error={touched.hardwareID && errors.hardwareID}
                />
                <TextField
                  required
                  label='Hardware Name'
                  variant='outlined'
                  fullWidth
                  value={values.hardwareName}
                  onChange={handleChange('hardwareName')}
                  style={{ margin: '20px auto' }}
                  error={touched.hardwareName && errors.hardwareName}
                />
                <FormControl
                  required
                  fullWidth
                  style={{ margin: '20px auto' }}
                  error={
                    touched.purchaseDate &&
                    errors.purchaseDate &&
                    values.purchaseDate != null
                  }
                >
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                      label='Purchase Date *'
                      format='DD/MM/YYYY'
                      value={values.purchaseDate}
                      onChange={val =>
                        setFieldValue('purchaseDate', dayjs(val))
                      }
                      slotProps={{
                        textField: {
                          error:
                            touched.purchaseDate &&
                            errors.purchaseDate &&
                            values.purchaseDate != null
                        }
                      }}
                    />
                  </LocalizationProvider>
                </FormControl>
                <TextField
                  label='Additional Info'
                  variant='outlined'
                  fullWidth
                  value={values.additionalInfo}
                  onChange={handleChange('additionalInfo')}
                  style={{ margin: '20px auto' }}
                />
                <FormControl fullWidth style={{ margin: '20px auto' }}>
                  <InputLabel>Assign To</InputLabel>
                  <GenericSelectCell
                    label={'Assign To'}
                    value={values.assignedToEmp}
                    key={'employeeID'}
                    keyvalue={'employeeID'}
                    itemName={'user'}
                    fetchData={fetchShortUserInfo}
                    extra={['name', 'lname']}
                    onChange={handleChange('assignedToEmp')}
                  />
                </FormControl>
                {!isValid && submitCount > 0 && (
                  <div style={{ color: 'red', fontStyle: 'bold' }}>
                    Some fields may be empty or invalid
                  </div>
                )}
                <Button
                  variant='contained'
                  className='button-gradient'
                  type='submit'
                >
                  <AddIcon />
                  Add Hardware
                </Button>
              </form>
            )
          }}
        </Formik>
      </DialogContent>
    </Dialog>
  )
}
