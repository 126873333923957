import Cookies from 'js-cookie'
import { BASE_URL } from '../apiService'
export const fetchItems = async (modelName,auth) => {
  try {
    const authToken = Cookies.get('_auth')
    const response = await fetch(`${BASE_URL}/${modelName}/getAll`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization:auth? auth: `Bearer ${authToken}`
      }
    })
    const data = await response.json()
    return data
  } catch (error) {
    console.error(`Error fetching ${modelName}s:`, error)
    return []
  }
}
