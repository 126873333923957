import React from 'react'
import { IconButton } from '@mui/material'
import { QuestionMarkRounded } from '@mui/icons-material'
import Tooltip from '@mui/material/Tooltip'
import CalenderLegend from './CalenderLegend'

export default function CalenderLegendTooltip ({
  handleTooltipClose,
  legendToolTipOpen,
  handleLegendToolTipOpen
}) {
  return (
    <Tooltip
      title={<CalenderLegend />}
      onClose={handleTooltipClose}
      open={legendToolTipOpen}
    >
      <IconButton
        style={{ backgroundColor: 'paleturquoise' }}
        onClick={handleLegendToolTipOpen}
        onMouseEnter={handleLegendToolTipOpen}
      >
        <QuestionMarkRounded color='secondary' fontSize='small' />
      </IconButton>
    </Tooltip>
  )
}
