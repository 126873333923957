import React, { useState } from 'react'
import AttendanceReport from './AttendanceReport'
import { Paper, Typography, Grid } from '@mui/material'
import AttendanceReportFilterPage from './AttendanceReportFilters'
import AttendanceStat from './AttendanceStat'

export default function AttendanceReportPage () {
  const [generateReport, setGenerateReport] = useState(false)
  const [generateStat, setGenerateStat] = useState(false)

  const [selectedValues, setSelectedValues] = useState({
    startDate: null,
    endDate: null,
    department: null,
    team: null,
    shift: null,
    employee_id: null
  })

  const handleGenerateReport = values => {
    setGenerateReport(true)
    setSelectedValues(values)
  }
  const handleGenerateStat = values => {
    setGenerateStat(true)
    setSelectedValues(values)
  }
  return (
    <Grid container spacing={1}>
      <Grid item xs={12} md={12}>
        <Paper elevation={2} className='paperStyle2'>
          <Typography variant='h4'>Attendance Report Details</Typography>
          <AttendanceReportFilterPage onGenerateReport={handleGenerateReport} onGenerateStat={handleGenerateStat}/>
        </Paper>
      </Grid>
      {generateReport && (
        <Grid item xs={12} md={12}>
          <Paper elevation={2} className='paperStyle2'>
            <Typography variant='h4'>Attendance Report</Typography>
            <AttendanceReport {...selectedValues} />
          </Paper>
        </Grid>
      )}
      {generateStat && (
        <Grid item xs={12} md={12}>
          <Paper elevation={2} className='paperStyle2'>
            <Typography variant='h4'>Attendance Stats</Typography>
            <AttendanceStat {...selectedValues} />
          </Paper>
        </Grid>
      )}
    </Grid>
  )
}
