import Cookies from 'js-cookie'
import { BASE_URL } from './apiService'
import dayjs from 'dayjs'

export const transferDeviceFromSender = async (
  deviceAssetTransferTracker,
  images
) => {
  try {
    const authToken = Cookies.get('_auth')
    const url = new URL(BASE_URL + '/deviceassettransfer/transferFromSender')
    let combinedData = {
      deviceAssetTransferTracker,
      images
    }
    const response = await fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${authToken}`
      },
      body: JSON.stringify(combinedData)
    })
    return response
  } catch (error) {
    console.error(error)
    throw error
  }
}

export const transferDeviceFromReceiver = async (
  deviceAssetTransferTracker,
  status
) => {
  try {
    const authToken = Cookies.get('_auth')
    const url = new URL(
      BASE_URL + '/deviceassettransfer/transferFromReceiver/' + status
    )
    const response = await fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${authToken}`
      },
      body: JSON.stringify(deviceAssetTransferTracker)
    })
    return response
  } catch (error) {
    console.error(error)
    throw error
  }
}

export const fetchDeviceAssetsForemployee = async employeeID => {
  try {
    const authToken = Cookies.get('_auth')
    const response = await fetch(
      BASE_URL + '/deviceasset/getAssigned/' + employeeID,
      {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${authToken}`
        }
      }
    )
    return response
  } catch (error) {
    console.error(error)
    throw error
  }
}

export const fetchDeviceAssetsTransferForemployee = async employeeID => {
  try {
    const authToken = Cookies.get('_auth')
    const response = await fetch(
      BASE_URL + '/deviceassettransfer/getAssignedRequests/' + employeeID,
      {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${authToken}`
        }
      }
    )
    return response
  } catch (error) {
    console.error(error)
    throw error
  }
}

export const repairOrSurrender = async (
  deviceAssetTransferTracker,
  images,
  status
) => {
  try {
    const authToken = Cookies.get('_auth')
    const url = new URL(BASE_URL + `/deviceassettransfer/repairOrSurrender?status=${status}`)
    let combinedData = {
      deviceAssetTransferTracker,
      images
    }
    const response = await fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${authToken}`
      },
      body: JSON.stringify(combinedData)
    })
    return response
  } catch (error) {
    console.error(error)
    throw error
  }
}
export const fetchDeviceAssetsTransferHistory = async deviceID => {
  try {
    const authToken = Cookies.get('_auth')
    const response = await fetch(
      BASE_URL + '/deviceassettransfer/getTransferHistory/' + deviceID,
      {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${authToken}`
        }
      }
    )
    return response
  } catch (error) {
    console.error(error)
    throw error
  }
}