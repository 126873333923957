import { React, useState } from 'react'
import LoginForm from '../components/LoginForm'
import {
  Button,
  TextField,
  Typography,
  Box,
  Container,
  CssBaseline
} from '@mui/material'
import { useAlert } from 'react-alert'
import { forgotPassword } from '../service/userEditDetailsService'
import { useNavigate } from 'react-router-dom'

export default function ForgotPassword () {
  const [email, setEMail] = useState('')
  const alert = useAlert()
  const navigate = useNavigate()
  const [mandatoryIsEmpty, setMandatoryIsEmpty] = useState(false)
  const onForgotPasswordSubmitEmail = async e => {
    e.preventDefault()
    if (email.trim() != '' && emailValidator(email)) {
      try {
        const res = await forgotPassword(email.trim())
        const data = await res.json()
        setTimeout(() => {
          alert.info(
            'Checking for user and sending email. Please check your mail for password reset details'
          )
          setEMail('')
          setMandatoryIsEmpty(false)
          navigate('/login')
        }, 100)
      } catch {
        alert.error('Error occured')
      }
    } else {
      setMandatoryIsEmpty(true)
      alert.error('Check details before submission')
    }
  }

  const emailValidator = (email) => {
    return email
      .toLowerCase()
      .match(
        /^[^\s@]+@[^\s@]+\.[^\s@]+$/
      )
  }

  return (
    <Container
      component='main'
      maxWidth='sm'
      style={{
        border: '0.5rem solid #e5f4fc',
        marginTop: '10%',
        padding: '5%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center'
      }}
    >
      <CssBaseline />
      <Box>
        <form onSubmit={onForgotPasswordSubmitEmail}>
          <Typography variant='h4'>Forgot Password</Typography>
          <TextField
            required
            label='Email'
            type='email'
            value={email}
            onChange={e => setEMail(e.target.value)}
            error={mandatoryIsEmpty}
            fullWidth
          />
          <Button
            onClick={onForgotPasswordSubmitEmail}
            variant='contained'
            color='secondary'
            sx={{ marginTop: '5%' }}
          >
            Submit
          </Button>
        </form>
      </Box>
    </Container>
  )
}
