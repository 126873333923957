/*For use of BeQiSoft Pvt Ltd. */

import React, { useState, useEffect } from 'react'
import TextField from '@mui/material/TextField'
import {
  Button,
  Checkbox,
  Dialog,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  IconButton,
  Input,
  InputLabel
} from '@mui/material'
import AddIcon from '@mui/icons-material/Add'
import {
  Cable,
  CancelOutlined,
  ImageOutlined,
  PhoneAndroid
} from '@mui/icons-material'
import { ErrorMessage, Formik, validateYupSchema } from 'formik'
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import * as yup from 'yup'
import GenericSelectCell from '../AdminSelectCells/GenericSelectCell'
import dayjs from 'dayjs'
import { fetchShortUserInfo } from '../../../service/addUserService'
import { addItem } from '../../../service/InterfaceFunctions/addItem'
import { useAlert } from 'react-alert'
import RenderDeviceImageEditor from './RenderDeviceImageEditor'

export default function CreateDeviceAssetForm ({
  open,
  handleClose,
  setRefreshTable
}) {
  const [mandatoryIsEmpty, setMandatorIsEmpty] = useState(false)
  const [imageUploader, setImageUploader] = useState(false)
  const [deviceImages, setDeviceImages] = useState([])

  const alert = useAlert()
  useEffect(() => {
    if (open) {
      resetState()
    }
  }, [open])

  const resetState = () => {
    setMandatorIsEmpty(false)
  }
  const handleFormClose = () => {
    handleClose()
  }
  const handleValueSubmit = async e => {
    if (e.deviceImages.length == 2) {
      try {
        const res = await addItem('deviceasset', e)
        const data = await res.text()
        if(res.ok)
          alert.show(data)
        else
        alert.show('Error in creation, ensure IDs are unique')
      } catch (error) {
        console.log(error)
        alert.show('Error')
      } finally {
        handleFormClose()
        setRefreshTable(true)
      }
    }
    else
    {
      setMandatorIsEmpty(true)
      alert.error('Ensure images and other fields are filled')
    }
  }
  const handleImageUploaderClose = () => {
    setImageUploader(false)
  }
  return (
    <Dialog open={open}>
      <DialogTitle>
        <IconButton onClick={handleFormClose} sx={{ float: 'right' }}>
          <CancelOutlined />
        </IconButton>
        Create Mobile Device
      </DialogTitle>
      <DialogContent>
        <Formik
          initialValues={{
            inUse: false,
            deviceAssetID: null,
            brand: null,
            modelName: null,
            category: null,
            purchaseDate: dayjs(new Date()),
            empId: null,
            teamIdf: null,
            processor: null,
            ram: null,
            storage: null,
            warranty: null,
            imeicode: null,
            serialNo: null,
            accessories: null,
            additionalInfo: null,
            deviceImages: deviceImages
          }}
          onSubmit={values => handleValueSubmit(values)}
          validationSchema={yup.object({
            deviceAssetID: yup.string().min(7).required('Required'),
            brand: yup.string().min(1).required('Required'),
            modelName: yup.string().min(1).required('Required'),
            processor: yup.string().min(1).required('Required'),
            ram: yup.string().min(1).required('Required'),
            storage: yup.string().min(1).required('Required'),
            warranty: yup.string().min(1).required('Required'),
            imeicode: yup.string().min(1).required('Required'),
            serialNo: yup.string().min(1).required('Required'),
            purchaseDate: yup.date().nonNullable().required('Required'),
            deviceImages: yup.array().min(2).required()
          })}
          validator={() => ({})}
        >
          {props => {
            const {
              values,
              touched,
              errors,
              dirty,
              isSubmitting,
              handleChange,
              handleBlur,
              handleSubmit,
              handleReset,
              setFieldValue,
              isValid,
              submitCount
            } = props
            return (
              <form noValidate onSubmit={handleSubmit}>
                <TextField
                  label='Asset ID'
                  variant='outlined'
                  fullWidth
                  value={values.deviceAssetID}
                  required={true}
                  error={touched.deviceAssetID && errors.deviceAssetID}
                  helperText={'Minimum 7 characters'}
                  onChange={handleChange('deviceAssetID')}
                  style={{ margin: '20px auto' }}
                />
                <FormControlLabel
                  required
                  control={
                    <Checkbox
                      checked={values.inUse}
                      required={true}
                      error={touched.inUse && errors.inUse}
                      onChange={newVal =>
                        setFieldValue('inUse', newVal.target.checked)
                      }
                    />
                  }
                  labelPlacement='start'
                  label='In Use'
                  style={{ marginLeft: '0px' }}
                />

                <TextField
                  label='Model Name'
                  variant='outlined'
                  fullWidth
                  value={values.modelName}
                  required={true}
                  error={touched.modelName && errors.modelName}
                  onChange={handleChange('modelName')}
                  style={{ margin: '20px auto' }}
                />
                <TextField
                  label='Brand'
                  variant='outlined'
                  fullWidth
                  value={values.brand}
                  required={true}
                  error={touched.brand && errors.brand}
                  onChange={handleChange('brand')}
                  style={{ margin: '20px auto' }}
                />
                <TextField
                  label='Processor'
                  variant='outlined'
                  fullWidth
                  value={values.processor}
                  required={true}
                  error={touched.processor && errors.processor}
                  onChange={handleChange('processor')}
                  style={{ margin: '20px auto' }}
                />
                <TextField
                  label='Warranty'
                  variant='outlined'
                  fullWidth
                  required={true}
                  error={touched.warranty && errors.warranty}
                  onChange={handleChange('warranty')}
                  style={{ margin: '20px auto' }}
                />

                <FormControl
                  fullWidth
                  style={{ margin: '20px auto' }}
                  error={touched.purchaseDate && errors.purchaseDate}
                >
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                      label='Purchase Date'
                      value={dayjs(values.purchaseDate)}
                      format='DD/MM/YYYY'
                      onChange={val =>
                        setFieldValue('purchaseDate', dayjs(val))
                      }
                      slotProps={{
                        textField: {
                          error:
                            touched.purchaseDate &&
                            errors.purchaseDate &&
                            values.purchaseDate != null
                        }
                      }}
                    />
                  </LocalizationProvider>
                </FormControl>
                <FormControl fullWidth style={{ margin: '20px auto' }}>
                  <InputLabel id='emp_ID-select-label'>Assign To</InputLabel>
                  <GenericSelectCell
                    label={'Assign To'}
                    value={values.empId}
                    itemName={'user'}
                    key={'userID'}
                    keyvalue={'employeeID'}
                    fetchData={fetchShortUserInfo}
                    extra={['name', 'lname']}
                    onChange={newVal => setFieldValue('empId', newVal)}
                  />
                </FormControl>
                <TextField
                  label='IMEI Code'
                  variant='outlined'
                  fullWidth
                  value={values.imeicode}
                  required={true}
                  error={touched.imeicode && errors.imeicode}
                  onChange={handleChange('imeicode')}
                  style={{ margin: '20px auto' }}
                />
                <TextField
                  label='Serial No'
                  variant='outlined'
                  required
                  fullWidth
                  value={values.serialNo}
                  onChange={handleChange('serialNo')}
                  error={touched.serialNo && errors.serialNo}
                  style={{ margin: '20px auto' }}
                />
                <TextField
                  label='RAM'
                  variant='outlined'
                  fullWidth
                  value={values.ram}
                  required={true}
                  error={touched.ram && errors.ram}
                  onChange={handleChange('ram')}
                  style={{ margin: '20px auto' }}
                />
                <TextField
                  label='Storage'
                  variant='outlined'
                  fullWidth
                  value={values.storage}
                  required={true}
                  error={touched.storage && errors.storage}
                  onChange={handleChange('storage')}
                  style={{ margin: '20px auto' }}
                />
                <TextField
                  label='Accessories'
                  variant='outlined'
                  fullWidth
                  value={values.accessories}
                  onChange={handleChange('accessories')}
                  style={{ margin: '20px auto' }}
                />
                <TextField
                  label='Additional Info'
                  variant='outlined'
                  fullWidth
                  multiline
                  value={values.additionalInfo}
                  onChange={handleChange('additionalInfo')}
                  style={{ margin: '20px auto' }}
                />
                {!isValid && submitCount > 0 && (
                  <div style={{ color: 'red', fontStyle: 'bold' }}>
                    Some fields may be empty or invalid
                  </div>
                )}
                <Button
                  onClick={() => {
                    setImageUploader(true)
                  }}
                  variant='outlined'
                  color={errors.deviceImages ? 'error': 'inherit'}
                  style={{ margin: '20px auto' }}
                >
                  Image Upload*
                </Button>
                <RenderDeviceImageEditor
                  images={values.deviceImages}
                  open={imageUploader}
                  handleClose={handleImageUploaderClose}
                  onChange={newVal => {
                    setFieldValue('deviceImages', newVal)
                    handleImageUploaderClose()
                  }}
                  alert={alert}
                  setImages={setImageUploader}
                />

                <Button
                  variant='contained'
                  type='submit'
                  className='button-gradient'
                  style={{ margin: '20px' }}
                >
                  Submit
                </Button>
              </form>
            )
          }}
        </Formik>
      </DialogContent>
    </Dialog>
  )
}
