import React, { useState, useEffect } from 'react'
import { useGridApiRef } from '@mui/x-data-grid'
import { Typography, Button } from '@mui/material'
import TableComponent from '../../TableComponents'
import { WarningAmberOutlined } from '@mui/icons-material'
import CreateDepartmentForm from '../AdminCreateForms/CreateDepartmentForm'
import { fetchAlertsForAdmin } from '../../../service/alertService'
import { DatePicker, DateTimePicker } from '@mui/x-date-pickers'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import dayjs from 'dayjs'
import timezone from 'dayjs/plugin/timezone'
import CreateAlertMessageForm from '../AdminCreateForms/CreateAlertMessage'
import { confirmAlert } from 'react-confirm-alert'
dayjs.extend(timezone)

export default function AlertMessagesTable () {
  const apiRef = useGridApiRef()
  const itemName = 'alertmessage'
  const itemID = 'alertID'
  const [formOpen, setFormOpen] = useState(false)
  const [refreshTable, setRefreshTable] = useState(false)

  const handleDateMismatch = () => {
    confirmAlert({
      title: 'Improper dates-Set dates properly before confirming edit',
      buttons: [
        {
          label: 'OK'
        }
      ]
    })
  }

  const handleRefreshTable = newState => {
    setRefreshTable(newState)
  }
  const handleClose = () => {
    setFormOpen(false)
  }

  useEffect(() => {
    setRefreshTable(false)
  }, [refreshTable])

  const columns = [
    {
      field: 'alertStartDate',
      type: 'dateTime',
       valueGetter: (value) =>
        value !== null ? dayjs(value).tz('Asia/Kolkata').toDate() : null,
      flex: 1,
      editable: true,
      headerName: 'Start',
      renderEditCell: params => (
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DateTimePicker
            value={dayjs(params.value)}
            format='DD/MM/YYYY'
            onChange={async event => {
              if (
                dayjs(event).isBefore(
                  dayjs(apiRef.current.getCellValue(params.id, 'alertEndDate')),
                  'day'
                )
              )
                handleDateMismatch()
              console.log('Date changed to:', dayjs(event))
              await apiRef.current.setEditCellValue({
                id: params.id,
                field: params.field,
                value: dayjs(event).tz('Asia/Kolkata').toDate()
              })
            }}
          />
        </LocalizationProvider>
      )
    },
    {
      field: 'alertEndDate',
      type: 'dateTime',
       valueGetter: (value) =>
        value !== null ? dayjs(value).tz('Asia/Kolkata').toDate() : null,
      headerName: 'End',
      flex: 1,
      editable: true,
      renderEditCell: params => (
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DateTimePicker
            value={dayjs(params.value)}
            format='DD/MM/YYYY'
            onChange={async event => {
              if (
                dayjs(event).isBefore(
                  dayjs(
                    apiRef.current.getCellValue(params.id, 'alertStartDate')
                  ),
                  'day'
                )
              )
                handleDateMismatch()
              console.log('Date changed to:', dayjs(event))
              await apiRef.current.setEditCellValue({
                id: params.id,
                field: params.field,
                value: dayjs(event).tz('Asia/Kolkata').toDate()
              })
            }}
          />
        </LocalizationProvider>
      )
    },
    {
      field: 'message',
      headerName: 'Message',
      flex: 1,
      editable: true
    }
  ]
  return (
    <>
      <div>
        <div className='div-spaceToSides'>
          <Typography variant='h4'>
            <WarningAmberOutlined />
            Alerts
          </Typography>

          <Button
            variant='filled'
            className='button-gradient'
            onClick={() => setFormOpen(true)}
          >
            +Add new alert
          </Button>
        </div>
        <Typography>
          Note: Alerts should not overlap, as only one alert will be displayed
          at a time
        </Typography>
        <TableComponent
          refreshTable={refreshTable}
          apiRef={apiRef}
          itemID={itemID}
          itemName={itemName}
          columns={columns}
          differentFetchFuction={fetchAlertsForAdmin}
        />

        <CreateAlertMessageForm
          open={formOpen}
          handleClose={handleClose}
          setRefreshTable={setRefreshTable}
        />
      </div>
    </>
  )
}
