/*For use of BeQiSoft Pvt Ltd. */

import React, { useState, useEffect } from 'react'
import TextField from '@mui/material/TextField'
import {
  Button,
  FormControl,
  InputLabel,
  Tab,
  Tabs,
  Typography,
  styled,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Backdrop,
  CircularProgress
} from '@mui/material'
import AddIcon from '@mui/icons-material/Add'
import PersonAddAltIcon from '@mui/icons-material/PersonAddAlt'
import { addUser } from '../../../service/addUserService'
import RoleSelect from '../AdminSelectCells/RoleSelect'
import GenericSelectCell from '../AdminSelectCells/GenericSelectCell'
import ManagerSelect from '../AdminSelectCells/ManagerSelect'
import ProjectSelect from '../AdminSelectCells/ProjectSelect'
import 'react-phone-number-input/style.css'
import PhoneInput from 'react-phone-number-input'
import { CancelOutlined } from '@mui/icons-material'
import { useAlert } from 'react-alert'
import HolidayListSelect from '../AdminSelectCells/HolidayListSelect'
import { DatePicker } from '@mui/x-date-pickers'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import dayjs from 'dayjs'
import timezone from 'dayjs/plugin/timezone'
import utc from 'dayjs/plugin/utc'
import 'dayjs/locale/en-in'
import { CustomTab } from '../../../stylings/customtabstyle'
dayjs.extend(utc)
dayjs.extend(timezone)
export default function CreateUserForm ({ open, handleClose, setRefreshTable }) {
  const [email, setEmail] = useState(null)
  const [secondaryEmail, setSecondaryEmail] = useState(null)
  const [name, setName] = useState(null)
  const [lname, setLname] = useState(null)
  const [password, setPassword] = useState(null)
  const [employeeID, setEmployeeID] = useState(null)
  const [contact, setContact] = useState(null)
  const [managerID, setManagerID] = useState(null)
  const [team, setTeam] = useState(null)
  const [roles, setRoles] = useState([])
  const [department, setDepartment] = useState(null)
  const [designation, setDesignation] = useState(null)
  const [office, setOffice] = useState(null)
  const [shift, setShift] = useState(null)
  const [type, setType] = useState(null)
  const [secondaryContact, setSecondaryContact] = useState(null)
  const [address, setAddress] = useState(null)
  const [assignedProjects, setAssignedProjects] = useState([])
  const [assignedHolidayList, setAssignedHolidayList] = useState([])
  const [value, setValue] = useState(0)
  const [doJ, setDoj] = useState(null)
  const [mandatoryIsEmpty, setMandatoryIsEmpty] = useState(false)
  const [progress, setProgress] = useState(false)
  const alert = useAlert()
  const handleChange = (event, newValue) => {
    setValue(newValue)
  }

  const resetState = () => {
    setMandatoryIsEmpty(false)
    setName(null)
    setLname(null)
    setEmail(null)
    setSecondaryEmail(null)
    setPassword(null)
    setEmployeeID(null)
    setTeam(null)
    setManagerID(null)
    setContact(null)
    setRoles([])
    setOffice(null)
    setAddress(null)
    setSecondaryContact(null)
    setDepartment(null)
    setDesignation(null)
    setShift(null)
    setType(null)
    setAssignedProjects([])
    setAssignedHolidayList([])
    setDoj(null)
    setValue(0)
  }
  useEffect(() => {
    if (open) {
      resetState()
    }
  }, [open])

  const handleCloseForm = () => {
    setMandatoryIsEmpty(false)
    setName('')
    setLname('')
    setEmail('')
    setSecondaryEmail('')
    setPassword('')
    setEmployeeID('')
    setTeam('')
    setManagerID('')
    setContact('')
    setRoles([])
    setOffice('')
    setAddress('')
    setSecondaryContact('')
    setDepartment('')
    setDesignation('')
    setShift('')
    setType('')
    setAssignedProjects([])
    setAssignedHolidayList([])
    setDoj(null)
    handleClose()
  }

  const handleSubmit = async e => {
    e.preventDefault()
    const userData = {
      type,
      department,
      designation,
      name,
      lname,
      email,
      password,
      contact,
      employeeID,
      team,
      managerID,
      roles,
      shift,
      office,
      assignedProjects,
      assignedHolidayList,
      secondaryContact,
      secondaryEmail,
      address,
      doJ
    }
    if (
      (email && employeeID && name && lname && doJ && roles && shift) != null
    ) {
      try {
        setProgress(true)
        const response = await addUser(userData)
        const responsePayload = await response.text()
      if(response.ok)
        alert.success(responsePayload)
      else
      alert.show('Error in creation, ensure IDs are unique')
      } catch (error) {
        alert.error('User Creation Error')
        setProgress(false)
        console.log(error)
      } finally {
        setTimeout(() => {
          handleCloseForm()
          setMandatoryIsEmpty(false)
          setRefreshTable(true)
          setProgress(false)
        }, 1000)
      }
    } else {
      setMandatoryIsEmpty(true)
      alert.error('User Creation Error Due To Missing Fields')
    }
  }

  return (
    <Dialog open={open}>
      <DialogTitle>
        <PersonAddAltIcon /> Create a user
        <IconButton onClick={handleCloseForm} sx={{ float: 'right' }}>
          <CancelOutlined />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <p>
          User will be able to access the site and perform various functions
        </p>
        <Tabs value={value} onChange={handleChange} indicatorColor='secondary'>
          <CustomTab
            label={<Typography variant='h6'>Quick Info</Typography>}
            wrapped={
              mandatoryIsEmpty &&
              (!email || !employeeID || !name || !roles || !lname)
            }
          />
          <CustomTab
            label={<Typography variant='h6'>Official Details</Typography>}
            wrapped={
              mandatoryIsEmpty &&
              (!shift || !team || !assignedProjects.length || !doJ)
            }
          />
          <CustomTab
            label={<Typography variant='h6'>Personal Details</Typography>}
          />
        </Tabs>
        <form className='div-centerstyle' noValidate>
          {value === 0 && (
            <>
              <TextField
                required
                id='outlined-basic-name'
                label='First Name'
                variant='outlined'
                fullWidth
                value={name}
                onChange={e => setName(e.target.value)}
                style={{ margin: '20px auto' }}
                error={mandatoryIsEmpty && !name}
              />
              <TextField
                required
                id='outlined-basic-name'
                label='Last Name'
                variant='outlined'
                fullWidth
                value={lname}
                onChange={e => setLname(e.target.value)}
                style={{ margin: '20px auto' }}
                error={mandatoryIsEmpty && !lname}
              />
              <TextField
                required
                id='outlined-basic-email'
                label='Email'
                variant='outlined'
                fullWidth
                value={email}
                onChange={e => setEmail(e.target.value)}
                style={{ margin: '20px auto' }}
                error={mandatoryIsEmpty && !email}
              />

              <TextField
                id='outlined-basic-password'
                label='Password'
                variant='outlined'
                fullWidth
                value={password}
                onChange={e => setPassword(e.target.value)}
                style={{ margin: '20px auto' }}
              />
              <TextField
                id='outlined-basic-semail'
                label='Secondary Email'
                variant='outlined'
                fullWidth
                value={secondaryEmail}
                onChange={e => setSecondaryEmail(e.target.value.trim())}
                style={{ margin: '20px auto' }}
              />
              <TextField
                required
                id='outlined-basic-employeeID'
                label='Employee ID'
                variant='outlined'
                fullWidth
                value={employeeID}
                onChange={e => setEmployeeID(e.target.value)}
                style={{ margin: '20px auto' }}
                error={mandatoryIsEmpty && !employeeID}
              />
              <FormControl
                required
                fullWidth
                style={{ margin: '20px auto' }}
                error={mandatoryIsEmpty && !roles.length}
              >
                <InputLabel id='role-select-label'>Role</InputLabel>
                <RoleSelect
                  value={roles}
                  onChange={newVal => setRoles(newVal)}
                />
              </FormControl>
            </>
          )}
          {value === 1 && (
            <>
              <FormControl
                required
                fullWidth
                style={{ margin: '20px auto' }}
                error={mandatoryIsEmpty && !doJ}
              >
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    label='Date of Joining *'
                    format='DD/MM/YYYY'
                    value={dayjs(doJ)}
                    onChange={newVal =>
                      setDoj(dayjs(newVal).tz('Asia/Kolkata').toDate())
                    }
                    slotProps={{
                      textField: {
                        error: !doJ && mandatoryIsEmpty
                      }
                    }}
                  />
                </LocalizationProvider>
              </FormControl>
              <FormControl fullWidth style={{ margin: '20px auto' }}>
                <InputLabel id='type-select-label'>Type</InputLabel>
                <GenericSelectCell
                  label={'Type'}
                  value={type}
                  key={'typeID'}
                  keyvalue={'typeName'}
                  itemName={'employeetype'}
                  onChange={newValue => setType(newValue)}
                />
              </FormControl>
              <FormControl fullWidth style={{ margin: '20px auto' }}>
                <InputLabel id='department-select-label'>Department</InputLabel>
                <GenericSelectCell
                  label={'Department'}
                  value={department}
                  key={'departmentID'}
                  keyvalue={'departmentName'}
                  itemName={'department'}
                  onChange={newValue => setDepartment(newValue)}
                />
              </FormControl>
              <FormControl
                required
                fullWidth
                style={{ margin: '20px auto' }}
                error={mandatoryIsEmpty && !team}
              >
                <InputLabel id='team-select-label'>Team</InputLabel>
                <GenericSelectCell
                  label={'Team'}
                  value={team}
                  key={'teamNo'}
                  keyvalue={'teamID'}
                  itemName={'team'}
                  onChange={newValue => setTeam(newValue)}
                />
              </FormControl>
              <FormControl fullWidth style={{ margin: '20px auto' }}>
                <InputLabel id='manager-select-label'>Manager</InputLabel>
                <ManagerSelect
                  value={managerID}
                  team={team}
                  onChange={newValue => setManagerID(newValue)}
                />
              </FormControl>
              <FormControl fullWidth style={{ margin: '20px auto' }}>
                <InputLabel id='designation-select-label'>
                  Designation
                </InputLabel>
                <GenericSelectCell
                  label={'Designation'}
                  value={designation}
                  key={'designationID'}
                  keyvalue={'designationName'}
                  itemName={'designation'}
                  onChange={newValue => setDesignation(newValue)}
                />
              </FormControl>
              <FormControl fullWidth style={{ margin: '20px auto' }}>
                <InputLabel id='office-select-label'>Office</InputLabel>
                <GenericSelectCell
                  label={'Office'}
                  value={office}
                  key={'locationID'}
                  keyvalue={'officeCode'}
                  itemName={'officelocation'}
                  onChange={newValue => setOffice(newValue)}
                  extra={['city']}
                />
                <FormControl fullWidth style={{ margin: '20px auto' }}>
                  <InputLabel id='holidaylist-select-label'>
                    Holidays
                  </InputLabel>
                  <HolidayListSelect
                    value={assignedHolidayList}
                    onChange={newValue => setAssignedHolidayList(newValue)}
                  />
                </FormControl>
              </FormControl>
              <FormControl
                fullWidth
                style={{ margin: '5px auto' }}
                required
                error={mandatoryIsEmpty && !shift}
              >
                <InputLabel id='shift-select-label'>Shift</InputLabel>
                <GenericSelectCell
                  label={'Shift'}
                  value={shift}
                  key={'shiftID'}
                  keyvalue={'shiftName'}
                  itemName={'shift'}
                  extra={['shiftStartTime', 'shiftEndTime', 'shiftTimeZone']}
                  onChange={newValue => setShift(newValue)}
                />
              </FormControl>
              <FormControl
                fullWidth
                style={{ margin: '20px auto' }}
                required
                error={mandatoryIsEmpty && !assignedProjects.length}
              >
                <InputLabel id='projects-select-label'>Projects</InputLabel>
                <ProjectSelect
                  value={assignedProjects}
                  onChange={newValue => setAssignedProjects(newValue)}
                />
              </FormControl>
            </>
          )}
          {value === 2 && (
            <>
              <TextField
                id='outlined-basic-address'
                label='Address'
                variant='outlined'
                fullWidth
                value={address}
                onChange={e => setAddress(e.target.value)}
                style={{ margin: '20px auto' }}
              />
              <div className='div-spaceToSides' style={{ marginBottom: '5%' }}>
                <PhoneInput
                  placeholder='Phone Number'
                  value={contact}
                  onChange={e => setContact(e)}
                />
                <PhoneInput
                  placeholder='Secondary Contact'
                  value={secondaryContact}
                  onChange={e => setSecondaryContact(e)}
                />
              </div>
            </>
          )}
          <Button
            variant='contained'
            className='button-gradient'
            onClick={handleSubmit}
          >
            <AddIcon />
            Add User
          </Button>
        </form>
      </DialogContent>
      <Backdrop
        sx={{ color: '#fff', zIndex: theme => theme.zIndex.drawer + 1 }}
        open={progress}
      >
        <CircularProgress></CircularProgress>
      </Backdrop>
    </Dialog>
  )
}
