import Cookies from 'js-cookie'
import { BASE_URL } from './apiService'

export const getAllAttendanceOverridables = async ({
  startDate,
  endDate,
  team,
  department,
  shift,
  empID
}) => {
  try {
    const authToken = Cookies.get('_auth')
    const url = new URL(BASE_URL + '/attendance/getOverridableRecords')
    let params = {
      startDate,
      endDate
    }
    if (team != null) 
     params.team=team
    
    if (department != null) params.department=department
    if (shift != null) params.shift=shift
    if (empID != null) params.empID=empID
    url.search = new URLSearchParams(params).toString()
    const response = await fetch(url, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${authToken}`
      }
    })

    return response
  } catch (error) {
    console.error(error)
    throw error
  }
}

export const getAllLeaveOverridables = async ({
  startDate,
  endDate,
  team,
  department,
  shift,
  empID
}) => {
  try {
    const authToken = Cookies.get('_auth')
    const url = new URL(BASE_URL + '/leave/getOverridableRecords')
    let params = {
      startDate,
      endDate
    }
    if (team != null) 
     params.team=team
    
    if (department != null) params.department=department
    if (shift != null) params.shift=shift
    if (empID != null) params.empID=empID
    url.search = new URLSearchParams(params).toString()
    const response = await fetch(url, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${authToken}`
      }
    })

    return response
  } catch (error) {
    console.error(error)
    throw error
  }
}

export const overrideAttendance = async ( itemDetails) => {
  if (itemDetails) {
  try {
    const authToken = Cookies.get('_auth')
    const response = await fetch(
      `${BASE_URL}/attendance/overrideAttendance`,
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${authToken}`
        },
        body: JSON.stringify(itemDetails)
      }
    )
    return response
  } catch (error) {
    console.error(error)
    throw error
  }
}

}
export const overrideLeave = async ( itemDetails) => {
  if (itemDetails) {
  try {
    const authToken = Cookies.get('_auth')
    const response = await fetch(
      `${BASE_URL}/leave/overrideLeave`,
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${authToken}`
        },
        body: JSON.stringify(itemDetails)
      }
    )
    return response
  } catch (error) {
    console.error(error)
    throw error
  }
}

}