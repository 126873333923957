/*For use of BeQiSoft Pvt Ltd. */

import React, { useState, useEffect } from 'react'
import TextField from '@mui/material/TextField'
import {
  Button,
  FormControl,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  InputLabel
} from '@mui/material'
import { MobileDateTimePicker, MobileTimePicker, TimePicker } from '@mui/x-date-pickers'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import AddIcon from '@mui/icons-material/Add'
import { addItem } from '../../../service/InterfaceFunctions/addItem'
import dayjs from 'dayjs'
import { ArrowForwardOutlined, CancelOutlined } from '@mui/icons-material'
import TimezoneSelect from 'react-timezone-select'
export default function CreateShiftForm ({
  open,
  handleClose,
  setRefreshTable
}) {
  const [shiftName, setShiftName] = useState(null)
  const [shiftTimeZone, setShiftTimeZone] = useState('Asia/Kolkata')
  const [shiftStartTime, setShiftStartTime] = useState(null)
  const [shiftEndTime, setShiftEndTime] = useState(null)
  const [mandatoryIsEmpty, setMandatoryIsEmpty] = useState(false)

  useEffect(() => {
    if (open) {
      resetState()
    }
  }, [open])

  const resetState = () => {
    setShiftName(null)
    setShiftStartTime(null)
    setShiftEndTime(null)
    setShiftTimeZone('Asia/Kolkata')
    setMandatoryIsEmpty(false)
  }
  const handleFormClose = () => {
    setShiftName('')
    setShiftStartTime('')
    setShiftEndTime('')
    setShiftTimeZone('Asia/Kolkata')
    handleClose()
  }
  const handleSubmit = async e => {
    e.preventDefault()
    const shiftData = {
      shiftName,
      shiftTimeZone,
      shiftStartTime,
      shiftEndTime
    }
    if (shiftName && shiftTimeZone && shiftStartTime && shiftEndTime) {
      try {
        setMandatoryIsEmpty(false)
        addItem('shift', shiftData)
      } catch (error) {
        console.log(error)
      } finally {
        setRefreshTable(true)
        handleFormClose()
      }
    } else {
      setMandatoryIsEmpty(true)
    }
  }

  return (
    <Dialog open={open}>
      <DialogTitle>
        Create Shift
        <IconButton onClick={handleFormClose} sx={{ float: 'right' }}>
          <CancelOutlined />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <form noValidate>
          <TextField
            required
            label='Shift Name'
            variant='outlined'
            fullWidth
            value={shiftName}
            onChange={e => setShiftName(e.target.value)}
            style={{ margin: '20px auto' }}
            error={mandatoryIsEmpty && !shiftName}
          />
          <InputLabel
            id='projects-select-label'
            required
            error={mandatoryIsEmpty && !shiftTimeZone}
          >
            Shift Time Zone
          </InputLabel>
          <FormControl
            error={mandatoryIsEmpty && !shiftTimeZone}
            required
            label='Shift Time Zone'
            variant='outlined'
            fullWidth
            style={{ margin: '0px 0px 20px 0px', zIndex: 10 }}
          >
            <TimezoneSelect
              labelStyle='offsetHidden'
              value={shiftTimeZone}
              onChange={event => setShiftTimeZone(event.value)}
            />
          </FormControl>
          <FormControl error={mandatoryIsEmpty && !shiftStartTime}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
            <MobileTimePicker
                label='Shift Start*'
                slotProps={{
                  textField: {
                    error: !shiftStartTime && mandatoryIsEmpty
                  }
                }}
                value={dayjs(shiftStartTime)}
                ampm={false}
                views={['hours', 'minutes', 'seconds']}
                onChange={newVal =>
                  setShiftStartTime(dayjs(newVal).format('HH:mm:ss'))
                }
              />
            </LocalizationProvider>
          </FormControl>
          <ArrowForwardOutlined />
          <FormControl error={mandatoryIsEmpty && !shiftEndTime}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <MobileTimePicker
                label='Shift End*'
                slotProps={{
                  textField: {
                    error: !shiftEndTime && mandatoryIsEmpty
                  }
                }}
                value={dayjs(shiftEndTime)}
                ampm={false}
                views={['hours', 'minutes', 'seconds']}
                onChange={newVal =>
                  setShiftEndTime(dayjs(newVal).format('HH:mm:ss'))
                }
              />
            </LocalizationProvider>
          </FormControl>
          <Button
            variant='contained'
            className='button-gradient'
            onClick={handleSubmit}
          >
            <AddIcon />
            Add Shift
          </Button>
        </form>
      </DialogContent>
    </Dialog>
  )
}
