import React, { useState, useEffect } from 'react'
import TextField from '@mui/material/TextField'
import {
  Button,
  FormControl,
  InputLabel,
  IconButton,
  ListItem,
  Stack,
  Divider,
  Card,
  CardContent,
  Typography,
  Box
} from '@mui/material'
import AddIcon from '@mui/icons-material/Add'
import GenericSelectCell from '../AdminSelectCells/GenericSelectCell'
import { Add, Delete, Remove } from '@mui/icons-material'
import { useAlert } from 'react-alert'
export default function DamageCounter ({
  prevValue,
  deviceIDFk,
  open,
  onChange,
  confirmButton,
  additionalError
}) {
  useEffect(() => {
    if (open) {
      resetState()
    }
  }, [open])

  const resetState = () => {
    setMandatoryIsEmpty(false)
    setDamage(null)
    setDamageCount(1)
    setDamageIDFk(null)
    setSelectedDamages(prevValue != null ? prevValue : [])
  }
  const [damage, setDamage] = useState(null)
  const [damageCount, setDamageCount] = useState(1)
  const [damageIDFk, setDamageIDFk] = useState(null)
  const [mandatoryIsEmpty, setMandatoryIsEmpty] = useState(false)
  const [selectedDamages, setSelectedDamages] = useState([])
  const alert = useAlert()

  const handleSubmit = async e => {
    console.log(selectedDamages,'e:',e)
    if (e != null&&Array.isArray(e)) onChange(e)
    else onChange(selectedDamages)
  }

  const handleSelectedAdd = e => {
    const damageData = {
      damage,
      damageCount,
      deviceIDFk,
      damageIDFk
    }
    if (
      damage != null &&
      damageCount != null &&
      !selectedDamages.some(a => a.damage == damage)
    ) {
      try {
        setMandatoryIsEmpty(false)
        const updatedCellValue = [...selectedDamages, damageData]
        setSelectedDamages(updatedCellValue)
        if (!confirmButton) handleSubmit(updatedCellValue)
        return
      } catch (error) {
        console.log(error)
      }
    } else {
      setMandatoryIsEmpty(true)
    }
  }

  const handleDeleteSelected = allToDelete => {
    let delRows = selectedDamages.filter(allocated => allocated !== allToDelete)
    setSelectedDamages(delRows)

    if (!confirmButton) {
      handleSubmit(delRows)
    }
  }

  const handleAdditionSelected = damagebalance => {
    damagebalance.damageCount++
    let newRows = [
      ...selectedDamages.filter(d => d !== damagebalance),
      damagebalance
    ]
    setSelectedDamages(newRows)
    if (!confirmButton) {
      handleSubmit(newRows)
    }
  }

  const handleSubtractionSelected = damagebalance => {
    if (damagebalance.damageCount > 1) {
      damagebalance.damageCount--
      let newRows = []
      if (damagebalance.damageCount > 0) {
        newRows = [
          ...selectedDamages.filter(d => d !== damagebalance),
          damagebalance
        ]
      } else {
        newRows = selectedDamages.filter(
          allocated => allocated !== damagebalance
        )
      }
      setSelectedDamages(newRows)

      if (!confirmButton) {
        handleSubmit(newRows)
      }
    }
  }

  return (
    <>
      <ShowList />
      <Divider />
      <form noValidate style={{ marginTop: '1%' }}>
        <div className='div-spaceToSides'>
          <FormControl
            required
            error={(mandatoryIsEmpty && !damage) || additionalError}
            fullWidth
          >
            <InputLabel id='type-select-label'>Damage Type</InputLabel>
            <GenericSelectCell
              label={'Damage Type'}
              value={damage}
              itemName={'devicedamages'}
              key={'damageID'}
              keyvalue={'damageName'}
              onChange={newVal => {
                if (newVal != null) setDamage(newVal.trim())
              }}
            />
          </FormControl>
          <TextField
            fullWidth
            required
            error={(mandatoryIsEmpty && !damageCount) || additionalError}
            type='number'
            id='outlined-basic-email'
            label='Count'
            variant='outlined'
            value={damageCount}
            onChange={e =>
              e.target.value > 0 ? setDamageCount(e.target.value) : null
            }
          />
          <Button
            variant='contained'
            className='button-gradient'
            onClick={handleSelectedAdd}
            sx={{ margin: '1%', padding: '1%' }}
          >
            <AddIcon />
            Add
          </Button>
        </div>
      </form>
      {confirmButton == null || confirmButton ? (
        <Button
          variant='contained'
          className='button-gradient'
          onClick={handleSubmit}
          sx={{ margin: '1%', padding: '1%' }}
        >
          Confirm
        </Button>
      ) : null}
    </>
  )
  function ShowList () {
    return (
      <Stack direction='column' spacing={0}>
        {selectedDamages.map(damagebalance => (
          <ListItem key={damagebalance.damage} value={damagebalance}>
            <Card sx={{ display: 'flex', maxHeight: '80px' }}>
              <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                <CardContent>
                  <Typography variant='subtitle2'>
                    {damagebalance.damage + '-' + damagebalance.damageCount}
                  </Typography>
                </CardContent>
              </Box>
              <Box sx={{ display: 'flex', alignItems: 'center', pl: 1, pb: 1 }}>
                <IconButton
                  onClick={() => handleAdditionSelected(damagebalance)}
                >
                  <Add fontSize='small' />
                </IconButton>
                <IconButton
                  onClick={() => handleSubtractionSelected(damagebalance)}
                >
                  <Remove fontSize='small' />
                </IconButton>
                <IconButton onClick={() => handleDeleteSelected(damagebalance)}>
                  <Delete fontSize='small' />
                </IconButton>
              </Box>
            </Card>
          </ListItem>
        ))}
      </Stack>
    )
  }
}
